import { Controller } from '@hotwired/stimulus';

const {
    PremiumBasisLabel,
} = require('../../js/BindHQ/Portal/PremiumBasisLabel');

export default class extends Controller {
    static targets = ['exposure', 'premiumBasis'];

    static values = {
        values: Object,
        default: String,
    };

    connect() {
        this.premiumBasisLabel = new PremiumBasisLabel(
            this.premiumBasisTarget,
            this.exposureTarget,
            this.valuesValue,
            this.defaultValue,
        );
        this.premiumBasisLabel.onChange();
    }

    onChange() {
        this.premiumBasisLabel.onChange();
    }
}
