import { Controller } from '@hotwired/stimulus';

const {
    TermExpiryCalculator,
} = require('../../assets/js/BindHQ/Utils/TermExpiryCalculator');
const {
    PolicyTermGroup,
} = require('../../assets/js/BindHQ/Utils/PolicyTermGroup');

export default class extends Controller {
    static targets = ['effective', 'expiration', 'code'];

    static values = {
        other: String,
    };

    connect() {
        const policyTermGroup = new PolicyTermGroup(
            new TermExpiryCalculator(),
            $(this.effectiveTarget),
            $(this.expirationTarget),
            $(this.codeTarget),
            this.otherValue,
        );

        policyTermGroup.init();
    }
}
