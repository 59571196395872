/**
 * For each array in arrayOfArrays, return an array of common elements that are in all arrays in arrayOfArrays
 *
 * @param {array} arrayOfArrays
 *
 * @returns {array}
 */
function commonElementsIn(arrayOfArrays) {
    if (arrayOfArrays.length === 0) {
        return [];
    }

    return _.cloneDeep(arrayOfArrays)
        .shift()
        .filter(function (v) {
            return arrayOfArrays.every(function (a) {
                return a.indexOf(v) !== -1;
            });
        });
}

class LinesOfBusinessSelector {
    /**
     * @param {HTMLElement} element
     * @param {Object} mapping
     */
    constructor(element, mapping) {
        this.element = element;
        this.mapping = mapping;

        this.updateOpenBlobs();
    }

    /**
     *
     */
    onChange() {
        this.updateOpenBlobs();
    }

    /**
     *
     */
    updateOpenBlobs() {
        const isWildcard = (blobs) => blobs.length > 0;
        const toBlobs = (option) => this.#getBlobs(option.value);
        const blobsForAllLobs = _.map(
            this.element.selectedOptions,
            toBlobs,
        ).filter(isWildcard);
        const commonBlobs = commonElementsIn(blobsForAllLobs);

        for (const lobCode in this.element.tomselect.options) {
            const lobId = lobCode;
            const lobBlobs = this.#getBlobs(lobId);

            let found = false;

            if (0 === blobsForAllLobs.length) {
                found = true;
            } else {
                for (let j = 0; j < commonBlobs.length; j++) {
                    if (
                        lobBlobs.length === 0 ||
                        lobBlobs.includes(commonBlobs[j])
                    ) {
                        found = true;
                        break;
                    }
                }
            }

            if (this.mapping[lobId]) {
                const data = {
                    value: lobCode,
                    text: this.mapping[lobId].name,
                    disabled: !found,
                };

                this.element.tomselect.updateOption(lobCode, data);
            }
        }
    }

    /**
     * @param {String} lobId
     */
    #getBlobs(lobId) {
        return this.mapping[lobId] ? this.mapping[lobId].broad_lobs : [];
    }
}

module.exports = { LinesOfBusinessSelector };
