import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['renewPolicies', 'submitButton'];

    initialize() {
        this.registerEvents();
    }

    /** @param {Event} event */
    showEmail(event) {
        this.renewPoliciesTarget.value = 0;
        this.submitButtonTarget.innerHTML = 'Send Email';

        this.showModal(event);
    }

    /** @param {Event} event */
    showRenew(event) {
        bindhq.modalAjax.showUrl(
            event.params.url +
                '?bulk_renew[policies]=' +
                this.#getPolicyIdString(),
        );
    }

    /** @param {Event} event */
    showEmailAndRenew(event) {
        this.renewPoliciesTarget.value = 1;
        this.submitButtonTarget.innerHTML = 'Renew Policies and Send Email';

        this.showModal(event);
    }

    showModal(event) {
        const tables = $(this.element).find('.table-renewals');
        const policies = this.policiesFor(tables);
        const policyNums = _.pluck(policies, 'policyNum');
        const policyIds = _.pluck(policies, 'policyId');

        this.setPolicyIds(policyIds.join(','));
        this.setSelectedPolicies(policyNums.join(', '));

        $('#renewal-email-modal').modal('show');
    }

    registerEvents() {
        $('#renewal-email-modal').on('hide.bs.modal', (e) => {
            this.setSelectedPolicies('');
            this.setPolicyIds('');
        });
    }

    policiesFor(tables) {
        const toAgent = function (row) {
            const agency = $('.agency', row);
            const name = agency.data('agent-name');
            const email = agency.data('agent-email');

            return name || email ? { name: name, email: email } : null;
        };

        const toPolicy = function (index, row) {
            return {
                policyId: $('.policy', row).data('id'),
                policyNum: $('.policy', row).text().trim(),
                agent: toAgent(row),
            };
        };

        return this.selectedRowsFor(tables).map(toPolicy).toArray();
    }

    selectedRowsFor(tables) {
        return $('tr:has([type=checkbox]:checked:not(".select-all"))', tables);
    }

    setSelectedPolicies(policies) {
        const selectedPolicies = $('#renewal-email-modal').find(
            '#selected-policies',
        );
        selectedPolicies.html(policies);
    }

    setPolicyIds(ids) {
        $('#renewal-email-modal').find('#bulk_email_and_renew_ids').val(ids);
    }

    /** @return {String} */
    #getPolicyIdString() {
        const tables = $(this.element).find('.table-renewals');
        const policies = this.policiesFor(tables);
        const policyIds = _.pluck(policies, 'policyId');

        return policyIds.join(',');
    }
}
