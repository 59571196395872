import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const numeral = require('numeral');
const Progress = require('../../../../js/BindHQ/Utils/Progress');
const qs = require('qs');

export default class extends Controller {
    static targets = ['classification', 'classificationLocation', 'summary'];

    static values = {
        allowNewFlow: String,
        instanceId: String,
        createUrl: String,
        format: String,
        rateUrl: String,
    };

    static debounces = ['check'];

    initialized = false;

    connect() {
        useDebounce(this, { wait: 500 });

        $(this.element).on('change', '.classification-class-code', () =>
            this.check(),
        );

        this.initialized = true;
        this.check();
    }

    classificationTargetConnected() {
        this.check();
    }

    classificationTargetDisconnected() {
        this.check();
    }

    create() {
        const allowNewFlow = document.createElement('input');
        allowNewFlow.setAttribute('type', 'hidden');
        allowNewFlow.setAttribute('name', this.allowNewFlowValue);
        allowNewFlow.setAttribute('value', '');

        const form = this.summaryTarget.closest('form');
        form.action = this.createUrlValue;
        form.appendChild(allowNewFlow);
        form.submit();
    }

    check() {
        if (!this.initialized) {
            // Workaround for initialization ordering.
            setTimeout(() => this.check(), 500);

            return;
        }

        const locations = [];

        this.classificationLocationTargets.forEach((classificationLocation) => {
            const locationUuid =
                classificationLocation.querySelector('.location').value;
            const location = window.locationBag.find(locationUuid);

            this.classificationTargets.forEach((classification) => {
                if (!classificationLocation.contains(classification)) {
                    return;
                }

                const classCode = $(
                    '.classification-class-code',
                    classification,
                );

                locations.push({
                    number: 1,
                    classCode: classCode.data('select2')
                        ? classCode.select2('val')
                        : classCode.val(),
                    state: location.getAddress().getState(),
                    exposure: classification.querySelector(
                        '.classification-exposure',
                    ).value,
                });
            });
        });

        const request = {
            locations,
            format: this.formatValue,
            instanceId: this.instanceIdValue,
        };

        Progress.start();

        fetch(this.rateUrlValue + '?' + qs.stringify(request))
            .then((response) => response.text())
            .then((html) => {
                this.summaryTarget.innerHTML = html;
            })
            .finally(() => Progress.stop());
    }
}
