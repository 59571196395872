const progress = require('../../../Utils/Progress');

class Rater {
    /**
     * @param {HTMLElement} form
     * @param {HTMLElement} premiumContainer
     * @param {String} ratingUrl
     */
    constructor(form, premiumContainer, ratingUrl) {
        this.form = form;
        this.premiumContainer = premiumContainer;
        this.ratingUrl = ratingUrl;

        this.refresher = _.debounce(() => this.refresh(), 500);
    }

    onChange() {
        this.refresher.call();
    }

    refresh() {
        progress.start();

        $.ajax({
            url: this.ratingUrl,
            data: $(this.form).formSerialize(),
            success: (html, status, xhr) => this.render(xhr.responseText),
            error: (xhr) => this.render(xhr.responseText),
            complete: () => progress.stop(),
        });
    }

    render(html) {
        this.premiumContainer.innerHTML = html;
    }
}

module.exports = { Rater };
