import { Controller } from '@hotwired/stimulus';

const Progress = require('../../js/BindHQ/Utils/Progress');
const qs = require('qs');

export default class extends Controller {
    static targets = [
        'content',
        'preview',
        'previewLink',
        'type',
        'writeLink',
        'replacements',
        'inputs',
        'replacementsError',
        'replacementsArrow',
    ];

    static values = {
        url: String,
        replacements: Object,
    };

    initialize() {
        const type = this.typeTarget.value;
        this.#addReplacements(type);
    }

    onWriteClick() {
        this.#show(this.writeLinkTarget);
    }

    onPreviewClick() {
        this.#show(this.previewLinkTarget);
        this.previewTarget.innerHTML = 'Loading...';

        const request = {
            type: this.typeTarget.value,
            content: this.contentTarget.value,
        };

        Progress.start();

        fetch(this.urlValue + '?' + qs.stringify(request))
            .then((response) => response.text())
            .then((html) => {
                this.previewTarget.innerHTML = html;
            })
            .finally(() => Progress.stop());
    }

    onReplacementClick(event) {
        const replacement = event.currentTarget.dataset.value;
        const activeElement = document.activeElement;

        if (activeElement.name === this.contentTarget.name) {
            $(this.contentTarget).redactor('insertText', replacement);
        } else {
            // Do nothing for now...Maybe display a warning later.
        }
    }

    toggleReplacements(event) {
        event.preventDefault();

        if (this.hasReplacementsTarget) {
            this.#toggleReplacementsArrow();
            this.#toggleReplacementsError();

            if (this.typeTarget.value !== '' && this.hasReplacementsTarget) {
                $(this.replacementsTarget).collapse('toggle');
            }
        }
    }

    #show(element) {
        const url = new URL(element.href);

        bindhq.tabs.show(url.hash.substring(1));
    }

    #removeReplacements() {
        this.replacementsTarget.innerHTML = '';
    }

    #addReplacements(type) {
        const replacements = this.#getReplacements(type);

        if (replacements === undefined) {
            this.#removeReplacements();
        } else {
            this.replacementsTarget.innerHTML =
                this.#replacementsHtml(replacements);
        }
    }

    #getReplacements(type) {
        return this.replacementsValue[type];
    }

    #replacementsHtml(replacements) {
        return (
            '<ul class="list-group pb-1">' +
            replacements.reduce(
                (items, { label, replacements }) =>
                    items +
                    `<li class="bg-transparent border-0 mb-2 p-0 list-group-item"><div><div class="mb-1">${label}</div><div>` +
                    replacements.reduce(
                        (buttons, { label, description, value }) =>
                            buttons +
                            `<button type="button" data-action="mousedown->dynamic-replacements--preview#onReplacementClick:prevent" class="mb-1 mr-1 btn btn-small btn-outline-primary text-replacement__item" data-trigger="hover" data-content="${description}" data-controller="form--popup" data-value="${value}">${label}</button>`,
                        '',
                    ) +
                    `</div></div></li>`,
                '',
            ) +
            '</ul>'
        );
    }

    #toggleReplacementsError() {
        if (this.typeTarget.value === '') {
            $(this.replacementsTarget).collapse('hide');
            $(this.replacementsErrorTarget).collapse('toggle');
        } else {
            $(this.replacementsErrorTarget).collapse('hide');
        }
    }

    #toggleReplacementsArrow() {
        if (
            this.replacementsTarget.classList.contains('show') ||
            this.replacementsErrorTarget.classList.contains('show')
        ) {
            this.replacementsArrowTarget.classList.replace(
                'fa-chevron-up',
                'fa-chevron-down',
            );
        } else {
            this.replacementsArrowTarget.classList.replace(
                'fa-chevron-down',
                'fa-chevron-up',
            );
        }
    }
}
