const assert = require('assert');
const { DateTime } = require('luxon');

class PolicyTermGroup {
    /**
     * @param {TermExpiryCalculator} calculator
     * @param {jQuery} termEffective
     * @param {jQuery} termExpiry
     * @param {jQuery} policyTermCode
     */
    constructor(
        calculator,
        termEffective,
        termExpiry,
        policyTermCode,
        otherValue,
    ) {
        assert.strictEqual(
            1,
            termEffective.length,
            'Expected exactly one element for termEffective, got: ' +
                termEffective.length,
        );
        assert.strictEqual(
            1,
            termExpiry.length,
            'Expected exactly one element for termExpiry, got: ' +
                termExpiry.length,
        );
        assert.strictEqual(
            1,
            policyTermCode.length,
            'Expected exactly one element for policyTermCode, got: ' +
                policyTermCode.length,
        );

        termEffective.on('change', () => this.#effectiveChanged());
        termExpiry.on('change', () => this.#expirationChanged());
        policyTermCode.on('change', () => this.#effectiveChanged());

        this.calculator = calculator;
        this.termEffective = termEffective;
        this.termExpiry = termExpiry;
        this.policyTermCode = policyTermCode;
        this.otherValue = otherValue;
    }

    init() {
        this.#effectiveChanged();
    }

    #effectiveChanged() {
        const termEffectiveValue = this.termEffective.val();

        if (!termEffectiveValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
            return;
        }

        const termEffective = DateTime.fromISO(
            termEffectiveValue.substring(0, 10),
        );
        const policyTermCode = this.policyTermCode.val();

        if (!policyTermCode) {
            return;
        }

        if (this.otherValue !== policyTermCode) {
            const termExpiry = this.calculator.calculate(
                termEffective,
                policyTermCode,
            );

            this.termExpiry.val(termExpiry.toISODate());
        }
    }

    #expirationChanged() {
        const tomselect = this.policyTermCode.get(0).tomselect;

        tomselect.setValue(this.otherValue);
        tomselect.trigger('change');
    }
}

module.exports = { PolicyTermGroup };
