import { Controller } from '@hotwired/stimulus';

/**
 * @param {jQuery} tags
 *
 * @return {Array}
 */
const selectedTags = function (tags) {
    const toTag = function (i, e) {
        return e.value;
    };

    return $('input:checked', tags).map(toTag).toArray();
};

/**
 * @param {jQuery} file
 *
 * @return {Array}
 */
const fileTags = function (file) {
    const toTag = function (i, e) {
        return e.dataset.tag;
    };

    return $('.tag', file).map(toTag).toArray();
};

/**
 * @param {jQuery} container
 *
 * @return {Object}
 */
const getFilterData = function (container) {
    const filterInput = container.findOne('.filter input');
    const tags = container.findOne('.filter-tags');
    const folder = container.findOne('.folder-tags');
    const filterString = filterInput.val().toLowerCase();
    const filterTags = selectedTags(tags).concat(selectedTags(folder));

    return {
        template: '_index',
        context: container.data('context'),
        owners: container.data('owners').split(','),
        fileTagTypes: container.data('file-tag-types').split(','),
        filterString: filterString,
        filterTags: filterTags,
        sortColumn: container.data('sort-column'),
        sortDirection: container.data('sort-direction'),
    };
};

/**
 * @param {jQuery} container
 * @param {jQuery} input
 * @param {jQuery} tags
 * @param {jQuery} folder
 */
const filter = function (container) {
    container.addClass('files-loading');

    $.ajax({
        url: container.data('url'),
        data: getFilterData(container),
        success: function (html) {
            container.trigger($.Event('documents_updated', { html }));
        },
        complete: function () {
            container.removeClass('files-loading');
        },
    });
};

/**
 * @param {Object} file
 *
 * @return {String|Boolean}
 */
const makeNameFor = function (file) {
    const desc = prompt('Please enter a name for this file:');

    if (!desc) {
        return false;
    }

    switch (file.type) {
        case 'image/jpeg':
            return desc + '.jpg';
        case 'image/gif':
            return desc + '.gif';
        case 'image/png':
            return desc + '.png';
    }

    return desc + '.png';
};

/**
 * @param {Object} item
 *
 * @return {boolean}
 */
const isImage = function (item) {
    return item.type.indexOf('image') !== -1;
};

/**
 * @param {jQuery} container
 */
const onFilterChanged = function (container) {
    container.trigger('filterschanged');
};

/**
 * @param {jQuery} container
 * @param {jQuery.Event} evt
 */
const onDownload = function (container, evt) {
    const toId = function (index, element) {
        return element.dataset.fileId;
    };

    const ids = $('.file-row:visible', container).map(toId).toArray().join(',');

    $('.download [name="ids"]').val(ids).closest('form').submit();
};

/**
 * @param {jQuery} container
 */
const selectedFiles = function (container) {
    const toFile = function (index, element) {
        const item = $(element);
        return bindhq.files.elementToFile(item);
    };

    return $('.file-row', container)
        .has('.select-file:checked')
        .map(toFile)
        .toArray();
};

/**
 * @param {jQuery} container
 */
const onFileSelected = function (container) {
    $('.select-files .select-all', container).prop('checked', false);

    container.trigger($.Event('selectedfileschanged'));
};

/**
 * @param {jQuery} container
 */
const onSendFilesSuccessComplete = function (container) {
    $('.select-files .select-all, .select-file', container)
        .prop('checked', false)
        .removeAttr('disabled');

    $('.multi-select', container).removeClass('multi-select-success');

    container.trigger($.Event('selectedfileschanged'));
};

/**
 * @param {jQuery} container
 * @param {Object} data
 */
const onSendFilesSuccess = function (container, data) {
    $('.multi-select .message', container).html(data.message);

    $('.multi-select', container).addClass('multi-select-success');

    setTimeout(_.partial(onSendFilesSuccessComplete, container), 3000);
};

/**
 * @param {jQuery} container
 * @param {jQuery} anchor
 */
const sendFilesTo = function (container, anchor) {
    const url = anchor.data('url');
    const files = selectedFiles(container);
    const ids = _.pluck(files, 'id').join(',');
    const group = anchor.closest('.btn-group');
    const onComplete = _.bind(bindhq.progress, group, 'stop');

    const onSuccess = _.partial(onSendFilesSuccess, container);

    const config = {
        beforeSubmit: _.bind(bindhq.progress, group, 'start'),
        success: _.compose(onComplete, onSuccess),
        error: onComplete,
    };

    $('.select-files [name="ids"]', container)
        .val(ids)
        .closest('form')
        .attr('action', url)
        .ajaxSubmit(config);
};

/**
 * @param {jQuery} container
 * @param {jQuery.Event} evt
 */
const onSendFilesClick = function (container, evt) {
    const anchor = $(evt.currentTarget);

    sendFilesTo(container, anchor);

    $('.select-files .select-all, .select-file', container).attr(
        'disabled',
        'disabled',
    );
};

/**
 * @param {jQuery} container
 */
const onDeleteMultiFilesSuccessComplete = function (container, data) {
    $('.select-files .select-all, .select-file', container)
        .prop('checked', false)
        .removeAttr('disabled');

    $.each(data, function (i, file) {
        $('.file-row[data-file-id=' + file + '][data-file-can-delete]').fadeOut(
            function () {
                $(this).remove();
            },
        );
    });
};

/**
 * @param {jQuery} container
 * @param {Object} data
 */
const onDeleteMultiFilesSuccess = function (container, data) {
    const form = $(data).sizedModal();

    const config = {
        success: _.partial(onDeleteMultiFilesSuccessComplete, container),
    };

    form.parent().modalAjax(config);
};

/**
 * @param {jQuery} container
 * @param {jQuery} anchor
 */
const deleteMultipleFiles = function (container, anchor) {
    const url = anchor.data('url');
    const files = selectedFiles(container);
    const ids = _.pluck(files, 'id').join(',');
    const group = anchor.closest('.btn-group');
    const onComplete = _.bind(bindhq.progress, group, 'stop');

    const onSuccess = _.partial(onDeleteMultiFilesSuccess, container);

    const config = {
        beforeSubmit: _.bind(bindhq.progress, group, 'start'),
        success: _.compose(onComplete, onSuccess),
        error: onComplete,
    };

    $('.select-files [name="ids"]', container)
        .val(ids)
        .closest('form')
        .attr('action', url)
        .ajaxSubmit(config);
};

/**
 * @param {jQuery} container
 * @param {jQuery.Event} evt
 */
const onDeleteMultiFilesClick = function (container, evt) {
    const anchor = $(evt.currentTarget);

    deleteMultipleFiles(container, anchor);

    $('.select-files .select-all, .select-file', container).attr(
        'disabled',
        'disabled',
    );
};

/**
 * @param {jQuery} container
 */
const onSelectAllClick = function (container) {
    const selectAll = $('.select-all input', container);

    $('.file-row:visible .select-file', container).prop(
        'checked',
        selectAll.is(':checked'),
    );

    container.trigger($.Event('selectedfileschanged'));
};

/**
 * @param {jQuery} container
 *
 * @return {jQuery}
 */
const createMultiSelect = function (container) {
    const tpl = $('#documents-multi-select-tpl').html();
    const html = bindhq.util.template(tpl, {});

    return $(html).appendTo(container);
};

/**
 * @param {jQuery} container
 * @param {jQuery} multi
 */
const onSelectedFilesChanged = function (container, multi) {
    const files = selectedFiles(container);
    const names = _.pluck(files, 'name');
    const html = names.join(', ');

    $('.items span', multi).html(html);

    if (files.length > 0) {
        multi.addClass('multi-select-visible');
    } else {
        multi.removeClass('multi-select-visible');
    }
};

/**
 * @param {jQuery} container
 * @param {jQuery} preview
 * @param {jQuery.Event} evt
 */
const onThumbnailClick = function (container, preview, evt) {
    const thumbnail = $(evt.currentTarget);

    if (thumbnail.hasClass('has-preview')) {
        const tpl = $('#thumbnail-preview-tpl').html();
        const html = bindhq.util.template(tpl, {
            src: thumbnail.attr('src'),
        });

        preview.empty().append(html).insertAfter(thumbnail);

        evt.stopPropagation();
    }
};

/**
 * @param {jQuery} preview
 */
const onThumbnailHide = function (preview) {
    preview.remove();
};

/**
 * @param {jQuery} container
 * @param {jQuery.Event} evt
 * @param {jQueryUI.Event} ui
 */
const onFileTagDrop = function (container, evt, ui) {
    const source = $('.action-drag', ui.draggable);
    const target = $(evt.target);
    const documentCount = target.find('.document-count');
    const identifier = target.data('tag-identifier');
    const config = {
        url: source.data('tag-url'),
        type: 'POST',
        data: _.extend({}, getFilterData(container), {
            identifier,
            format: 'html',
        }),
        success: function (html) {
            container.trigger($.Event('documents_updated', { html }));
        },
    };

    if (0 !== documentCount.length) {
        documentCount.html(parseInt(documentCount.text(), 10) + 1);
    }

    $.ajax(config);
};

const toggleFolderLabelStatus = function (container, evt) {
    $('.folder-label:not(.root)').removeClass('open');
    $(evt.target).closest('label').addClass('open');
};

export default class extends Controller {
    static targets = ['foldersColumn', 'filesColumn'];

    sortConfig = {
        title: 'asc',
        date_created: 'desc',
    };

    folderCollapseClass = 'documents-tab-folders-collapsed';

    connect() {
        const container = $(this.element);
        const filterInput = $('.filter input', container);
        const tags = $('.filter-tags', container);
        const folder = $('.folder-tags', container);
        const preview = $('<div></div>').addClass('thumbnail-preview');
        const multi = createMultiSelect(container);

        const draggableConfig = {
            cursor: 'grabbing',
            handle: '.action-drag',
            cursorAt: {
                top: 0,
                left: 0,
            },
            start: function () {
                $('body').addClass('dragging');
            },
            stop: function () {
                $('body').removeClass('dragging');
            },
            helper: function () {
                const modal = $(this).closest('.modal');
                const src = $('.thumbnail', this).attr('src');
                const hint = bindhq.util.template(bindhq.tpl.drag_files_hint, {
                    src,
                });

                return $(hint, this)
                    .appendTo(modal.length ? modal : 'body')
                    .css('position', 'fixed');
            },
        };

        const initFolders = function () {
            $('.folder-label, .tag-label', container).droppable({
                hoverClass: 'droppable-active',
                tolerance: 'pointer',
                drop: _.partial(onFileTagDrop, container),
            });
        };

        container.data('sort-column', 'date_created');
        container.data('sort-direction', 'desc');

        filterInput.keyup(
            _.debounce(_.partial(onFilterChanged, container), 1000),
        );

        container.on(
            'selectedfileschanged',
            _.partial(onSelectedFilesChanged, container, multi),
        );

        container.on(
            'filterschanged',
            _.partial(filter, container, filterInput, tags, folder),
        );

        container.on(
            'filterschanged',
            _.partial(toggleFolderLabelStatus, container),
        );

        $('.download [type=submit]').click(
            bindhq.util.noDefault(_.partial(onDownload, container)),
        );

        container.on(
            'click',
            '.select-file',
            _.partial(onFileSelected, container),
        );

        container.on(
            'click',
            '.files-table .thumbnail',
            _.partial(onThumbnailClick, container, preview),
        );

        container.on(
            'mouseover',
            '.file-row:has(.action-drag):not(.ui-draggable)',
            function () {
                $(this).draggable(draggableConfig);
            },
        );

        $('body').on('file_updated file_deleted file_uploaded', function (evt) {
            container.trigger('filterschanged');
        });

        container.on('documents_updated', function (evt) {
            container
                .findOne('.folder-tags-container')
                .html($(evt.html).findOne('.folder-tags'));

            container
                .findOne('.filter-tags-container')
                .html($(evt.html).findOne('.filter-tags'));

            container
                .findOne('.files-table-container')
                .html($(evt.html).findOne('.files-table'));

            initFolders();

            $('[data-toggle="modal"]', container).each(bindhq.modalAjax.init);

            multi.removeClass('multi-select-visible');
        });

        $(document).click(_.partial(onThumbnailHide, preview));

        $('.multi-select li a.op-send-files', container).click(
            bindhq.util.noDefault(_.partial(onSendFilesClick, container)),
        );

        $('.multi-select li a.op-delete-files', container).click(
            bindhq.util.noDefault(
                _.partial(onDeleteMultiFilesClick, container),
            ),
        );

        container.on(
            'click',
            '.select-all input',
            _.partial(onSelectAllClick, container),
        );

        initFolders();
    }

    /**
     * @param {Event} evt
     */
    onAllFilesClick(evt) {
        $('.folder-tags input', this.element).prop('checked', false);

        this.onFileTagClick(evt);
    }

    /**
     * @param {Event} evt
     */
    onFileTagClick(evt) {
        $('.folder-label input[name=radio-tag]').removeClass('btn-selected');

        $(evt.currentTarget).addClass('btn-selected');

        $(this.element).trigger(
            $.Event('filterschanged', { target: evt.currentTarget }),
        );
    }

    /**
     * @param {Event} evt
     */
    onSortColumnClick(evt) {
        const container = $(this.element);
        const header = $(evt.currentTarget).closest('th');
        const sortColumn = header.data('sort-column-name');
        const sortDirection =
            'desc' === this.sortConfig[sortColumn] ? 'asc' : 'desc';

        this.sortConfig[sortColumn] = sortDirection;

        container.data('sort-column', sortColumn);
        container.data('sort-direction', sortDirection);
        container.trigger('filterschanged');
    }

    /**
     *
     */
    onCollapseFoldersClick() {
        this.setCollapseCookie('true');

        this.element.classList.add(this.folderCollapseClass);

        this.foldersColumnTarget.classList.add('d-none');

        this.filesColumnTarget.classList.remove('col-7');
        this.filesColumnTarget.classList.add('col-10');
    }

    /**
     *
     */
    onExpandFoldersClick() {
        this.setCollapseCookie('false');

        this.element.classList.remove(this.folderCollapseClass);

        this.foldersColumnTarget.classList.remove('d-none');

        this.filesColumnTarget.classList.remove('col-10');
        this.filesColumnTarget.classList.add('col-7');
    }

    /**
     * @param {String} value
     */
    setCollapseCookie(value) {
        $.cookie(this.element.dataset.folderCollapseCookieName, value, {
            path: '/',
        });
    }
}
