const { truncate } = require('../../Utils/String');

class ClassificationMenuGenerator {
    /**
     * @param {string} target
     * @param {string} label
     * @param {Array} classifications
     *
     * @returns {string}
     */
    generateListItem(target, label, classifications) {
        return `<li class="mb-3"><a href="${target}" class="collapse-show" data-controller="scroll-to" data-side-scroller-target="link" data-action="side-scroller#onClick">${truncate(
            label,
            40,
            '...',
        )}</a>${this.#getClassificationsListHtml(classifications)}</li>`;
    }

    /**
     * @param {Array.<{target: string, label: string}>} classifications
     *
     * @returns {string}
     */
    #getClassificationsListHtml(classifications) {
        if (!classifications.length) {
            return '';
        }

        const html = classifications
            .map(
                ({ target, label }) =>
                    `<li class="mb-2"><a href="${target}" class="collapse-show">${truncate(
                        label,
                        40,
                        '...',
                    )}</a></li>`,
            )
            .join('');

        return `<ul class="mt-2">${html}</ul>`;
    }
}

module.exports = { ClassificationMenuGenerator };
