import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const progress = require('../../../js/BindHQ/Utils/Progress');

export default class extends Controller {
    static targets = ['premium'];

    static debounces = ['submit'];

    connect() {
        useDebounce(this, { wait: 500 });

        this.submit();
    }

    onChange() {
        this.submit();
    }

    submit() {
        const data = new URLSearchParams(new FormData(this.element));
        data.append('rating', '1');

        const config = { method: 'post', body: data };

        progress.start();

        fetch(this.element.action, config)
            .then((response) => response.text())
            .then((html) => {
                this.premiumTarget.innerHTML = html;
            })
            .finally(() => progress.stop());
    }
}
