import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['heading', 'item', 'itemUrl'];

    selectItem({ params, currentTarget }) {
        this.itemTargets.forEach((item) => {
            item.classList.remove('selected');
            item.classList.remove('portal-item-error');
        });

        this.headingTarget.classList.add('d-none');

        currentTarget.classList.add('selected');

        this.itemUrlTarget.href = params.url;
    }

    navigate(e) {
        if (e.currentTarget.getAttribute('href') == '') {
            e.preventDefault();

            this.itemTargets.forEach((item) =>
                item.classList.add('portal-item-error'),
            );
            this.headingTarget.classList.remove('d-none');
        }
    }
}
