const Observable = require('../../Observable');

/**
 * @param {jQuery} container
 * @param {google.maps.Geocoder} geocoder
 * @param {LocationBag} locationBag
 */
class ClassificationLocation extends Observable {
    constructor(container, geocoder, locationBag) {
        super();

        const locationId = $('.location', container).val();
        const location = locationBag.find(locationId);

        if (!location) {
            throw new Error('Location not found: ' + locationId);
        }

        this.container = container;
        this.geocoder = geocoder;
        this.location = location;
        this.marker = null;
    }

    #renderMarker(map, latLng) {
        if (this.marker) {
            this.marker.position = latLng;
        } else {
            bhqGoogleMaps(() => {
                this.marker = new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: latLng,
                });
            });
        }
    }

    #renderMap(map) {
        const geoPoint = this.location.getGeoPoint();
        if (
            geoPoint &&
            !(geoPoint.latitude === 0 && geoPoint.longitude === 0)
        ) {
            const latLng = {
                lat: geoPoint.getLatitude(),
                lng: geoPoint.getLongitude(),
            };

            map.setCenter(latLng);

            this.#renderMarker(map, latLng);
        } else {
            this.geocoder.geocode(
                { address: this.location.getAddress().getDescription() },
                (results, status) => {
                    if (status === 'OK') {
                        const latLng = results[0].geometry.location;

                        map.setCenter(latLng);

                        this.#renderMarker(map, latLng);
                    }
                },
            );
        }

        $('.address-description', this.container).html(
            this.location.getAddress().getDescription(),
        );
    }

    /** @return {Location} */
    getLocation() {
        return this.location;
    }

    init() {
        const mapContainer = this.container.findOne('.map-container');
        const map = new google.maps.Map(mapContainer.get(0), {
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            mapId: 'BINDHQ_MAP',
            zoom: 14,
        });

        this.#renderMap(map);

        this.location.subscribe('locationUpdated', () => {
            this.#renderMap(map);
        });

        this.container
            .find('.location-number, .collapsed-location-number')
            .html(this.location.getNumber());
    }
}

module.exports = { ClassificationLocation };
