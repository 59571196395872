import { Controller } from '@hotwired/stimulus';

const { CollectionIndexer } = require('../../js/BindHQ/Form/CollectionIndexer');

const {
    LineItemRegenerator,
    LineItemRegeneratorEvents,
} = require('../../js/BindHQ/Form/LineItemRegenerator');

const {
    FormFlowReloader,
    FormFlowReloaderEvents,
} = require('../../js/BindHQ/Form/FormFlowReloader');

const {
    ManualTaxesToggler,
    ManualTaxesTogglerEvents,
} = require('../../js/BindHQ/Quote/ManualTaxesToggler');

export default class extends Controller {
    connect() {
        const container = $(this.element);
        const formFlowReloader = new FormFlowReloader(container);
        const lineItemRegenerator = new LineItemRegenerator(container);
        const collectionIndexer = new CollectionIndexer();
        const manualTaxesToggler = new ManualTaxesToggler(
            container,
            collectionIndexer,
        );

        lineItemRegenerator.subscribe(LineItemRegeneratorEvents.CHANGED, () =>
            formFlowReloader.reload(),
        );
        lineItemRegenerator.subscribe(LineItemRegeneratorEvents.KEYUP, () =>
            formFlowReloader.reload(),
        );
        manualTaxesToggler.subscribe(ManualTaxesTogglerEvents.CHANGED, () =>
            formFlowReloader.reloadNow(),
        );

        formFlowReloader.subscribe(FormFlowReloaderEvents.CHANGED, (xhr) => {
            lineItemRegenerator.render(xhr);
        });

        lineItemRegenerator.init();
        manualTaxesToggler.init();
    }
}
