import { Controller } from '@hotwired/stimulus';

import { MenuLinkHandler } from '../../js/BindHQ/Application/MenuLinkHandler';

export default class extends Controller {
    connect() {
        const container = $(this.element);

        this.menuLinkHandler = new MenuLinkHandler(container);
    }

    /**
     * @param {Event} evt
     */
    onNavigate(evt) {
        this.menuLinkHandler.onNavigate(evt);
    }
}
