import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'lobNameInput',
        'lobUniqueIdentifierInput',
        'lobUniqueIdentifierEditButton',
    ];

    connect() {
        if (!this.lobUniqueIdentifierInputTarget.value) {
            this.nameChanged();
        }
    }

    nameChanged() {
        if (this.#uniqueIdentifierManualOverrideEnabled()) {
            return;
        }

        this.lobUniqueIdentifierInputTarget.value =
            this.#sanitiseAndTransformUniqueIdentifier(
                this.lobNameInputTarget.value,
            );
    }

    toggleUniqueIdentifierReadonlyStatus() {
        if (this.#uniqueIdentifierManualOverrideEnabled()) {
            this.#disableUniqueIdentifierManualOverride();
            this.nameChanged();

            return;
        }

        this.#enableUniqueIdentifierManualOverride();
    }

    #disableUniqueIdentifierManualOverride() {
        this.lobUniqueIdentifierInputTarget.readOnly = true;
        this.lobUniqueIdentifierEditButtonTarget.innerText = 'Edit';
    }

    #enableUniqueIdentifierManualOverride() {
        this.lobUniqueIdentifierInputTarget.readOnly = false;
        this.lobUniqueIdentifierEditButtonTarget.innerText = 'Restore';
    }

    #uniqueIdentifierManualOverrideEnabled() {
        return this.lobUniqueIdentifierEditButtonTarget.innerText === 'Restore';
    }

    #sanitiseAndTransformUniqueIdentifier(uniqueIdentifier) {
        return uniqueIdentifier
            .trim()
            .replace(/[^a-zA-Z0-9\s]+/g, '')
            .replace(/\s+/g, '_')
            .replace(/_+$/, '')
            .replace(/^[^a-zA-Z]+/, '')
            .toLowerCase();
    }
}
