import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['completed', 'configured', 'field', 'unconfigured'];

    static values = {
        editorId: String,
        formName: String,
        formDescription: String,
        freeTextFieldFormName: String,
        withFieldNamesParameter: String,
        previewUrl: String,
    };

    connect() {
        this.displayScrollTop = null;
    }

    onFillClick() {
        this.displayScrollTop = window.scrollY;

        const [editor, display, preview, fields, title, description] =
            this.#getElements();

        const [
            saveButton,
            cancelButton,
            backButton,
            previewButton,
            resetButton,
        ] = this.#getButtons();

        this.#reset(preview);

        saveButton.addEventListener('click', () => this.#onSaveClick());
        cancelButton.addEventListener('click', () => this.#onCancelClick());
        backButton.addEventListener('click', () => this.#onBackClick());
        previewButton.addEventListener('click', () => this.#preview(preview));
        resetButton.addEventListener('click', () => this.#reset(preview));

        title.innerHTML = this.formNameValue;
        description.innerHTML = this.formDescriptionValue;
        editor.classList.remove('d-none');
        display.classList.add('d-none');

        fields.innerHTML = '';

        this.fieldTargets.forEach((field) => {
            const formLabel = document.createElement('label');
            formLabel.innerHTML = field.dataset.formName;

            const formValue = document.createElement('textarea');
            formValue.name =
                this.freeTextFieldFormNameValue +
                '[' +
                field.dataset.formName +
                ']';
            formValue.innerHTML = field.value;
            formValue.placeholder = 'Enter free text input...';

            const formGroup = document.createElement('div');
            formGroup.classList.add('form-group');
            formGroup.appendChild(formLabel);
            formGroup.appendChild(formValue);

            fields.appendChild(formGroup);

            field.valueElement = formValue;
        });
    }

    #onSaveClick() {
        this.configuredTarget.classList.remove('d-none');
        this.unconfiguredTarget.classList.add('d-none');

        this.#save();
        this.#hide();
    }

    #onCancelClick() {
        this.#hide();
    }

    #onBackClick() {
        this.#hide();
    }

    #save() {
        this.completedTarget.value = '1';
        this.fieldTargets.forEach((field) => {
            field.value = field.valueElement.value;
        });
    }

    #hide() {
        const [editor, display, preview, fields] = this.#getElements();

        editor.classList.add('d-none');
        display.classList.remove('d-none');
        preview.src = 'about:blank';
        fields.innerHTML = '';

        window.scrollTo(0, this.displayScrollTop);
    }

    #getElements() {
        const editor = document.getElementById(this.editorIdValue);

        return [
            editor,
            editor.previousElementSibling,
            document.getElementById('free-text-editor-preview'),
            document.getElementById('free-text-editor-fields'),
            document.getElementById('free-text-editor-title'),
            document.getElementById('free-text-editor-description'),
        ];
    }

    #getButtons() {
        const saveButton = document.getElementById(
            'free-text-editor-save-button',
        );
        const cancelButton = document.getElementById(
            'free-text-editor-cancel-button',
        );
        const backButton = document.getElementById(
            'free-text-editor-back-button',
        );
        const previewButton = document.getElementById(
            'free-text-editor-preview-button',
        );
        const resetButton = document.getElementById(
            'free-text-editor-reset-button',
        );

        return [
            this.#clearListeners(saveButton),
            this.#clearListeners(cancelButton),
            this.#clearListeners(backButton),
            this.#clearListeners(previewButton),
            this.#clearListeners(resetButton),
        ];
    }

    /**
     * @param {HTMLElement} preview
     */
    #reset(preview) {
        this.#loadPreview(preview, true);
    }

    /**
     * @param {HTMLElement} preview
     */
    #preview(preview) {
        this.#loadPreview(preview, false);
    }

    /**
     * @param {HTMLElement} preview
     */
    #loadPreview(preview, withFieldNames) {
        const form = this.element.closest('form');
        const originalAction = form.action;

        try {
            form.action =
                this.previewUrlValue +
                (withFieldNames
                    ? '?' + this.withFieldNamesParameterValue + '=1'
                    : '');
            form.target = preview.name;

            form.submit();
        } finally {
            form.action = originalAction;
            form.target = '';
        }
    }

    /**
     * Javascript doesn't seem to have a way to list event listeners, so we have to clone the element and replace it.
     *
     * @param {HTMLElement} element
     */
    #clearListeners(element) {
        const newElement = element.cloneNode(true);
        element.after(newElement);

        const parent = element.parentElement;
        parent.removeChild(element);

        return newElement;
    }
}
