import { Controller } from '@hotwired/stimulus';

const { Collapse } = require('../js/BindHQ/Utils/Collapse');

export default class extends Controller {
    static targets = ['additionalInterests'];

    connect() {
        new Collapse($(this.additionalInterestsTarget));
    }
}
