import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['selected', 'usage'];

    connect() {
        this.#update();
    }

    onToggle() {
        this.#update();
    }

    #update() {
        if (this.selectedTarget.checked) {
            this.#toggleTomSelect(this.usageTarget, false);
        } else {
            this.#toggleTomSelect(this.usageTarget, true);
        }
    }

    /**
     * @param {HTMLElement} control
     * @param {Boolean} isDisabled
     */
    #toggleTomSelect(control, isDisabled) {
        if (control.tomselect) {
            if (isDisabled) {
                control.tomselect.disable();
            } else {
                control.tomselect.enable();
            }
        } else {
            control.disabled = isDisabled;
        }
    }
}
