import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const numeral = require('numeral');
const Progress = require('../../../../js/BindHQ/Utils/Progress');
const qs = require('qs');

export default class extends Controller {
    static targets = [
        'classCode',
        'errors',
        'exposure',
        'lineItems',
        'submit',
        'state',
    ];

    static values = { url: String, lineItemTypes: Object, ineligible: String };

    static debounces = ['rate'];

    connect() {
        useDebounce(this, { wait: 500 });
    }

    premiumBasis() {
        const value = this.classCodeTarget.selectedIndex;
        const premiumBasisCode =
            -1 === value
                ? 'Exposure'
                : this.classCodeTarget.options[value].dataset.premiumBasisCode;

        this.exposureTarget
            .closest('.form-group')
            .querySelector('label').innerHTML = premiumBasisCode;
    }

    rate() {
        if (
            !this.classCodeTarget.value ||
            !this.stateTarget.value ||
            !this.exposureTarget.value
        ) {
            this.#clear();

            return;
        }

        const request = {
            locations: [
                {
                    number: 1,
                    classCode: this.classCodeTarget.value,
                    state: this.stateTarget.value,
                    exposure: this.exposureTarget.value,
                },
            ],
        };

        fetch(this.urlValue + '?' + qs.stringify(request))
            .then((response) => response.json())
            .then((rateResponse) => {
                this.#clear();

                if (this.ineligibleValue === rateResponse.eligibility_status) {
                    this.#ineligible(rateResponse);
                } else {
                    this.#eligible(rateResponse);
                }
            })
            .finally(() => Progress.stop());
    }

    /**
     * @param {Object} rateResponse
     */
    #ineligible(rateResponse) {
        const ul = document.createElement('ul');

        for (const index in rateResponse.reasons) {
            const li = document.createElement('li');
            li.innerHTML = rateResponse.reasons[index];

            ul.appendChild(li);
        }

        this.errorsTarget.appendChild(ul);
        this.errorsTarget.classList.remove('d-none');
        this.submitTarget.classList.add('d-none');
    }

    /**
     * @param {Object} rateResponse
     */
    #eligible(rateResponse) {
        this.#lineItem(
            'Premium',
            this.#formatCurrency(rateResponse.premium.amount),
        );

        rateResponse.line_items.forEach((line_item) => {
            this.#lineItem(
                this.lineItemTypesValue[line_item.type],
                this.#formatCurrency(line_item.amount.amount),
            );
        });

        this.#lineItem('', '');

        const minimumPremium = this.#lineItem(
            'Minimum Premium',
            this.#formatCurrency(rateResponse.minimum_premium.amount),
        );
        this.#lineItem('Rate', rateResponse.locations[0].rate);

        this.submitTarget.classList.remove('d-none');
    }

    /**
     * @param {String} label
     * @param {String} value
     *
     * @return {HTMLElement}
     */
    #lineItem(label, value) {
        const labelElement = document.createElement('td');
        labelElement.innerHTML = label;
        labelElement.classList.add('font-weight-bold');
        labelElement.classList.add('mb-2');
        labelElement.classList.add('border-0');

        const valueElement = document.createElement('td');
        valueElement.innerHTML = value;
        valueElement.classList.add('align-right');
        valueElement.classList.add('border-0');

        const tr = document.createElement('tr');
        tr.appendChild(labelElement);
        tr.appendChild(valueElement);

        this.lineItemsTarget.append(tr);

        return tr;
    }

    #clear() {
        this.errorsTarget.classList.add('d-none');
        this.errorsTarget.innerHTML = '';
        this.lineItemsTarget.innerHTML = '';
    }

    /**
     * @param {String} value
     *
     * @return {String}
     */
    #formatCurrency(value) {
        return numeral(value).format('$0,00.00');
    }
}
