import { Controller } from '@hotwired/stimulus';

const Progress = require('../../js/BindHQ/Utils/Progress');
const qs = require('qs');

export default class extends Controller {
    static targets = [
        'body',
        'preview',
        'previewLink',
        'subject',
        'type',
        'writeLink',
        'inputs',
    ];

    static values = {
        url: String,
        replacements: Object,
    };

    onWriteClick() {
        this.#show(this.writeLinkTarget);
    }

    onPreviewClick() {
        this.#show(this.previewLinkTarget);
        this.previewTarget.innerHTML = 'Loading...';

        const request = {
            type: this.typeTarget.value,
            subject: this.subjectTarget.value,
            body: this.bodyTarget.value,
        };

        Progress.start();

        fetch(this.urlValue + '?' + qs.stringify(request))
            .then((response) => response.text())
            .then((html) => {
                this.previewTarget.innerHTML = html;
            })
            .finally(() => Progress.stop());
    }

    #show(element) {
        const url = new URL(element.href);

        bindhq.tabs.show(url.hash.substring(1));
    }
}
