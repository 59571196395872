import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['form', 'baseVersionInput', 'changedVersionInput'];

    submitForm() {
        this.formTarget.submit();
        this.baseVersionInputTarget.disabled = true;
        this.changedVersionInputTarget.disabled = true;
    }
}
