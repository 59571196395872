class Collapse {
    /**
     * Initialize default values for Accordion functionality
     * @param {jQuery} container
     */
    constructor(container) {
        this.container = container;
        this.plusIcon = '<i class="fa fa-plus icon-white"></i>';
        this.minusIcon = '<i class="fa fa-minus icon-white"></i>';

        this.initCollapse();
    }

    /**
     * @param {jQuery} elem
     */
    display(elem) {
        if (elem) {
            elem.removeClass('hidden');
        } else {
            throw new Error('elem is not a jQuery object');
        }
    }

    /**
     *
     * @param {jQuery} elem
     */
    hide(elem) {
        if (elem) {
            elem.addClass('hidden');
            elem.parent()
                .find('.collapse')
                .each((_, el) => {
                    if ($(el) !== elem) {
                        $(el).collapse('show');
                        const toggles = $(el).siblings(
                            '[data-toggle="collapse"]',
                        );
                        for (const toggle of toggles) {
                            this.toggleIcon($(toggle), false);
                        }
                    }
                });
        } else {
            throw new Error('elem is not a jQuery object');
        }
    }

    /**
     *
     * @param {jQuery} elem
     * @param {boolean} collapsed
     */
    toggleIcon(elem, collapsed) {
        if (elem) {
            collapsed ? elem.html(this.plusIcon) : elem.html(this.minusIcon);
        }
    }

    /**
     *
     * @param {jQuery} elem
     * @param {boolean} show
     */
    toggleCollapsibleHeader(elem, show) {
        const header = elem.parent().find('.collapsible-header');
        if (header) {
            show ? this.display(header) : this.hide(header);
        }
    }

    initCollapse() {
        const self = this;
        $(this.container).on('click', '[data-toggle="collapse"]', function () {
            const target = $(this).attr('href');
            self.toggleIcon($(this), $(target).hasClass('show'));
            self.toggleCollapsibleHeader($(this), $(target).hasClass('show'));
        });

        $(this.container).on('click', 'a.collapse-show', function () {
            const target = $(this).attr('href');
            const $toggleButton = $(target)
                .parent()
                .find('[data-toggle="collapse"]');
            $(target).collapse('show');
            self.toggleIcon($toggleButton, false);
            self.toggleCollapsibleHeader($toggleButton, false);
        });
    }
}

module.exports = { Collapse };
