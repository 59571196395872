import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    static values = {
        url: String,
        formName: String,
    };

    connect() {
        this.tomSelect = new TomSelect(this.element, {
            onChange: () => this.#changed(),
            plugins: ['dropdown_input'],
        });
    }

    #changed() {
        const currentValue = this.tomSelect.getValue();

        const data = new FormData();
        data.append(this.formNameValue + '[status]', currentValue);

        const params = {
            method: 'POST',
            body: data,
        };

        this.tomSelect.disable();

        fetch(this.urlValue, params)
            .then((response) => response.json())
            .then((statuses) => {
                this.tomSelect.clearOptions();
                this.tomSelect.addOptions(
                    _.map(statuses, (value, label) => {
                        return {
                            value: value,
                            text: label,
                        };
                    }),
                );
                this.tomSelect.enable();
            });

        this.#updateLabel(currentValue);
    }

    /**
     * @param {String} currentValue
     */
    #updateLabel(currentValue) {
        this.element
            .closest('tr')
            .querySelectorAll('.status-indicator')
            .forEach((indicator) => {
                indicator.classList.forEach((className) => {
                    if (
                        className !== 'status-indicator' &&
                        className.startsWith('status-')
                    ) {
                        indicator.classList.remove(className);
                    }
                });

                const newClassName = currentValue
                    .replaceAll('_', '-')
                    .replaceAll(' ', '-')
                    .replaceAll('(', '')
                    .replaceAll(')', '');

                indicator.classList.add('status-' + newClassName);
            });
    }
}
