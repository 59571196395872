const ElementExtractor = require('./Utils/ElementExtractor');

/**
 *
 * @param {number} value
 * @returns {string}
 */
const numberFormat = (value) => {
    let formatted = '';
    let divider = 1000000;
    let symbol = 'm';
    if (value < 1000000 && value >= 1000) {
        divider = 1000;
        symbol = 'k';
    } else if (value < 100000) {
        divider = 1;
        symbol = '';
    }

    formatted = `${value / divider}${symbol}`;
    return formatted;
};

const SummaryTransformers = {
    /**
     * @param {jQuery} elem
     */
    deductibles: (elem) => {
        const deductibles = [];

        elem.find('.collection-item').each((_, item) => {
            const amount = $(item)
                .find('select.deductible-amount')
                .last()
                .get(0);
            const type = $(item).find('select.applied-to').last().get(0);

            if (amount.value) {
                deductibles.push(
                    `$${numberFormat(amount.value / 100)} ${
                        type.value
                            ? type.options[type.selectedIndex].innerHTML
                            : ''
                    }`,
                );
            }
        });

        if (!deductibles.length) {
            deductibles.push('n/a');
        }

        return deductibles.join(' / ');
    },

    /**
     * @param {jQuery} elem
     * @returns {string|number}
     */
    generalMoneyElement: (elem) => {
        const extracted = ElementExtractor.extractValue(elem.get(0));
        const value = typeof extracted === 'object' ? extracted.id : extracted;
        const parsed = parseFloat(value / 100); // remove decimals
        if (isNaN(parsed)) {
            return 0;
        }
        return `$${numberFormat(parsed)}`;
    },
};

module.exports = { SummaryTransformers };
