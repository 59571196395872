class TermExpiryCalculator {
    /**
     * @param {DateTime} termEffective
     * @param {String} policyTermCode
     *
     * @return {Date}
     */
    calculate(termEffective, policyTermCode) {
        const months = parseInt(policyTermCode.match(/\d+/)[0], 10);

        return termEffective.plus({ months });
    }
}

module.exports = { TermExpiryCalculator };
