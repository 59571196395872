import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    onClick() {
        this.element
            .closest('form')
            .querySelectorAll('[required]')
            .forEach((element) => {
                element.required = false;
            });
    }
}
