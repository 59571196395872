import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox', 'button'];

    connect() {
        this.checkboxTarget.addEventListener(
            'change',
            this.toggleButtonState.bind(this),
        );

        this.toggleButtonState();
    }

    toggleButtonState() {
        if (this.checkboxTarget.checked) {
            this.buttonTarget.removeAttribute('disabled');
        } else {
            this.buttonTarget.setAttribute('disabled', 'disabled');
        }
    }
}
