class Address {
    /**
     * @param {String} line1
     * @param {String} line2
     * @param {String} city
     * @param {String} state
     * @param {String} internationalState
     * @param {String} postalCode
     * @param {String} county
     * @param {String} countyFips
     */
    constructor(
        line1,
        line2,
        city,
        state,
        internationalState,
        postalCode,
        county,
        countyFips,
    ) {
        this.line1 = line1;
        this.line2 = line2;
        this.city = city;
        this.state = state;
        this.internationalState = internationalState;
        this.postalCode = postalCode;
        this.county = county;
        this.countyFips = countyFips;
    }

    /** @return {String} */
    getLine1() {
        return this.line1;
    }

    /** @return {String} */
    getLine2() {
        return this.line2;
    }

    /** @return {String} */
    getCity() {
        return this.city;
    }

    /** @return {String} */
    getState() {
        return this.state;
    }

    /** @return {String} */
    getInternationalState() {
        return this.internationalState;
    }

    /** @return {String} */
    getPostalCode() {
        return this.postalCode;
    }

    /** @return {String} */
    getCounty() {
        return this.county;
    }

    /** @return {String} */
    getCountyFips() {
        return this.countyFips;
    }

    /** @return {String} */
    getDescription() {
        const parts = [
            this.line1,
            this.line2,
            this.city,
            this.state,
            this.internationalState,
            this.postalCode,
            this.county,
        ];

        return parts.filter((x) => x).join(', ');
    }
}

module.exports = { Address };
