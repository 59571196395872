const progress = require('../Utils/Progress');

const Observable = require('../Observable');

const FormFlowReloaderEvents = {
    CHANGED: 'bindhq.form.form_reloader.changed',
};

const STATUS_CANCELLED = 0;
const STATUS_BAD_REQUEST = 400;

class FormFlowReloader extends Observable {
    constructor(container, action) {
        super();

        this.container = container;
        this.action = action ?? this.container.attr('action');
        this.xhr = null;
        this.debounce = _.debounce(() => this.reloadNow(), 1000);
    }

    reload() {
        this.debounce();
    }

    reloadNow() {
        progress.start();

        if (null !== this.xhr) {
            this.xhr.abort();
            this.xhr = null;
        }

        this.xhr = $.ajax({
            type: 'POST',
            url: this.action,
            data:
                this.container.formSerialize() +
                '&flow_transition_to=' +
                this.container.data('flow-current-step-number'),
            success: (html, status, xhr) => this.onSuccess(xhr),
            error: (xhr) => this.onError(xhr),
            complete: () => this.onComplete(),
        });
    }

    onSuccess(xhr) {
        super.fire(FormFlowReloaderEvents.CHANGED, xhr);
    }

    onError(xhr) {
        if (STATUS_BAD_REQUEST === xhr.status) {
            super.fire(FormFlowReloaderEvents.CHANGED, xhr);
        } else if (STATUS_CANCELLED !== xhr.status) {
            console.error(
                'The was an error calculating taxes: ' + xhr.responseText,
            );
        }
    }

    onComplete(xhr) {
        progress.stop();

        this.xhr = null;
    }
}

module.exports = { FormFlowReloader, FormFlowReloaderEvents };
