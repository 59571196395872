import { Controller } from '@hotwired/stimulus';

const { ProgramCriteria } = require('../../js/BindHQ/Utils/ProgramCriteria');
const { ProgramSelector } = require('../../js/BindHQ/Utils/ProgramSelector');

export default class extends Controller {
    static values = {
        application: Number,
    };

    connect() {
        const container = $(this.element);
        const quoteTemplateSelector = '#quote_basic_information_quoteTemplate';
        const quoteTemplate = $(quoteTemplateSelector);

        const programSelector = '#quote_basic_information_program';
        const program = $(programSelector);

        if (quoteTemplate.length > 1) {
            throw new Error(
                'Multiple elements for ID ' +
                    quoteTemplateSelector +
                    ' found, expected either none or one',
            );
        }

        if (program.length > 1) {
            throw new Error(
                'Multiple elements for ID ' +
                    programSelector +
                    ' found, expected either none or one',
            );
        }

        if (1 === quoteTemplate.length) {
            const homeState = container.findOne('select.home-state');
            const carrier = container.findOne('input.carrier');
            const marketingCompany = container.findOne(
                'input.marketing-company',
            );
            const businessType = container.findOne('select.business-type');

            const programCriteria = new ProgramCriteria(
                homeState,
                carrier,
                marketingCompany,
                businessType,
                container.find(
                    '#quote_basic_information_linesOfBusiness .checkbox input',
                ),
            );

            new ProgramSelector(
                container.findOne('.program-selector-container'),
                container.data('program-selector-url'),
                this.applicationValue,
                programCriteria,
                quoteTemplate.val() || program.val(),
            );

            program.remove();
            quoteTemplate.remove();
            programCriteria.init();
        }
    }
}
