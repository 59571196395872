import { Controller } from '@hotwired/stimulus';

const { BigDecimalField } = require('../../js/BindHQ/Form/BigDecimalField');

export default class extends Controller {
    connect() {
        const bigDecimal = new BigDecimalField(this.element);
        bigDecimal.init();
    }
}
