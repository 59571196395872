const CodeMirror = require('codemirror');
require('codemirror/addon/hint/show-hint');
require('codemirror/addon/hint/show-hint.css');
const _ = require('lodash');

class HTMLEditor {
    #codeMirrorInstance;

    autoCompletions = [];

    wordCharsRegex;

    elements = [];

    static #defaultOptions = {
        mode: 'htmlmixed',
        lineNumbers: true,
        autocomplete: {
            wordCharsRegex: /\S/,
            enabled: false,
            data: [],
        },
    };

    /**
     * @param {HTMLElement} element
     * @param options
     */
    constructor(element, options = {}) {
        const mergedOptions = _.merge(
            {},
            this.constructor.#defaultOptions,
            options,
        );

        const config = {
            mode: mergedOptions.mode,
            lineNumbers: true,
            autoCloseTags: true,
        };

        if (mergedOptions.autocomplete.enabled) {
            this.autoCompletions = mergedOptions.autocomplete.data;
            this.wordCharsRegex = mergedOptions.autocomplete.wordCharsRegex;

            config.hintOptions = {
                hint: this.#getAutoCompletion(),
                completeSingle: false,
            };
        }
        CodeMirror.registerHelper('wordChars', 'wordChars', /[^\s]+/);

        this.#codeMirrorInstance = CodeMirror.fromTextArea(element, config);

        this.#codeMirrorInstance.on('inputRead', (cm) => {
            cm.showHint();
        });

        /**
         * Need this because inputRead won't trigger on a backspace.
         */
        this.#codeMirrorInstance.on('keydown', (cm, event) => {
            if (event.key === 'Backspace') {
                cm.showHint();
            }
        });
    }

    /**
     * @param {string} text
     */
    insertContentAtCursor(text) {
        if (this.#codeMirrorInstance.getValue().trim() === '') {
            this.#codeMirrorInstance.setValue(text);
            return;
        }

        const doc = this.#codeMirrorInstance.getDoc();
        const cursor = doc.getCursor();
        const line = cursor.line;
        let lineContent = doc.getLine(line);

        let lastTagMatch = lineContent.match(/<\/?[^>]+>$/);
        let currentLine = line;
        while (!lastTagMatch && currentLine > 0) {
            currentLine--;
            lineContent = doc.getLine(currentLine);
            lastTagMatch = lineContent.match(/<\/?[^>]+>$/);
        }

        const isClosingTag = lastTagMatch && lastTagMatch[0].startsWith('</');
        const firstNonWhitespaceIndex = lineContent.search(/\S|$/);
        let indentation = lineContent.substring(0, firstNonWhitespaceIndex);

        if (!isClosingTag) {
            indentation += '\t';
        }

        const indentedText = text
            .split('\n')
            .map((line) => `${indentation}${line}`)
            .join('\n');

        doc.replaceRange(`${indentedText}\n`, { line: line + 1, ch: 0 });
    }

    #getAutoCompletion() {
        return (cm) => {
            const cursor = cm.getCursor();
            const wordRange = cm.findWordAt(cursor);
            let currentPosition = cursor.ch;
            const currentLine = cursor.line;
            let currentWord = '';

            while (0 < currentPosition) {
                const char = cm.getRange(
                    { line: currentLine, ch: currentPosition - 1 },
                    { line: currentLine, ch: currentPosition },
                );

                if (!this.wordCharsRegex.test(char)) {
                    break;
                }

                currentWord = char + currentWord;

                --currentPosition;
            }

            if ('' === currentWord) {
                return null;
            }

            const list = this.autoCompletions.filter((item) =>
                item.includes(currentWord),
            );

            return {
                list: list,
                from: CodeMirror.Pos(currentLine, currentPosition),
                to: wordRange.head,
            };
        };
    }

    getCodeMirrorInstance() {
        return this.#codeMirrorInstance;
    }

    getValue() {
        return this.#codeMirrorInstance.getDoc().getValue();
    }
}

module.exports = { HTMLEditor };
