const { FormListener } = require('./FormListener');
const { SummaryTransformers } = require('./SummaryTransformers');
const assert = require('assert');

class SummaryListener {
    /**
     *
     * @param {jQuery} container
     */
    constructor(container) {
        assert.strictEqual(
            1,
            container.length,
            'Expected exactly one element for container',
        );

        this.container = container;
        this.formListener = new FormListener(container);
    }

    /**
     *
     * @param {jQuery} elem
     * @param {string|object} value
     * @param {string} prop
     */
    updateSummary(elem, value, prop) {
        const text = typeof value === 'object' ? value[prop] : value;
        elem.html(text);
    }

    init() {
        const $summaries = $('[data-summary-id]', this.container);
        for (const summary of $summaries) {
            const $summary = $(summary);
            const fieldId = $summary.data('summary-id');
            const child = $summary.data('child');
            const transformerKey = $summary.data('summary-transformer');
            const prop = $summary.data('summary-prop') || 'text';
            if (child) {
                this.formListener.listenForChild(fieldId, child, () => {
                    if (SummaryTransformers.hasOwnProperty(transformerKey)) {
                        const value = SummaryTransformers[transformerKey](
                            $(`#${fieldId}`),
                        );
                        this.updateSummary($summary, `${value}`, prop);
                    } else {
                        throw new Error(
                            `listenForChild in element ${fieldId} does not have a matching transformer in SummaryTransformers`,
                        );
                    }
                });
            } else {
                this.formListener.listenFor(fieldId, (value) => {
                    const transformer = SummaryTransformers[transformerKey];

                    this.updateSummary(
                        $summary,
                        transformer
                            ? `${transformer($(`#${fieldId}`))}`
                            : value,
                        prop,
                    );
                });
            }
        }
    }
}

module.exports = { SummaryListener };
