import { Controller } from '@hotwired/stimulus';

const {
    CollectionIndexer,
} = require('../../../js/BindHQ/Form/CollectionIndexer');

export default class extends Controller {
    static outlets = ['collapse', 'form--collection'];

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();

        const [nextIndex, nextNumber] = this.#nextData();
        const oldItem = this.element.closest('.collection-item');

        const oldItemPrefix = oldItem
            .querySelector('.sub-location-number')
            .name.replace('[number]', '');

        const newItemPrefix =
            oldItem
                .querySelector('.sub-location-number')
                .name.replace(/\[\d+\]\[number\]/, '') +
            '[' +
            nextIndex +
            ']';

        const newItem = document.createElement('div');
        newItem.innerHTML = oldItem.innerHTML;

        this.#updateFieldNames(newItem, oldItemPrefix, newItemPrefix);
        this.#updatePrototypes(newItem, oldItemPrefix, newItemPrefix);
        this.#updateAttributes(newItem, oldItem);
        this.#updateUuids(newItem);
        this.#resetTomSelects(newItem);
        this.#resetSelect2s(newItem);
        this.#copySelectValues(newItem, newItemPrefix, oldItem, oldItemPrefix);
        this.#updateNumber(newItem, nextNumber);

        this.formCollectionOutlet.addItem(newItem);

        this.collapseOutlets.forEach((outlet) => {
            if (outlet.element === newItem) {
                outlet.expand();
            }
        });

        window.scrollTo({
            top: newItem.offsetTop + 200, // 200 offsets past the page header
            behavior: 'smooth',
        });
    }

    /**
     * TomSelect/Select2 do not flush their value to the underlying control when
     * they are changed, so we need to do that manually.
     *
     * @param {HTMLElement} oldItem
     */
    #copySelectValues(newItem, newItemPrefix, oldItem, oldItemPrefix) {
        const getNewElement = function (oldElement) {
            const newSelector = oldElement.name.replace(
                oldItemPrefix,
                newItemPrefix,
            );

            const newElement = newItem.querySelector(`[name="${newSelector}"]`);

            if (!newElement) {
                throw new Error(
                    `Could not find element with name "${newSelector}"`,
                );
            }

            return newElement;
        };

        // TomSelect elements.

        oldItem.querySelectorAll('.tomselected').forEach((oldElement) => {
            const value = oldElement.tomselect.getValue();
            const newElement = getNewElement(oldElement);
            const values = Array.isArray(value) ? value : [value];

            _.forEach(newElement.options, (option) => {
                option.selected = values.includes(option.value);
            });
        });

        // Select2 elements.

        oldItem
            .querySelectorAll('.select2-container + input')
            .forEach((oldElement) => {
                const data = $(oldElement).select2('data');
                const newElement = getNewElement(oldElement);

                if (!data) {
                    newElement.value = null;

                    return;
                }

                newElement.value = data.id;
                newElement.dataset.initial = JSON.stringify(data);
            });

        // Text elements.

        oldItem
            .querySelectorAll(
                'input[type="text"][name], input[type="number"][name]',
            )
            .forEach((oldElement) => {
                const value = oldElement.value;

                getNewElement(oldElement).value = value;
            });

        // Plain SELECT elements.

        oldItem
            .querySelectorAll('select:not(.tomselected)')
            .forEach((oldElement) => {
                const newElement = getNewElement(oldElement);

                for (let i = 0; i < oldElement.options.length; i++) {
                    newElement.options[i].selected =
                        oldElement.options[i].selected;
                }
            });
    }

    /**
     * @param {HTMLElement} newItem
     * @param {Number} nextNumber
     */
    #updateNumber(newItem, nextNumber) {
        newItem.querySelector('.sub-location-number').value = nextNumber;
        newItem.querySelector('.collapsed-sub-location-number').innerHTML =
            nextNumber;
    }

    /**
     * @param {HTMLElement} newItem
     * @param {String} oldItemPrefix
     * @param {String} newItemPrefix
     */
    #updateFieldNames(newItem, oldItemPrefix, newItemPrefix) {
        newItem.querySelectorAll('[name]').forEach((input) => {
            input.name = input.name.replace(oldItemPrefix, newItemPrefix);
        });
    }

    /**
     * @param {HTMLElement} newItem
     * @param {String} oldItemPrefix
     * @param {String} newItemPrefix
     */
    #updatePrototypes(newItem, oldItemPrefix, newItemPrefix) {
        const oldItemNamePrefix = this.#toPrototypePrefix(oldItemPrefix);
        const newItemNamePrefix = this.#toPrototypePrefix(newItemPrefix);

        newItem.querySelectorAll('[data-prototype]').forEach((input) => {
            input.dataset.prototype = input.dataset.prototype.replaceAll(
                oldItemPrefix,
                newItemPrefix,
            );
            input.dataset.prototype = input.dataset.prototype.replaceAll(
                oldItemNamePrefix,
                newItemNamePrefix,
            );
        });
    }

    /**
     * @param {String} prefix
     *
     * @return {String}
     */
    #toPrototypePrefix(prefix) {
        return prefix
            .replaceAll('][', '_')
            .replaceAll('[', '_')
            .replaceAll(']', '_');
    }

    /**
     * @param {HTMLElement} newItem
     */
    #resetTomSelects(newItem) {
        newItem.querySelectorAll('.ts-wrapper').forEach((tomSelect) => {
            tomSelect.parentNode.removeChild(tomSelect);
        });

        newItem.querySelectorAll('.tomselected').forEach((input) => {
            input.classList.remove('tomselected');
            input.classList.remove('ts-hidden-accessible');
        });
    }

    /**
     * @param {HTMLElement} newItem
     */
    #resetSelect2s(newItem) {
        newItem
            .querySelectorAll('.select2-container + input')
            .forEach((input) => {
                input.removeAttribute('style');
            });

        newItem.querySelectorAll('.select2-container').forEach((select2) => {
            select2.parentNode.removeChild(select2);
        });
    }

    /**
     * @param {HTMLElement} newItem
     * @param {HTMLElement} oldItem
     */
    #updateAttributes(newItem, oldItem) {
        oldItem.getAttributeNames().forEach((attr) => {
            newItem.setAttribute(attr, oldItem.getAttribute(attr));
        });
    }

    /**
     * @param {HTMLElement} newItem
     */
    #updateUuids(newItem) {
        newItem
            .querySelectorAll('input[type="hidden"].uuid')
            .forEach((input) => {
                input.value = bindhq.util.uuid4();
            });
    }

    /**
     * @return {Array}
     */
    #nextData() {
        let index = 0;
        let number = 1;

        this.formCollectionOutlet.element
            .querySelectorAll('.sub-location-number')
            .forEach((item) => {
                const thisIndex = parseInt(
                    item.name.match(/\[(\d+)\]\[number\]/)[1],
                    10,
                );
                const thisNumber = parseInt(item.value, 10);

                if (thisIndex >= index) {
                    index = thisIndex + 1;
                }

                if (thisNumber >= number) {
                    number = thisNumber + 1;
                }
            });

        return [index, number];
    }
}
