class FormSearcher {
    /**
     * @param {HTMLElement} input
     * @param {Array} results
     */
    constructor(input, results) {
        this.input = input;
        this.results = results;
        this.debounce = _.debounce(() => this.reload(), 500);
    }

    /**
     *
     */
    onChange() {
        this.debounce();
    }

    /**
     *
     */
    reload() {
        const query = this.toSearchText(this.input.value);

        _.each(this.results, (result) => {
            if (
                '' === query ||
                -1 !== this.toSearchText($(result).text()).indexOf(query)
            ) {
                result.classList.remove('d-none');
            } else {
                result.classList.add('d-none');
            }
        });
    }

    /**
     * @param {String} text
     *
     * @return {String}
     */
    toSearchText(text) {
        return text.replace(/[\s-]+/g, '').toLowerCase();
    }
}

module.exports = { FormSearcher };
