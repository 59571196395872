import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'existingFile',
        'field',
        'fields',
        'file',
        'filename',
        'fixedValue',
        'preview',
        'previewWrapper',
        'noPreviewWrapper',
        'token',
        'upload',
        'uploadWrapper',
    ];

    static values = {
        previewUrl: String,
        uploadUrl: String,
        uploadMaxSizeBytes: Number,
        withFieldNamesParameter: String,
    };

    connect() {
        if (this.hasFileTarget) {
            this.#preview();
        }
    }

    onUploadChange() {
        this.#upload();
    }

    onPreviewClick() {
        this.#preview();
    }

    onResetClick() {
        this.#preview(true);
    }

    onFileDeleteClick(event) {
        event.preventDefault();

        const clickEvent = new CustomEvent('confirm--button:clicked', {
            detail: {
                title: 'Do you want to delete this file?',
                body: 'All previously entered attributes will be lost.',
                confirm: 'Delete',
                onConfirm: () => {
                    this.existingFileTarget.classList.replace(
                        'd-block',
                        'd-none',
                    );
                    this.previewWrapperTarget.classList.replace(
                        'd-flex',
                        'd-none',
                    );
                    this.noPreviewWrapperTarget.classList.replace(
                        'd-none',
                        'd-flex',
                    );
                    this.uploadWrapperTarget.classList.remove('d-none');
                    this.uploadTarget.value = '';

                    this.fieldsTarget.innerHTML = '';

                    window.dispatchEvent(
                        new CustomEvent('confirm--dialog:closeDialog'),
                    );
                },
            },
        });

        window.dispatchEvent(clickEvent);
    }

    #upload() {
        const file = this.uploadTarget.files[0];

        if (file.size > this.uploadMaxSizeBytesValue) {
            const megabytes = Math.round(
                this.uploadMaxSizeBytesValue / 1000000,
            );

            this.fieldsTarget.innerHTML = `
                <span class="invalid-feedback mb-0 mt-2 d-block">
                    <span class="bindhq-error-box d-block">
                        <span class="form-error-icon badge badge-danger text-uppercase">Error</span>
                        <span id="template_fillable_name-error" class="error form-error-message">The uploaded file must be smaller than ${megabytes}MB.</span>
                    </span>
                </span>
            `;

            return;
        }

        const data = new FormData();
        data.append('upload', file);

        const filename = this.uploadTarget.value.replace('C:\\fakepath\\', '');

        fetch(this.uploadUrlValue, {
            method: 'POST',
            body: data,
        })
            .then(async (response) => [response, await response.text()])
            .then(([response, html]) => {
                if (!response.ok) {
                    this.fieldsTarget.innerHTML = html;
                } else {
                    this.filenameTarget.innerHTML = filename;
                    this.existingFileTarget.classList.replace(
                        'd-none',
                        'd-block',
                    );
                    this.previewWrapperTarget.classList.replace(
                        'd-none',
                        'd-flex',
                    );
                    this.noPreviewWrapperTarget.classList.replace(
                        'd-flex',
                        'd-none',
                    );
                    this.uploadWrapperTarget.classList.add('d-none');
                    this.fieldsTarget.innerHTML = html;
                    this.#preview(true);
                }
            });
    }

    #preview(withFieldNames) {
        if (!this.hasFileTarget) {
            return;
        }

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = this.previewUrlValue;
        form.target = this.previewTarget.name;
        form.enctype = 'multipart/form-data';

        const formToken = document.createElement('input');
        formToken.type = 'hidden';
        formToken.name = this.tokenTarget.name;
        formToken.value = this.tokenTarget.value;
        form.appendChild(formToken);

        const formFile = document.createElement('input');
        formFile.type = 'hidden';
        formFile.name = this.fileTarget.name;
        formFile.value = this.fileTarget.value;
        form.appendChild(formFile);

        this.fieldTargets.forEach((field) => {
            const name = document.createElement('input');
            name.type = 'hidden';
            name.name = field.name.replace('[type]', '[name]');
            name.value = field.dataset.name;

            const fixedValue = document.createElement('input');
            fixedValue.type = 'hidden';
            fixedValue.name = field.name.replace('[type]', '[fixedValue]');
            fixedValue.value = this.#findValue(
                this.fixedValueTargets,
                field.closest('fieldset'),
            );

            const type = document.createElement('input');
            type.type = 'hidden';
            type.name = field.name;
            type.value = field.tomselect
                ? field.tomselect.getValue()
                : field.value;

            form.appendChild(name);
            form.appendChild(type);
            form.appendChild(fixedValue);
        });

        if (withFieldNames) {
            form.action += '?' + this.withFieldNamesParameterValue + '=1';
        }

        const parent = this.element.parentNode;
        parent.appendChild(form);
        form.submit();
        parent.removeChild(form);
    }

    #findValue(targets, element) {
        return targets.find((target) => element.contains(target)).value;
    }
}
