import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'dateTypeSelector',
        'dateFromField',
        'dateToField',
        'dateRangePickerWrapper',
    ];

    connect() {
        this.renderDateFields();
    }

    onDateTypeChanged() {
        this.renderDateFields();
    }

    renderDateFields() {
        if ('' === this.dateTypeSelectorTarget.value) {
            this.dateToFieldTarget.value = '';
            this.dateFromFieldTarget.value = '';
            this.dateToFieldTarget.removeAttribute('required');
            this.dateFromFieldTarget.removeAttribute('required');
            this.dateRangePickerWrapperTarget.classList.add('hidden');
        } else {
            this.dateToFieldTarget.setAttribute('required', true);
            this.dateFromFieldTarget.setAttribute('required', true);
            this.dateRangePickerWrapperTarget.classList.remove('hidden');
        }
    }
}
