import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['title', 'tags', 'extension'];

    onClickSave() {
        const fileId = this.element.dataset.fileId;

        const title = document.querySelector(
            `[data-file-id="${fileId}"] .title`,
        );

        const tags = document.querySelector(`[data-file-id="${fileId}"] .tags`);

        const selectedTags = $(this.tagsTarget).select2('data');

        if (tags) {
            tags.replaceChildren();

            selectedTags.forEach((element) => {
                const tagli = `<li class="tag" data-tag="${
                    element.id
                }"><span class="label">${element.text.trim()}</span>&nbsp;</li>`;

                tags.insertAdjacentHTML('beforeend', tagli);
            });
        }

        const ext = this.hasExtensionTarget
            ? this.extensionTarget.innerHTML
            : '';

        title.innerHTML = this.titleTarget.value + ext;
    }

    onClickDelete() {
        const fileId = this.element.dataset.fileId;

        const deleteRow = document.querySelector(`[data-file-id="${fileId}"]`);

        if (deleteRow.dataset.fileCanDelete === 'true') {
            deleteRow.remove();
        }
    }
}
