import { Controller } from '@hotwired/stimulus';
import { Collapse } from 'bootstrap';

export default class extends Controller {
    static targets = ['content', 'preview', 'toggle', 'showing', 'hidden'];

    static values = {
        openByDefault: { type: Boolean, default: true },
        validateOnCollapse: { type: Boolean, default: false },
    };

    connect() {
        if (this.hasToggleTarget) {
            this.toggleTarget.addEventListener('click', (event) =>
                this.onShowHide(event),
            );
        }

        if (this.hasDefinedToggleStates()) {
            this.hiddenTarget.addEventListener('click', (event) =>
                this.onShowHide(event),
            );
            this.showingTarget.addEventListener('click', (event) =>
                this.onShowHide(event),
            );

            this.renderDefinedToggleStates();
        }

        this.contentTarget.classList.add('collapse');

        this.element.classList.toggle('is-collapsed', !this.openByDefaultValue);

        this.content = new Collapse(this.contentTarget, {
            toggle: this.openByDefaultValue,
        });

        if (this.hasPreviewTarget) {
            this.previewTarget.addEventListener('click', (event) => {
                this.onShowHide(event);
            });

            this.previewTarget.classList.add('collapse');

            this.preview = new Collapse(this.previewTarget, {
                toggle: !this.openByDefaultValue,
            });
        }

        this.#resizeTextarea();
    }

    onShowHide(event) {
        event.preventDefault();

        if (
            this.validateOnCollapseValue &&
            !this.element.classList.contains('is-collapsed')
        ) {
            const allFields = Array.from(
                this.contentTarget.querySelectorAll(
                    '.form-control:not(.select2-container):not(.ts-wrapper), select.tomselected',
                ),
            );

            allFields.forEach((input) => {
                $(input).valid();
            });

            const fieldsValid = allFields.every((element) =>
                $(element).valid(),
            );

            if (fieldsValid) {
                this.#toggleShowHide();
            }
        } else {
            this.#toggleShowHide();
        }

        if (this.hasDefinedToggleStates()) {
            this.renderDefinedToggleStates();
        }
    }

    renderDefinedToggleStates() {
        this.showingTarget.classList.toggle(
            'd-none',
            this.element.classList.contains('is-collapsed'),
        );
        this.hiddenTarget.classList.toggle(
            'd-none',
            !this.element.classList.contains('is-collapsed'),
        );
    }

    #toggleShowHide() {
        this.content.toggle();

        if (this.hasPreviewTarget) {
            this.preview.toggle();
        }

        this.element.classList.toggle('is-collapsed');

        this.#resizeTextarea();
    }

    #resizeTextarea() {
        const textareas = this.element.querySelectorAll(
            '[data-controller~="textarea-autogrow"]',
        );

        textareas.forEach((textarea) => {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }

    hasDefinedToggleStates() {
        return this.hasHiddenTarget && this.hasShowingTarget;
    }
}
