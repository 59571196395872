import { Controller } from '@hotwired/stimulus';

const CodeMirror = require('codemirror');

export default class extends Controller {
    connect() {
        CodeMirror.fromTextArea(this.element, {
            mode: 'javascript',
            lineNumbers: true,
        });
    }
}
