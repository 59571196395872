'use strict';

class Observable {
    constructor() {
        this.subscribers = {};
    }

    /**
     *
     * @param {string} event
     * @param {Function} handler
     * @return {Observable}
     */
    subscribe(event, handler) {
        if (this.subscribers[event]) {
            this.subscribers[event].push(handler);
        } else {
            this.subscribers[event] = [handler];
        }

        return this;
    }

    /**
     *
     * @param {string} event
     * @param {Function} handler
     * @return {Observable}
     */
    unsubscribe(event, handler) {
        const index = this.subscribers[event].findIndex((h) => h === handler);
        if (index > -1) {
            this.subscribers[event].splice(index, 1);
        }

        return this;
    }

    /**
     *
     * @param {string} event
     * @param args
     * @return {Observable}
     */
    fire(event, args) {
        if (this.subscribers[event]) {
            for (const handler of this.subscribers[event]) {
                handler(args);
            }
        }

        return this;
    }
}

module.exports = Observable;
