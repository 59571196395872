import { Controller } from '@hotwired/stimulus';

const {
    Rater,
} = require('../../js/BindHQ/Portal/Program/ContractorsGuard/Rater');

export default class extends Controller {
    static targets = ['premiumContainer'];

    static values = {
        ratingUrl: String,
    };

    connect() {
        this.contractorsGuardRater = new Rater(
            this.element,
            this.premiumContainerTarget,
            this.ratingUrlValue,
        );

        $('.select2', this.element)
            .select2()
            .on('change', () => this.onChange());
    }

    onChange() {
        this.reload();
    }

    onCollectionRemoved(evt) {
        if (this.element.contains(evt.detail.element)) {
            this.reload();
        }
    }

    reload() {
        this.contractorsGuardRater.onChange();
    }
}
