import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['link'];

    connect() {
        this.#createIntersectionObserver();
        this.#checkDocumentAtTop();

        const mutationConfig = { childList: true, subtree: true };
        const mutationObserver = new MutationObserver(
            this.#mutationCallback.bind(this),
        );
        mutationObserver.observe(this.element, mutationConfig);
    }

    onClick(event) {
        const link = event.currentTarget;
        const activateLink = () => {
            this.#activateNavLink(link.getAttribute('href').substring(1));
        };

        setTimeout(activateLink, 200);

        if ('onscrollend' in document) {
            document.addEventListener('scrollend', activateLink, {
                once: true,
            });
        }
    }

    #createIntersectionObserver() {
        const observerOptions = {
            root: null,
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            rootMargin: '-50% 0px',
        };

        const observer = new IntersectionObserver(
            this.#handleIntersection.bind(this),
            observerOptions,
        );

        this.linkTargets.forEach((section) => {
            const contentSection = document.getElementById(
                section.getAttribute('href').substring(1),
            );
            if (contentSection) {
                observer.observe(contentSection);
            }
        });
    }

    #handleIntersection(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.#activateNavLink(entry.target.id);
            }
        });
    }

    #activateNavLink(targetId) {
        this.linkTargets.forEach((link) => {
            const linkHref = link.getAttribute('href').substring(1);
            link.classList.toggle('active', linkHref === targetId);
        });
    }

    #mutationCallback() {
        this.#createIntersectionObserver();
        this.#checkDocumentAtTop();
    }

    #checkDocumentAtTop() {
        if (window.scrollY === 0) {
            const firstLink = this.linkTargets[0];
            if (firstLink) {
                this.#activateNavLink(
                    firstLink.getAttribute('href').substring(1),
                );
            }
        }
    }
}
