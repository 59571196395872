class Animation {
    /**
     * @param {HTMLElement} element
     * @param {number} offset
     */
    scrollTo(element, offset = 0) {
        if (!element) {
            throw new Error('Element does not exist');
        }

        const y =
            element.getBoundingClientRect().top + window.pageYOffset - offset;

        window.scrollTo({
            behavior: 'smooth',
            top: y,
        });
    }
}

module.exports = { Animation };
