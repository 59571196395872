import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
    static targets = [
        'protectionClass',
        'distanceToFireStation',
        'distanceToFireHydrant',
        'fields',
    ];

    static values = {
        protectionClass: Array,
        distanceToFireStation: Number,
        distanceToFireHydrant: Number,
    };

    static debounces = ['onChange'];

    connect() {
        useDebounce(this, { wait: 300 });

        this.#update();
    }

    onChange() {
        this.#update();
    }

    #update() {
        const protectionClass = this.protectionClassTarget.value;
        const protectionClassMatches =
            this.protectionClassValue.includes(protectionClass);

        const distanceToFireStation = this.distanceToFireStationTarget.value;
        const distanceToFireStationMatches =
            distanceToFireStation &&
            distanceToFireStation > this.distanceToFireStationValue;

        const distanceToFireHydrant = this.distanceToFireHydrantTarget.value;
        const distanceToFireHydrantMatches =
            distanceToFireHydrant &&
            distanceToFireHydrant.replace(',', '') >
                this.distanceToFireHydrantValue;

        if (
            protectionClassMatches ||
            distanceToFireStationMatches ||
            distanceToFireHydrantMatches
        ) {
            this.fieldsTarget.classList.remove('d-none');
            this.#setRequired(true);
        } else {
            this.fieldsTarget.classList.add('d-none');
            this.#setRequired(false);
        }
    }

    /**
     * @param {Boolean} isRequired
     */
    #setRequired(isRequired) {
        this.fieldsTarget.querySelectorAll('input').forEach((element) => {
            element.required = isRequired;
        });
    }
}
