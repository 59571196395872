import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['type', 'individual', 'nonIndividual'];

    static values = {
        individualType: String,
    };

    connect() {
        this.#update();
    }

    onTypeChange() {
        this.#update();
    }

    #update() {
        const value = this.typeTarget.tomselect
            ? this.typeTarget.tomselect.getValue()
            : this.typeTarget.value;
        const showIndividual = value === this.individualTypeValue;

        this.individualTargets.forEach((target) => {
            target.classList.toggle('d-none', !showIndividual);
        });

        this.nonIndividualTargets.forEach((element) => {
            element.classList.toggle('d-none', showIndividual);
        });
    }
}
