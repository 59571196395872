import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    connect() {
        this.element.classList.add('product--commercial-property--territory');
        this.control = new TomSelect(this.element, {});
        this.#update();
    }

    onChange() {
        this.#update();
    }

    #update() {
        const locationUuid = this.element
            .closest('.property-location')
            .querySelector('.location').value;
        const location = window.locationBag.find(locationUuid);

        this.element.querySelectorAll('option').forEach((option) => {
            this.control.updateOption(option.value, {
                value: option.value,
                text: option.innerHTML,
                disabled: option.dataset.state !== location.address.state,
            });
        });
    }
}
