const { Collapse } = require('../Utils/Collapse');

const { LocationBag } = require('../Utils/LocationBag');

const { LocationAdder } = require('../Utils/LocationAdder');

const { Address } = require('../Models/Address');

const { Location } = require('../Models/Location');

const {
    PropertyLocationBag,
    PropertyLocationBagEvents,
} = require('./CommercialProperty/PropertyLocationBag');

const {
    NavigationListener,
} = require('./CommercialProperty/NavigationListener');

class CommercialProperty {
    /**
     * @param {HTMLElement} element
     */
    constructor(element) {
        const container = $(element);

        const locationBag = new LocationBag(
            container,
            'product_commercial_property',
        );
        locationBag.init();

        const propertyLocationBag = new PropertyLocationBag(
            container,
            locationBag,
        );
        propertyLocationBag.init();

        if (1 === container.find('.add-location-container').length) {
            new LocationAdder(container, {
                /**
                 * @param {Location} location
                 */
                onAdd: function (location) {
                    locationBag.add(location);
                    propertyLocationBag.add(location);
                },

                /**
                 * @return {Array}
                 */
                getLocations: () => {
                    return this.extractAdditionalLocations(
                        container,
                        locationBag,
                    )
                        .concat(locationBag.getAll())
                        .filter(function (location) {
                            return !propertyLocationBag.contains(location);
                        });
                },

                /**
                 * @return {Integer}
                 */
                getNextLocationNumber: function () {
                    return locationBag.getNextNumber();
                },
            });
        }

        /* auto init */
        new Collapse($('.property-locations', container));

        const navigationListener = new NavigationListener(
            element.querySelector('.cp-menu'),
            propertyLocationBag,
        );
        navigationListener.init();
    }

    extractAdditionalLocations(container, locationBag) {
        const locations = container.data('additional-locations') || [];

        return _.map(locations, function (location) {
            return new Location(
                location.id,
                locationBag.getNextNumber(),
                new Address(
                    location.address.line1,
                    location.address.line2,
                    location.address.city,
                    location.address.state,
                    location.address.international_state,
                    location.address.postal_code,
                    location.address.county,
                    location.address.county_fips,
                ),
                null,
            );
        });
    }
}

module.exports = { CommercialProperty };
