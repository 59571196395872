import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['bodyContent'];

    onClick(event) {
        event.preventDefault();

        const bodyContent = this.bodyContentTarget.innerHTML;

        const clickEvent = new CustomEvent('confirm--button:clicked', {
            detail: {
                title: 'Quote Eligibility Details',
                body: bodyContent,
                cancel: 'Close',
            },
        });

        window.dispatchEvent(clickEvent);
    }
}
