import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['dateField'];

    updateField(event) {
        const target = event.target;

        if (!target.checked) {
            return;
        }

        this.dateFieldTargets.forEach((element) => {
            const $element = $(element);
            const newIndicated = `${target.dataset.indicatedType}${element.dataset.indicatedBase}`;

            $element.data('indicated', newIndicated);
            element.setAttribute('data-indicated', newIndicated);
        });
    }
}
