import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [];

    static values = {
        selected: String,
        selector: String,
        defaultSelector: String,
        default: Boolean,
    };

    connect() {
        this.#update(this.hasDefaultValue ? this.defaultValue : false);
    }

    /**
     * @param {Object} evt
     */
    onChange(evt) {
        const isSelected = this.hasSelectedValue
            ? this.selectedValue === evt.currentTarget.value
            : evt.currentTarget.checked;

        this.#update(isSelected);
    }

    #update(isVisible) {
        this.element.querySelectorAll(this.selectorValue).forEach((element) => {
            if (isVisible) {
                element.classList.remove('d-none');
            } else {
                element.classList.add('d-none');
            }

            this.#disable(element, !isVisible);
        });

        if (this.defaultSelectorValue) {
            this.element
                .querySelectorAll(this.defaultSelectorValue)
                .forEach((element) => {
                    if (isVisible) {
                        element.classList.add('d-none');
                    } else {
                        element.classList.remove('d-none');
                    }

                    this.#disable(element, isVisible);
                });
        }
    }

    #disable(element, isDisabled) {
        element
            .querySelectorAll('[required="required"]')
            .forEach((requiredElement) => {
                if (
                    requiredElement.disabled &&
                    !requiredElement.hasAttribute('wasDisableToggled')
                ) {
                    return;
                }

                if (this.#isChild(requiredElement)) {
                    if (requiredElement.tomselect) {
                        if (isDisabled) {
                            requiredElement.tomselect.disable();
                        } else {
                            requiredElement.tomselect.enable();
                        }
                    } else {
                        requiredElement.setAttribute('wasDisableToggled', true);
                        requiredElement.disabled = isDisabled;
                    }
                }
            });
    }

    /**
     * An element is a child if the nearest containing controller element
     * is the same as this controllers element (ie. it's not an embedded
     * depdendent fields controller).
     */
    #isChild(element) {
        return (
            element.closest('[data-controller="portal--dependent-fields"]') ===
            this.element
        );
    }
}
