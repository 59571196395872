import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const pattern = this.element.getAttribute('pattern');

        if (!pattern) {
            throw new Error('Pattern is not defined on: ', this.element);
            return;
        }

        this.element.addEventListener('keydown', (event) => {
            // Ignore special keys
            if (1 !== event.key.length) {
                return;
            }

            // insert new character at current cursor position
            const cursorPosition = this.element.selectionStart;
            const value =
                this.element.value.slice(0, cursorPosition) +
                event.key +
                this.element.value.slice(cursorPosition);

            if ('' !== value && !value.match(pattern)) {
                event.preventDefault();

                return;
            }
        });
    }
}
