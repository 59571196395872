import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['corporate', 'individual', 'type'];

    static values = {
        individual: String,
    };

    connect() {
        this.#changed();
    }

    onTypeChange() {
        this.#changed();
    }

    #changed() {
        if (this.typeTarget.value === this.individualValue) {
            this.corporateTarget.classList.add('d-none');
            this.individualTarget.classList.remove('d-none');

            this.#setRequired(this.corporateTarget, false);
            this.#setRequired(this.individualTarget, true);
        } else {
            this.corporateTarget.classList.remove('d-none');
            this.individualTarget.classList.add('d-none');

            this.#setRequired(this.corporateTarget, true);
            this.#setRequired(this.individualTarget, false);
        }
    }

    #setRequired(parent, isRequired) {
        parent.querySelectorAll('input').forEach((input) => {
            input.required = isRequired;
        });
    }
}
