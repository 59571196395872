import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['template'];

    static values = {
        body: String,
        title: String,
        confirm: String,
        confirmType: String,
        cancel: String,
    };

    onClick(event) {
        event.preventDefault();

        const bodyContent = this.hasTemplateTarget
            ? this.templateTarget.innerHTML
            : this.bodyValue;

        this.dispatch('clicked', {
            detail: {
                title: this.titleValue,
                body: bodyContent,
                confirm: this.confirmValue,
                confirmType: this.confirmTypeValue,
                cancel: this.cancelValue,
            },
        });
    }
}
