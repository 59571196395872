import { Controller } from '@hotwired/stimulus';
const Clipboard = require('clipboard');

export default class extends Controller {
    static targets = [
        'generatedKey',
        'generatedKeyName',
        'copyKeyButton',
        'generateTokenForm',
        'generateTokenButton',
        'generatedKeyWrapper',
        'regenerateButtonWrapper',
    ];

    static values = {
        copyButtonText: String,
        errorMessage: String,
        duplicateName: String,
        modalDetails: Object,
    };

    apiKeys = [];

    clipboard = null;

    showGeneratedKey(key, name) {
        this.generatedKeyTarget.textContent = key;
        this.generatedKeyNameTarget.textContent = name;
        this.generatedKeyWrapperTarget.classList.remove('d-none');
        this.copyKeyButtonTarget.classList.remove('d-none');
        this.generateTokenButtonTarget.classList.add('d-none');
        this.regenerateButtonWrapperTarget.classList.add('d-none');

        this.initClipboard();
    }

    hideGeneratedKey() {
        this.generatedKeyWrapperTarget.classList.add('d-none');
    }

    onRegenerateApiKey(event) {
        event.stopPropagation();
        event.preventDefault();

        const modal = this.modalDetailsValue;

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: modal.title,
                    body: modal.message,
                    cancel: modal.cancel,
                    confirm: modal.confirm,
                    confirmType: modal.confirmType,
                    onConfirm: () => {
                        this.submitGenerateForm(
                            this.generateTokenFormTarget,
                        ).then(() => {
                            window.dispatchEvent(
                                new CustomEvent('confirm--dialog:closeDialog'),
                            );
                        });
                    },
                    lockOnConfirm: true,
                },
            }),
        );
    }

    onSubmitGenerateForm(event) {
        event.stopPropagation();
        event.preventDefault();

        const form = event.currentTarget;
        this.submitGenerateForm(form);
    }

    submitGenerateForm(form) {
        return fetch(form.action, {
            method: form.method,
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        })
            .then((response) => response.json())
            .then(({ apiKey }) => {
                this.showGeneratedKey(apiKey.token, apiKey.name);
            })
            .catch((error) => {
                this.application.logger.error(error);
            });
    }

    initClipboard() {
        if (null !== this.clipboard) {
            return;
        }

        this.clipboard = new Clipboard(this.copyKeyButtonTarget, {
            text: () => this.generatedKeyTarget.textContent,
        });

        const setButtonText = (btn, message) => {
            if (!btn.dataset.originalMessage) {
                btn.dataset.originalMessage = btn.innerHTML;
            }

            btn.innerHTML = message;

            setTimeout(() => {
                btn.innerHTML = btn.dataset.originalMessage;
            }, 1000);
        };

        this.clipboard.on('success', (e) => {
            setButtonText(e.trigger, '<i class="fas fa-check"></i> Copied!');
        });

        this.clipboard.on('error', (e) => {
            setButtonText(e.trigger, '<i class="fas fa-times"></i> Failed!');
        });
    }

    disconnect() {
        this.keyNameTarget.removeEventListener('keypress', (e) =>
            this.handleReturnKey(e),
        );
        this.clipboard.destroy();
    }
}
