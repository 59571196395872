import { Controller } from '@hotwired/stimulus';

const {
    CommercialProperty,
} = require('../../js/BindHQ/Product/CommercialProperty');

export default class extends Controller {
    connect() {
        new CommercialProperty(this.element);
    }
}
