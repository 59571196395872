class InsuredSelector {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        this.container = container;

        this.insuredPath = this.container.data('insured-path');
        this.insuredInfoPath = this.container.data('insured-info-path');

        this.$applicationNewInsured = null;
        this.$insured = null;
        this.$insuredDataView = null;
        this.$btnCreateNewInsured = null;
        this.$btnCancelInsured = null;
        this.$insuredId = null;
        this.$insuredName = null;
        this.$insuredFirstName = null;
        this.$insuredLastName = null;
        this.$insuredNameValidation = null;
        this.$insuredType = null;
        this.$insuredDba = null;
        this.$insuredAddressLine1 = null;
        this.$insuredAddressLine2 = null;
        this.$insuredAddressLine3 = null;
        this.$insuredCity = null;
        this.$insuredCounty = null;
        this.$insuredCountry = null;
        this.$insuredStateSelect = null;
        this.$insuredStateInput = null;
        this.$insuredZipCode = null;
        this.$insuredAddressLatitude = null;
        this.$insuredAddressLongitude = null;
        this.$insuredEmail = null;
        this.$insuredPhone = null;
        this.$usAddressOption = null;
        this.$internationalAddressOption = null;
        this.$addressLookupContainer = null;
        this.$addressLookupSelect = null;
        this.$addressManualFormContainer = null;
        this.$addressManualFormFields = null;
        this.$btnEnterAddressManually = null;
        this.$btnSearchAddressAgain = null;
        this.$countyContainer = null;
        this.$countryContainer = null;
        this.$stateSelectContainer = null;
        this.$stateInputContainer = null;

        this.bindDOM();

        this.$insured.on('change', (e) => {
            if (e.added && e.added.id === 'new') {
                this.addNewInsured(e.added.cleanName);
            } else {
                this.loadInsuredInfo();
            }
        });

        this.loadInsuredInfo();

        this.$addressLookupSelect.on('address-details-found', (evt, address) =>
            this.onAddressDetailsFound(evt, address),
        );

        this.$addressLookupSelect.on('address-details-not-found', () =>
            this.onAddressDetailsNotFound(),
        );

        this.$insuredType.on('change', () => this.handleInsuredTypeChange());

        this.$btnCancelInsured &&
            this.$btnCancelInsured.on('click', () => this.cancelNewInsured());

        this.$btnCreateNewInsured &&
            this.$btnCreateNewInsured.on('click', (evt) =>
                this.createNewInsured(evt),
            );

        this.$btnEnterAddressManually &&
            this.$btnEnterAddressManually.on('click', (evt) =>
                this.activateAddressManualMode(evt),
            );

        this.$btnSearchAddressAgain &&
            this.$btnSearchAddressAgain.on('click', (evt) =>
                this.activateAddressLookupMode(evt),
            );

        this.$usAddressOption.on('click', () => this.onUsAddressSelected());
        this.$internationalAddressOption.on('click', () =>
            this.onInternationalAddressSelected(),
        );

        $('.insured-dropdown').on(
            'DOMSubtreeModified',
            _.debounce(() => this.toggleAddNewClass(), 200),
        );
    }

    /**
     *
     */
    toggleAddNewClass() {
        const $insuredDropdown = $('.insured-dropdown');
        const hasNewOption = $('[data-id=new]', $insuredDropdown).length !== 0;
        const classNames = ['with-new'];

        if (hasNewOption) {
            $insuredDropdown.addClass(classNames.join(' '));
        } else {
            _.each(classNames, (className) =>
                $insuredDropdown.removeClass(className),
            );
        }
    }

    /**
     *
     */
    bindDOM() {
        this.$form = $('#application-quick-new, #application-quick-edit');
        this.$applicationNewInsured = $('.application-new-insured');
        this.$insuredDataView = $('.insured-data-view', this.container);
        this.$btnCancelInsured = $('.btn-cancel-insured');
        this.$btnCreateNewInsured = $('.btn-create-insured');
        this.$insuredId = $('#insured_id');
        this.$insuredName = $('#insured_name');
        this.$insuredFirstName = $('#insured_first_name');
        this.$insuredLastName = $('#insured_last_name');
        this.$insuredNameValidation = $('.insured_name_validation');
        this.$insuredType = $('#insured_type');
        this.$insuredDba = $('#insured_doingBusinessAs');
        this.$insuredAddressLine1 = $('#insured_address_address_line1');
        this.$insuredAddressLine2 = $('#insured_address_address_line2');
        this.$insuredAddressLine3 = $('#insured_address_address_line3');
        this.$insuredCity = $('#insured_city');
        this.$insuredCounty = $('#insured_county');
        this.$insuredCountry = $('#insured_country');
        this.$insuredStateSelect = $('#insured_state');
        this.$insuredStateInput = $('#insured_international_state');
        this.$insuredZipCode = $('#insured_zip');
        this.$insuredEmail = $('#insured_email');
        this.$insuredPhone = $('#insured_phone');
        this.$insuredAddressLatitude = $('#insured_geo_point_latitude');
        this.$insuredAddressLongitude = $('#insured_geo_point_longitude');
        this.$usAddressOption = $('#insured_international_address_0');
        this.$internationalAddressOption = $(
            '#insured_international_address_1',
        );
        this.$insured = $('#application_insured', this.container);
        this.$agency = $('#application_agency', this.container);
        this.$agent = $('#application_agent', this.container);
        this.$producer = $('#application_producer', this.container);
        this.$lob = $('#application_linesOfBusiness', this.container);
        this.$addressLookupContainer = $('#container-insured-address-lookup');
        this.$addressLookupSelect = $('.address-lookup');
        this.$addressManualFormContainer = $(
            '#container-insured-address-manual-form',
        );
        this.$addressManualFormFields =
            this.$addressManualFormContainer.find('input,select');
        this.$btnEnterAddressManually = $('.btn-enter-manually');
        this.$btnSearchAddressAgain = $('.btn-search-address-again');
        this.$countyContainer = $('#county-container');
        this.$countryContainer = $('#country-container');
        this.$stateSelectContainer = $('#state-select-container');
        this.$stateInputContainer = $('#state-input-container');
    }

    /**
     *
     */
    bindNewInsured() {
        this.$btnCreateNewInsured.html('SAVE NEW INSURED');
        this.$btnCreateNewInsured.unbind('click');
        this.$btnCreateNewInsured.on('click', (evt) =>
            this.createNewInsured(evt),
        );
        this.$insuredId.value = '';
    }

    /**
     *
     */
    bindEditInsured(
        insuredId,
        insuredName,
        insuredFirstName,
        insuredLastName,
        insuredType,
        insuredDba,
        insuredAddressLine1,
        insuredAddressLine2,
        insuredCity,
        insuredState,
        internationalState,
        insuredCounty,
        insuredCountry,
        insuredZip,
        insuredInternationalAddress,
        insuredEmail,
        insuredPhone,
    ) {
        (insuredInternationalAddress
            ? this.$internationalAddressOption
            : this.$usAddressOption
        ).click();
        this.$btnCreateNewInsured.text('SAVE INSURED');
        this.$btnCreateNewInsured.unbind('click');
        this.$btnCreateNewInsured.on('click', (evt) =>
            this.createNewInsured(evt),
        );
        this.$insuredId.val(insuredId);
        this.$insuredName.val(insuredName);
        this.$insuredFirstName.val(insuredFirstName);
        this.$insuredLastName.val(insuredLastName);
        this.$insuredType.val(insuredType).trigger('change');
        this.$insuredDba.val(insuredDba);
        this.$insuredAddressLine1.val(insuredAddressLine1);
        this.$insuredAddressLine2.val(insuredAddressLine2);
        this.$insuredCity.val(insuredCity);
        this.$insuredCounty.val(insuredCounty);
        this.$insuredCountry.val(insuredCountry);
        this.$insuredStateSelect.val(insuredState).trigger('change');
        this.$insuredStateInput.val(internationalState);
        this.$insuredZipCode.val(insuredZip);
        this.$insuredEmail.val(insuredEmail);
        this.$insuredPhone.val(insuredPhone);
    }

    /**
     * @param {String} initialName
     */
    addNewInsured(initialName) {
        const [firstName, lastName] = initialName.split(' ');

        this.displayInsuredForm();
        this.$btnCancelInsured = $('.btn-cancel-insured');
        this.$btnCreateNewInsured = $('.btn-create-insured');
        this.$insured.select2('data', {
            id: 'new',
            name: 'Creating a new insured...',
        });
        this.$insuredName.val(initialName);
        this.$insuredFirstName.val(firstName);
        this.$insuredLastName.val(lastName);
        this.bindNewInsured();
    }

    /**
     *
     */
    displayInsuredForm() {
        $('#container-insured-form')
            .children()
            .each((i, c) => this.$applicationNewInsured.get(0).appendChild(c));

        this.$insured.select2('disable');
        this.$applicationNewInsured.removeClass('d-none');
        this.$insuredDataView.parent().removeClass('d-flex').addClass('d-none');
    }

    /**
     * @return {Boolean}
     */
    isUsAddress() {
        return this.$usAddressOption.is(':checked');
    }

    /**
     *
     */
    resetInsuredForm() {
        this.$insured.select2('enable');
        this.$applicationNewInsured.addClass('d-none');
        this.$insuredDba.val('');
        this.$insuredAddressLine1.val('');
        this.$insuredAddressLine2.val('');
        this.$insuredAddressLine3.val('');
        this.$insuredCity.val('');
        this.$insuredCounty.val('');
        this.$insuredCountry.val('');
        this.$insuredStateSelect.val('').trigger('change');
        this.$insuredStateInput.val('');
        this.$insuredZipCode.val('');
        this.$insuredEmail.val('');
        this.$insuredPhone.val('');
        this.$insuredAddressLatitude.val('');
        this.$insuredAddressLongitude.val('');
        this.$insuredType.val('CP').select2(); // need a select2 call to reset the ctrl
        this.$addressLookupSelect.val(null).trigger('change.select2');
        this.handleInsuredTypeChange();
        this.$applicationNewInsured
            .children()
            .each((i, c) => $('#container-insured-form').get(0).appendChild(c));

        // default to search mode
        this.activateAddressLookupMode();
    }

    /**
     *
     */
    cancelNewInsured() {
        this.resetInsuredForm();

        $('.insured-data-view')
            .html('')
            .parent()
            .addClass('d-none')
            .removeClass('d-flex');

        if ('new' === this.$insured.val()) {
            this.$insured.val(null).trigger('change');
        } else {
            this.loadInsuredInfo();
        }

        $('.address-fields').show();
    }

    /**
     * @param {String} url
     * @param {jQuery} extraInfoArea
     * @param {Function} cb
     */
    loadExtraInfo(url, extraInfoArea, cb = null) {
        const success = function (data) {
            if (extraInfoArea) {
                extraInfoArea.html('');
                extraInfoArea.append(data);
            }

            if (cb && typeof cb === 'function') {
                cb(data);
            }
        };

        $.get(url, {}, success);
    }

    /**
     * @param {Integer} insuredId
     */
    editInsured(insuredId) {
        if (!insuredId) {
            return;
        }
        const url = '/api/v1/insureds/' + insuredId;

        this.loadExtraInfo(url, null, (insured) => {
            this.displayInsuredForm();
            // default to manual mode. Assuming an insured already has an address, this allows
            // it to be edited. User can switch to search mode
            this.activateAddressManualMode();
            this.bindEditInsured(
                insuredId,
                insured.name,
                insured.first_name,
                insured.last_name,
                insured.type,
                insured.doing_business_as,
                insured.mailing_address.address_line1,
                insured.mailing_address.address_line2,
                insured.mailing_address.city,
                insured.mailing_address.state,
                insured.mailing_address.international_state,
                insured.mailing_address.county,
                insured.mailing_address.country,
                insured.mailing_address.zip,
                insured.mailing_address.international_state ||
                    insured.mailing_address.country,
                insured.email,
                insured.phone,
            );
        });
    }

    /**
     *
     */
    loadInsuredInfo() {
        const extraInfoArea = this.container.find('.insured-data-view');
        const insuredId = this.$insured.select2('val');

        if (insuredId && 'new' !== insuredId) {
            const url = this.insuredInfoPath.replace(/{insuredId}/, insuredId);

            this.loadExtraInfo(url, extraInfoArea, () => {
                this.addInsuredActions(extraInfoArea, insuredId);
            });

            extraInfoArea.parent().removeClass('d-none');
        }
    }

    /**
     * @param {jQuery} context
     * @param {Integer} insuredId
     */
    addInsuredActions(context, insuredId) {
        const actions =
            '<div class="mt-3"><small>' +
            '<a href="#!" class="btn-edit-insured">EDIT INSURED</a>' +
            ' | ' +
            '<a href="' +
            this.insuredPath.replace(/{id}/, insuredId) +
            '" target="_blank">VIEW FULL INSURED DETAILS</a>' +
            '</small></div>';
        $('.insured-extra-info', context).append(actions);
        $('.btn-edit-insured', context).on('click', () => {
            const insuredName =
                document.getElementById('insured-extra-info').dataset
                    .insuredName;
            window.dispatchEvent(
                new CustomEvent('confirm--button:clicked', {
                    detail: {
                        title: 'Do you want to edit Insured&rsquo;s information?',
                        body: `Changes made to <b>${insuredName}</b> will affect all existing applications, quotes, and policies where this insured is used.<br><br>Are you sure you want to edit an existing Insured?`,
                        confirm: 'Edit Insured',
                        confirmType: 'primary',
                        onConfirm: (closeDialog) => {
                            this.editInsured(insuredId);

                            window.dispatchEvent(
                                new CustomEvent('confirm--dialog:closeDialog'),
                            );
                        },
                    },
                }),
            );
        });
    }

    /**
     *
     */
    handleInsuredTypeChange() {
        const type = this.$insuredType.val();

        if (type === this.container.data('insured-type-individual')) {
            $('.insured-name').addClass('d-none');
            $('.individual-name').removeClass('d-none');
        } else {
            $('.insured-name').removeClass('d-none');
            $('.individual-name').addClass('d-none');
        }
    }

    /**
     *
     */
    validateNewInsured() {
        this.resetNewInsuredValidation();

        const validationRules = [
            {
                isInvalid: () => {
                    const [firstName, lastName] = [
                        this.$insuredFirstName.val(),
                        this.$insuredLastName.val(),
                    ];
                    return this.$insuredType.val() ===
                        this.container.data('insured-type-individual')
                        ? !firstName || !lastName
                        : false;
                },
                action: () => {
                    this.$insuredFirstName.addClass('is-invalid');
                    this.$insuredLastName.addClass('is-invalid');
                    this.$insuredNameValidation.removeClass('hidden');
                },
            },
            {
                isInvalid: () =>
                    !this.$addressManualFormContainer.is(':visible')
                        ? !this.$addressLookupSelect.select2('data')
                        : false,
                action: () =>
                    this.$addressLookupSelect
                        .select2('container')
                        .addClass('is-invalid'),
            },
            {
                isInvalid: () => !this.$insuredName.val(),
                action: () => this.$insuredName.addClass('is-invalid'),
            },
            {
                isInvalid: () => !this.$insuredType.val(),
                action: () =>
                    this.$insuredType
                        .select2('container')
                        .addClass('is-invalid'),
            },
            {
                isInvalid: () => !this.$insuredAddressLine1.val(),
                action: () => this.$insuredAddressLine1.addClass('is-invalid'),
            },
            {
                isInvalid: () => !this.$insuredCity.val(),
                action: () => this.$insuredCity.addClass('is-invalid'),
            },
            {
                isInvalid: () =>
                    !this.$insuredStateSelect.val() && this.isUsAddress(),
                action: () =>
                    this.$insuredStateSelect
                        .select2('container')
                        .addClass('is-invalid'),
            },
            {
                isInvalid: () =>
                    !this.$insuredStateInput.val() && !this.isUsAddress(),
                action: () => this.$insuredStateInput.addClass('is-invalid'),
            },
            {
                isInvalid: () => !this.$insuredZipCode.val(),
                action: () => this.$insuredZipCode.addClass('is-invalid'),
            },
        ];

        let error = false;

        $.each(validationRules, function (i, rule) {
            if (rule.isInvalid()) {
                rule.action();
                error = true;
            }
        });

        return error;
    }

    resetNewInsuredValidation() {
        const fields = [
            this.$insuredName,
            this.$insuredFirstName,
            this.$insuredLastName,
            this.$insuredType.select2('container'),
            this.$insuredAddressLine1,
            this.$insuredCity,
            this.$insuredStateSelect.select2('container'),
            this.$insuredStateInput,
            this.$insuredZipCode,
            this.$insuredEmail,
            this.$insuredPhone,
            this.$addressLookupSelect.select2('container'),
        ];

        $.each(fields, (i, f) => f.removeClass('is-invalid'));

        this.$insuredNameValidation.addClass('hidden');
    }

    /**
     * @param {Event} e
     */
    async createNewInsured(e) {
        e.preventDefault();

        if (this.validateNewInsured()) {
            return;
        }

        const data = {
            'insured[name]': this.$insuredName.val(),
            'insured[first_name]': this.$insuredFirstName.val(),
            'insured[last_name]': this.$insuredLastName.val(),
            'insured[type]': this.$insuredType.val(),
            'insured[doingBusinessAs]': this.$insuredDba.val(),
            'insured[international_address]': this.isUsAddress() ? '0' : '1',
            'insured[address][address_line1]': this.$insuredAddressLine1.val(),
            'insured[address][address_line2]': this.$insuredAddressLine2.val(),
            'insured[address][address_line3]': this.$insuredAddressLine3.val(),
            'insured[city]': this.$insuredCity.val(),
            'insured[county]': this.$insuredCounty.val(),
            'insured[country]': this.$insuredCountry.val(),
            'insured[state]': this.$insuredStateSelect.val(),
            'insured[international_state]': this.$insuredStateInput.val(),
            'insured[zip]': this.$insuredZipCode.val(),
            'insured[geo_point][latitude]': this.$insuredAddressLatitude.val(),
            'insured[geo_point][longitude]':
                this.$insuredAddressLongitude.val(),
            'insured[email]': this.$insuredEmail.val(),
            'insured[phone]': this.$insuredPhone.val(),
            'insured[_token]': $('#insured__token').val(),
            clearing: false,
        };

        if (
            this.$insuredType.val() ===
            this.container.data('insured-type-individual')
        ) {
            data['insured[name]'] =
                data['insured[first_name]'] + ' ' + data['insured[last_name]'];
        } else {
            data['insured[first_name]'] = '';
            data['insured[last_name]'] = '';
        }

        const insuredId = this.$insuredId.val();

        let url = this.$form.data('new-insured-url');

        if (insuredId) {
            url = this.$form
                .data('update-insured-url')
                .replace(/{insuredId}/, insuredId);
        }

        $.ajax({
            type: 'POST',
            url,
            data,
            success: (insured) => {
                if (insuredId) {
                    this.$insured
                        .select2('data', {
                            id: insuredId,
                            name: data['insured[name]'],
                        })
                        .trigger('change');
                } else {
                    this.$insured.select2('data', insured).trigger('change');
                }

                this.resetInsuredForm();
            },
            error: function (xhr) {
                const error = Array.isArray(xhr.responseJSON)
                    ? xhr.responseJSON.join('\r')
                    : xhr.responseText;

                const clickEvent = new CustomEvent('confirm--button:clicked', {
                    detail: {
                        title: 'Validation Error',
                        body: error,
                        cancel: 'Edit Details',
                        hideCancelButton: true,
                    },
                });

                window.dispatchEvent(clickEvent);
            },
        });
    }

    /**
     * @param {Event} e
     * @param {Object} address
     */
    onAddressDetailsFound(e, address) {
        (address.country === 'US'
            ? this.$usAddressOption
            : this.$internationalAddressOption
        ).click();
        this.$insuredAddressLine1.val(address.line1);
        this.$insuredAddressLine2.val(address.line2);
        this.$insuredAddressLine3.val('');
        this.$insuredCity.val(address.city);
        this.$insuredStateSelect.val(address.state).trigger('change');
        this.$insuredStateInput.val('');
        this.$insuredCounty.val(address.county);
        this.$insuredCountry.val(address.country);
        this.$insuredZipCode.val(address.postal_code);
        this.$insuredAddressLatitude.val(address.latitude);
        this.$insuredAddressLongitude.val(address.longitude);
    }

    /**
     *
     */
    onAddressDetailsNotFound() {
        alert('Address details could not be loaded.');
        this.activateAddressManualMode();
    }

    /**
     * @param {Event} e
     */
    activateAddressLookupMode(e) {
        e && e.preventDefault();
        this.resetNewInsuredValidation();
        this.$addressManualFormContainer.addClass('d-none');
        this.$addressLookupContainer.removeClass('d-none');
    }

    /**
     * @param {Event} e
     */
    activateAddressManualMode(e) {
        e && e.preventDefault();
        this.resetNewInsuredValidation();
        this.$addressLookupSelect.val(null).trigger('change.select2');
        this.$addressManualFormContainer.removeClass('d-none');
        this.$addressLookupContainer.addClass('d-none');
        this.$insuredAddressLatitude.val('');
        this.$insuredAddressLongitude.val('');
    }

    /**
     *
     */
    onUsAddressSelected() {
        this.$stateSelectContainer.removeClass('d-none');
        this.$stateInputContainer.addClass('d-none');
        this.$countryContainer.addClass('d-none');
        this.$countyContainer.removeClass('d-none');

        this.$addressManualFormFields.val('');
    }

    /**
     *
     */
    onInternationalAddressSelected() {
        this.$stateSelectContainer.addClass('d-none');
        this.$stateInputContainer.removeClass('d-none');
        this.$countryContainer.removeClass('d-none');
        this.$countyContainer.addClass('d-none');

        this.$addressManualFormFields.val('');
    }
}

module.exports = { InsuredSelector };
