/**
 * @param {String} str
 * @param {Integer} length
 * @param {String} append
 */
function truncate(str, length = 100, append = '') {
    let truncated = str.trim().substring(0, length).trim();
    if (str.trim().length > length && append) {
        truncated += append;
    }
    return truncated;
}

module.exports = { truncate };
