import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        titleOnCheckOn: {
            type: String,
            default: 'Confirm',
        },
        titleOnCheckOff: {
            type: String,
            default: 'Confirm',
        },
        messageOnCheckOn: String,
        messageOnCheckOff: String,
        cancel: String,
        confirmOnCheckOn: {
            type: String,
            default: 'Are you sure?',
        },
        confirmOnCheckOff: {
            type: String,
            default: 'Are you sure?',
        },
        confirmType: {
            type: String,
            default: 'danger',
        },
        confirmState: {
            type: String,
            default: 'both',
            validate(value) {
                return ['on', 'off', 'both'].includes(value);
            },
        },
    };

    connect() {
        if (
            !(
                'INPUT' === this.element.tagName &&
                'checkbox' === this.element.type
            )
        ) {
            throw new Error(
                'The confirm controller can only be used on a checkbox element.',
            );
        }

        this.element.addEventListener('click', this.onClick.bind(this));
    }

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();

        const target = event.currentTarget;
        const currentlyChecked = !target.checked;

        if ('off' === this.confirmStateValue && !currentlyChecked) {
            setTimeout(() => {
                target.checked = true;
            }, 1);
            return;
        }

        if ('on' === this.confirmStateValue && currentlyChecked) {
            setTimeout(() => {
                target.checked = false;
            }, 1);
            return;
        }

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: this.#getTitle(currentlyChecked),
                    body: this.#getMessage(currentlyChecked),
                    cancel: this.cancelValue,
                    confirm: this.#getConfirm(currentlyChecked),
                    confirmType: this.confirmTypeValue,
                    onConfirm: () => {
                        target.checked = !currentlyChecked;
                    },
                    closeOnConfirm: true,
                },
            }),
        );
    }

    #getTitle(currentlyChecked) {
        if ('both' === this.confirmStateValue) {
            return currentlyChecked
                ? this.titleOnCheckOffValue
                : this.titleOnCheckOnValue;
        }

        if ('on' === this.confirmStateValue) {
            return this.titleOnCheckOnValue;
        }

        if ('off' === this.confirmStateValue) {
            return this.titleOnCheckOffValue;
        }
    }

    #getMessage(checked) {
        if ('both' === this.confirmStateValue) {
            return checked
                ? this.messageOnCheckOffValue
                : this.messageOnCheckOnValue;
        }

        if ('on' === this.confirmStateValue) {
            return this.messageOnCheckOnValue;
        }

        if ('off' === this.confirmStateValue) {
            return this.messageOnCheckOffValue;
        }
    }

    #getConfirm(checked) {
        if ('both' === this.confirmStateValue) {
            return checked
                ? this.confirmOnCheckOffValue
                : this.confirmOnCheckOnValue;
        }

        if ('on' === this.confirmStateValue) {
            return this.confirmOnCheckOnValue;
        }

        if ('off' === this.confirmStateValue) {
            return this.confirmOnCheckOffValue;
        }
    }
}
