import { Controller } from '@hotwired/stimulus';

const initLazyStimulus = require('../../assets/js/BindHQ/Form/LazyStimulus');

export default class extends Controller {
    static targets = ['content', 'preview', 'toggle'];

    static values = {
        collapsedByDefault: { type: Boolean, default: false },
    };

    connect() {
        this.isCollapsed = false;
        this.plusIcon = '<i class="fa fa-plus icon-white"></i>';
        this.minusIcon = '<i class="fa fa-minus icon-white"></i>';

        this.previewTarget.classList.add('d-none');

        if (this.collapsedByDefaultValue) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    onToggle(event) {
        event.preventDefault();

        this.#toggle();
    }

    expand() {
        initLazyStimulus(this.element);

        if (!this.isCollapsed) {
            return;
        }

        this.toggleTarget.innerHTML = this.minusIcon;
        this.contentTarget.classList.remove('d-none');
        this.previewTarget.classList.add('d-none');
        this.isCollapsed = false;
    }

    collapse() {
        if (this.isCollapsed) {
            return;
        }

        this.toggleTarget.innerHTML = this.plusIcon;
        this.contentTarget.classList.add('d-none');
        this.previewTarget.classList.remove('d-none');
        this.isCollapsed = true;
    }

    #toggle() {
        if (this.isCollapsed) {
            this.expand();
        } else {
            this.collapse();
        }
    }
}
