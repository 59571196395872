import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['collapsedTitle'];

    onCodeChanged(event) {
        const select = event.currentTarget;

        this.collapsedTitleTarget.innerHTML = select.selectedIndex
            ? select.options[select.selectedIndex].innerHTML
            : '~';
    }
}
