import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
    static targets = ['attachment', 'search'];

    static debounces = ['searchAttachments'];

    connect() {
        useDebounce(this, { wait: 300 });
    }

    searchAttachments() {
        const criteria = {
            name: this.searchTarget.value,
            description: this.searchTarget.value,
        };

        this.attachmentTargets.forEach((e) => {
            const searchable = {
                name: e.dataset.fileName,
                description: e.dataset.fileDescription || '',
            };
            if (!this.#search(searchable, criteria)) {
                e.classList.add('hidden');
            } else {
                e.classList.remove('hidden');
            }
        });
    }

    #search(searchable, criteria) {
        const matchingName = searchable.name
            .toLowerCase()
            .includes(criteria.name.toLowerCase());
        const matchingDescription = searchable.description
            .toLowerCase()
            .includes(criteria.description.toLowerCase());

        return matchingName || matchingDescription;
    }
}
