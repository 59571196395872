import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['control'];

    static values = {
        comparisonIsEqual: String,
        comparisonNotEqual: String,
        outcomeOptional: String,
        outcomeRequired: String,
        outcomeExcluded: String,
    };

    initialize() {
        this.eventMap = new Map();
    }

    /**
     * @param {HTMLElement} control
     */
    controlTargetConnected(control) {
        this.#indexControl(control);
    }

    /**
     * @param {HTMLElement} control
     */
    controlTargetDisconnected(control) {
        this.eventMap.delete(control.dataset.identifier);

        for (const identifier in this.eventMap) {
            this.eventMap.set(
                identifier,
                this.eventMap.get(identifier).filter((dependency) => {
                    return dependency.control !== control;
                }),
            );
        }
    }

    /**
     * @param {Event} event
     */
    onChange(event) {
        const control = event.target;

        if (!this.eventMap.has(control.dataset.identifier)) {
            return;
        }

        this.eventMap.get(control.dataset.identifier).forEach((dependency) => {
            this.#applyDecision(dependency);
        });
    }

    /**
     * @param {Object} dependency
     */
    #applyDecision(dependency) {
        const control = dependency.control;
        const outcome = this.#evaluateDecision(dependency);

        if (outcome === this.outcomeExcludedValue) {
            control.closest('.product-field-control').classList.add('d-none');
        } else if (outcome === this.outcomeOptionalValue) {
            control.required = false;

            const formGroup = control.closest('.product-field-control');
            formGroup.querySelector('label').classList.remove('required');
            formGroup.classList.remove('d-none');
        } else if (outcome === this.outcomeRequiredValue) {
            control.required = true;

            const formGroup = control.closest('.product-field-control');
            formGroup.querySelector('label').classList.add('required');
            formGroup.classList.remove('d-none');
        } else {
            throw new Error('Unsupported outcome: ' + outcome);
        }
    }

    /**
     * @param {Object} dependency
     */
    #evaluateDecision(dependency) {
        const decision = dependency.decision;

        for (let i = 0; i < decision.or_conditions.length; i++) {
            const orCondition = decision.or_conditions[i];

            if (
                orCondition.and_conditions.some((andCondition) => {
                    return andCondition.conditions.every((condition) => {
                        const control = this.#findControl(
                            dependency.control.closest(
                                '.product-field-control',
                            ),
                            condition.identifier,
                        );
                        const controlValue = this.#controlToValue(control);
                        const conditionValue = this.#conditionToValue(
                            control,
                            condition,
                        );

                        if (
                            condition.comparison === this.comparisonIsEqualValue
                        ) {
                            return controlValue === conditionValue;
                        }

                        if (
                            condition.comparison ===
                            this.comparisonNotEqualValue
                        ) {
                            return controlValue !== conditionValue;
                        }

                        throw new Error(
                            'Unsupported comparison: ' + condition.comparison,
                        );
                    });
                })
            ) {
                return orCondition.outcome;
            }
        }

        return decision.default_outcome;
    }

    #findControl(container, identifier) {
        if (null === container) {
            throw new Error('Control not found: ' + identifier);
        }

        const control = this.controlTargets.find((control) => {
            return (
                control.dataset.identifier === identifier &&
                container.contains(control)
            );
        });

        if (undefined !== control) {
            return control;
        }

        return this.#findControl(
            container.parentElement.closest('.product-field-control'),
            identifier,
        );
    }

    /**
     * @param {HTMLElement} control
     */
    #indexControl(control) {
        if (control.dataset.decision) {
            const decision = JSON.parse(control.dataset.decision);

            decision.or_conditions.forEach((orCondition) => {
                orCondition.and_conditions.forEach((andCondition) => {
                    andCondition.conditions.forEach((condition) => {
                        const identifier = condition.identifier;

                        if (!this.eventMap.has(identifier)) {
                            this.eventMap.set(identifier, []);
                        }

                        this.eventMap
                            .get(identifier)
                            .push({ control, decision });
                    });
                });
            });

            this.#applyDecision({ control, decision });
        }
    }

    /**
     * @param {HTMLElement} control
     * @param {Object} condition
     */
    #conditionToValue(control, condition) {
        if (control.type === 'checkbox') {
            if ('true' === condition.value) {
                return true;
            }

            if ('false' === condition.value) {
                return false;
            }

            throw new Error(
                'Unsupported value for checkbox condition: ' + condition.value,
            );
        }

        throw new Error('Unsupported condition control: ' + control.type);
    }

    /**
     * @param {HTMLElement} control
     */
    #controlToValue(control) {
        if (control.type === 'checkbox') {
            return control.checked;
        }

        return control.value;
    }
}
