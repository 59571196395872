import { Controller } from '@hotwired/stimulus';

const {
    LinesOfBusinessSelector,
} = require('../../js/BindHQ/Form/LinesOfBusinessSelector');

export default class extends Controller {
    static values = {
        linesOfBusiness: Object,
    };

    connect() {
        this.selector = new LinesOfBusinessSelector(
            this.element,
            this.linesOfBusinessValue['lines-of-business'],
        );
    }

    onChange() {
        this.selector.onChange();
    }
}
