import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['division'];

    setDivision(event) {
        const { id } = event.params;
        this.divisionTarget.value = document.querySelector(id).value;
    }
}
