import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['trigger', 'toggleable'];

    connect() {
        const radios = this.element.querySelectorAll('input[type=radio]');

        if (this.triggerTarget.checked) {
            $(this.toggleableTarget).show();
        } else {
            $(this.toggleableTarget).hide();
        }

        radios.forEach((e) => {
            e.addEventListener('change', () => {
                this.#checkRadios();
            });
        });
    }

    #checkRadios() {
        if (this.triggerTarget.checked) {
            $(this.toggleableTarget).slideDown();
        } else {
            $(this.toggleableTarget).slideUp();
        }
    }
}
