import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['collapsedTitle'];

    connect() {
        $('.classification-class-code', this.element).on('change', (event) => {
            const data = $(event.currentTarget).select2('data');

            this.collapsedTitleTarget.innerHTML =
                data.name + ' - ' + data.iso_code;
        });
    }
}
