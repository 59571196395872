import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const numeral = require('numeral');

export default class extends Controller {
    static targets = [
        'breakdown',
        'breakdownFields',
        'grandTotal',
        'indemnityLossPaid',
        'indemnityLossReserve',
        'paidBreakdown',
        'paidTotal',
        'reserveBreakdown',
        'reserveTotal',
    ];

    static debounces = ['onInputChange'];

    connect() {
        useDebounce(this, { wait: 500 });

        this.#update();
    }

    onBreakdownChange(event) {
        this.#update();
    }

    onInputChange(event) {
        this.#updateTotals();
    }

    #update() {
        if (this.breakdownTarget.checked) {
            this.breakdownFieldsTarget.classList.remove('d-none');
            this.paidTotalTarget.disabled = true;
            this.reserveTotalTarget.disabled = true;
        } else {
            this.breakdownFieldsTarget.classList.add('d-none');
            this.paidTotalTarget.disabled = false;
            this.reserveTotalTarget.disabled = false;
        }

        this.#updateTotals();
    }

    #updateTotals() {
        let paidTotal = numeral(this.paidTotalTarget.value);
        let reserveTotal = numeral(this.reserveTotalTarget.value);

        if (this.breakdownTarget.checked) {
            paidTotal = numeral(0);
            reserveTotal = numeral(0);

            this.paidBreakdownTargets.forEach((target) => {
                paidTotal.add(target.value);
            });

            this.reserveBreakdownTargets.forEach((target) => {
                reserveTotal.add(target.value);
            });

            this.paidTotalTarget.value = paidTotal.format('0,0.00');
            this.reserveTotalTarget.value = reserveTotal.format('0,0.00');
        }

        this.grandTotalTarget.value = paidTotal
            .add(reserveTotal)
            .add(numeral(this.indemnityLossReserveTarget.value))
            .add(numeral(this.indemnityLossPaidTarget.value))
            .format('0,0.00');
    }
}
