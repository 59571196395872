import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const numeral = require('numeral');
const progress = require('../../../js/BindHQ/Utils/Progress');

export default class extends Controller {
    static debounces = ['rate'];

    static targets = ['address', 'map', 'premium', 'rate'];

    static values = {
        ratingUrl: String,
    };

    connect() {
        useDebounce(this, { wait: 500 });

        this.rate();
        this.#renderMap();
    }

    rate() {
        if (!this.ratingUrlValue) {
            return;
        }

        const data = new URLSearchParams(
            new FormData(this.element.closest('form')),
        );
        const config = { method: 'post', body: data };

        progress.start();

        fetch(this.ratingUrlValue, config)
            .then((response) => response.json())
            .then((response) => {
                const rating = response.custom_rating;
                const coverage = rating.coverages[0];

                this.rateTargets.forEach(
                    (element) => (element.innerHTML = coverage.rate),
                );
                this.premiumTargets.forEach((element) => {
                    if ('input' === element.tagName.toLowerCase()) {
                        element.value = coverage.premium.amount;
                    } else {
                        const amount = numeral(coverage.premium.amount);

                        element.innerHTML = '$' + amount.format('0,0.00');
                    }
                });
            })
            .finally(() => progress.stop());
    }

    #renderMap() {
        const map = new google.maps.Map(this.mapTarget, {
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            mapId: 'BINDHQ_MAP',
            zoom: 14,
        });

        new google.maps.Geocoder().geocode(
            { address: this.addressTarget.innerText },
            (results, status) => {
                if (status === 'OK') {
                    const latLng = results[0].geometry.location;

                    map.setCenter(latLng);

                    bhqGoogleMaps(() => {
                        new google.maps.marker.AdvancedMarkerElement({
                            map,
                            position: latLng,
                        });
                    });
                }
            },
        );
    }
}
