import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['name', 'template'];

    onTemplateChange() {
        this.nameTarget.value =
            this.templateTarget.options[
                this.templateTarget.selectedIndex
            ].innerHTML;
    }
}
