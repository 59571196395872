import ScrollTo from 'stimulus-scroll-to';

export default class extends ScrollTo {
    get defaultOptions() {
        return {
            offset: 100,
            behavior: 'smooth',
        };
    }
}
