import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'amountColumn',
        'hurricaneDeductibleColumn',
        'hurricaneDeductibleTypeColumn',
        'included',
        'percentageColumn',
        'value',
        'valueColumn',
    ];

    static values = {
        windHailCoverage: String,
        hurricaneCoverage: String,
        other: String,
    };

    connect() {
        this.#changed();
    }

    onChange() {
        this.#changed();
    }

    #changed() {
        if (this.windHailCoverageValue === this.includedTarget.value) {
            this.valueColumnTarget.classList.remove('d-none');
            this.amountColumnTarget.classList.add('d-none');
            this.percentageColumnTarget.classList.add('d-none');

            if (this.otherValue === this.valueTarget.value) {
                this.amountColumnTarget.classList.remove('d-none');
                this.percentageColumnTarget.classList.remove('d-none');
            }

            if (this.hasHurricaneDeductibleColumnTarget) {
                this.hurricaneDeductibleColumnTarget.classList.add('d-none');
                this.hurricaneDeductibleTypeColumnTarget.classList.add(
                    'd-none',
                );
            }
        } else if (this.hurricaneCoverageValue === this.includedTarget.value) {
            this.hurricaneDeductibleColumnTarget.classList.remove('d-none');
            this.hurricaneDeductibleTypeColumnTarget.classList.remove('d-none');

            this.valueColumnTarget.classList.add('d-none');
            this.amountColumnTarget.classList.add('d-none');
            this.percentageColumnTarget.classList.add('d-none');
            this.amountColumnTarget.classList.add('d-none');
            this.percentageColumnTarget.classList.add('d-none');
        } else {
            this.valueColumnTarget.classList.add('d-none');
            this.amountColumnTarget.classList.add('d-none');
            this.percentageColumnTarget.classList.add('d-none');

            if (this.hasHurricaneDeductibleColumnTarget) {
                this.hurricaneDeductibleColumnTarget.classList.add('d-none');
                this.hurricaneDeductibleTypeColumnTarget.classList.add(
                    'd-none',
                );
            }
        }
    }
}
