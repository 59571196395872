class ProductsCompletedOperations {
    /**
     * @param {jQuery} coverageOptionCodeSelect
     * @param {jQuery} premiumInput
     * @param {jQuery} rateInput
     * @param {String} chargedValue
     */
    constructor(
        coverageOptionCodeSelect,
        premiumInput,
        rateInput,
        chargedValue,
    ) {
        this.coverageOptionCodeSelect = coverageOptionCodeSelect;
        this.premiumInput = premiumInput;
        this.rateInput = rateInput;
        this.chargedValue = chargedValue;
    }

    init() {
        this.coverageOptionCodeSelect.on('change', () => this.onChange());
        this.onChange();
    }

    onChange() {
        if (this.chargedValue === this.coverageOptionCodeSelect.val()) {
            this.premiumInput.removeClass('d-none');
            this.rateInput.removeClass('d-none');
            this.premiumInput.closest('.input-group').removeClass('d-none');
        } else {
            this.rateInput.val(null).addClass('d-none');
            this.premiumInput.val(null).addClass('d-none').trigger('change');
            this.premiumInput.closest('.input-group').addClass('d-none');

            // Fire event for Stimulus interop.
            const premiumElement = this.premiumInput.get(0);
            premiumElement.dispatchEvent(new CustomEvent('input'));
        }
    }
}

module.exports = { ProductsCompletedOperations };
