import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        modalId: String,
        url: String,
    };

    static targets = ['display', 'input', 'tree'];

    connect() {
        this.modal = $('#' + this.modalIdValue);
        this.modal.on('shown.bs.modal', () => this.#init());
    }

    openModal() {
        this.modal.modal('show');
    }

    closeModal() {
        this.modal.modal('hide');
    }

    clear() {
        this.#value(null, null);
        this.closeModal();
    }

    #init() {
        if (this.treeTarget.innerHTML) {
            return;
        }

        this.#load(this.treeTarget, this.urlValue);
    }

    /**
     * @param {null|String} id
     * @param {null|String} name
     */
    #value(id, name) {
        this.inputTarget.value = id;
        this.displayTarget.value = name;
    }

    /**
     * @param {HTMLElement} ul
     * @param {String} url
     */
    #load(ul, url) {
        ul.innerHTML =
            '<div class="p-2"><i class="fas fa-spinner"></i> Loading...</div>';

        fetch(url)
            .then((response) => response.json())
            .then((costCentres) => {
                ul.innerHTML = '';

                costCentres.forEach((costCentre) => {
                    this.#node(ul, costCentre);
                });
            });
    }

    /**
     * @param {HTMLElement} ul
     * @param {Object} costCentre
     */
    #node(ul, costCentre) {
        const li = document.createElement('li');
        li.classList.add('p-2');

        if (costCentre.has_children) {
            this.#child(li, costCentre);
        } else {
            this.#leaf(li);
        }

        const select = document.createElement('a');
        select.innerHTML = costCentre.code + ' ' + costCentre.name;
        select.addEventListener('click', (event) => {
            this.#value(costCentre.id, costCentre.display_name);
            this.closeModal();
        });

        li.appendChild(select);
        ul.appendChild(li);
    }

    /**
     * @param {HTMLElement} li
     * @param {Object} costCentre
     */
    #child(li, costCentre) {
        let children = null;

        const expand = document.createElement('a');
        expand.innerHTML = '<i class="fas fa-angle-right"></i>';
        expand.classList.add('ml-2', 'mr-3');

        const collapse = document.createElement('a');
        collapse.innerHTML = '<i class="fas fa-angle-down"></i>';
        collapse.classList.add('ml-2', 'mr-3', 'd-none');

        expand.addEventListener('click', (event) => {
            expand.classList.add('d-none');
            collapse.classList.remove('d-none');

            if (null === children) {
                children = document.createElement('ul');
                children.classList.add('ml-2', 'mt-2');

                li.appendChild(children);

                this.#load(children, this.urlValue + costCentre.id);
            } else {
                children.classList.remove('d-none');
            }
        });

        collapse.addEventListener('click', (event) => {
            collapse.classList.add('d-none');
            expand.classList.remove('d-none');
            children.classList.add('d-none');
        });

        li.appendChild(expand);
        li.appendChild(collapse);
    }

    /**
     * @param {HTMLElement} li
     */
    #leaf(li) {
        const leaf = document.createElement('span');
        leaf.innerHTML = '<i class="far fa-circle"></i>';
        leaf.classList.add('ml-2', 'mr-3');

        li.append(leaf);
    }
}
