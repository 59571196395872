const assert = require('assert');

const {
    ClassificationLocationMapper,
} = require('../Product/GeneralLiability/ClassificationLocationMapper');

const {
    ClassificationLocationBag,
} = require('../Product/GeneralLiability/ClassificationLocationBag');

const { syncHeader } = require('../Product/GeneralLiability/utils');

const {
    ClassificationMenuGenerator,
} = require('../Product/GeneralLiability/ClassificationMenuGenerator');

const { SummaryListener } = require('../SummaryListener');

const { LocationBag } = require('../Utils/LocationBag');

const { AddressWidget } = require('../Utils/AddressWidget');

const { LocationAdder } = require('../Utils/LocationAdder');

const { Collapse } = require('../Utils/Collapse');

const { Animation } = require('../Utils/Animation');

const { Address } = require('../Models/Address');

const { Location } = require('../Models/Location');

const numeral = require('numeral');

const HEADER_TYPES = [
    'exposure',
    'class-code',
    'premium-basis',
    'if-any',
    'premium',
];

/**
 * @param {ClassificationLocationMapper} classificationLocationMapper
 * @param {jQuery} container
 */
function initHeaders(classificationLocationMapper, container) {
    for (const header of HEADER_TYPES) {
        $(container).on('change', '.classification-' + header, function () {
            syncHeader($(this), header);

            if (header === 'class-code') {
                buildClassificationLocationMenu(classificationLocationMapper);
            }
        });
    }
}

/**
 *
 */
function cleanAddressErrors() {
    const $addressErrors = $('.address-errors');

    $addressErrors.html('');
    $addressErrors.addClass('d-none');
}

/**
 *
 */
function displayErrors(errors) {
    const $addressErrors = $('.address-errors');

    let errorsHtml = '';

    for (const errorKey of Object.keys(errors)) {
        for (const error of errors[errorKey]) {
            errorsHtml += `<li>${error}</li>`;
        }
    }

    $addressErrors.html(`<ul>${errorsHtml}</ul>`);
    $addressErrors.removeClass('d-none');
}

/**
 * @param {ClassificationLocationMapper} classificationLocationMapper
 */
function buildClassificationLocationMenu(classificationLocationMapper) {
    const $glMenu = $('.gl-menu');
    const map = classificationLocationMapper.mapClassificationLocations();
    const menuGenerator = new ClassificationMenuGenerator();

    $glMenu.html('');

    for (const item of map) {
        $glMenu.append(
            menuGenerator.generateListItem(
                item.target,
                item.label,
                item.classifications,
            ),
        );
    }
}

class ContractorsGuard {
    /**
     * @param {String} formName
     * @param {jQuery} container
     * @param {Array} classificationLocations
     */
    constructor(formName, container, classificationLocations) {
        assert.strictEqual(
            1,
            container.length,
            'Expected GL container to have exactly one element, got: ' +
                container.length,
        );

        const summaryListener = new SummaryListener(container);
        summaryListener.init();

        const locationBag = new LocationBag(container, formName);
        locationBag.init();

        const classificationLocationBag = new ClassificationLocationBag(
            container,
            locationBag,
        );
        classificationLocationBag.init();

        const animation = new Animation($(window));
        container.on('click', '.collapse-show', (e) => {
            e.preventDefault();
            const selector = $(e.target).attr('href');
            animation.scrollTo($(selector).parent().get(0), 120);
        });

        const classificationLocationMapper = new ClassificationLocationMapper(
            container,
        );

        const bindAddressLookup = function () {
            const uuid = $('.location', this).val();
            const location = locationBag.find(uuid);
            const addressLookup = new AddressWidget($(this), location);
            addressLookup
                .init({
                    addressLookupContainerSelector: '.address-lookup-container',
                })
                .subscribe('addressDetailsFound', ({ address, geoPoint }) => {
                    location.setAddress(address, geoPoint);
                    cleanAddressErrors();
                    buildClassificationLocationMenu(
                        classificationLocationMapper,
                    );
                })
                .subscribe('addressSaved', (address) => {
                    location.setAddress(
                        new Address(
                            address.address_line1,
                            address.address_line2,
                            address.city,
                            address.state,
                            location.address.internationalState,
                            address.zip,
                            address.county,
                            null,
                        ),
                    );
                    cleanAddressErrors();
                    buildClassificationLocationMenu(
                        classificationLocationMapper,
                    );
                })
                .subscribe('addressErrors', (errors) => {
                    displayErrors(errors);
                });
        };

        $('.classification-location', container).each(bindAddressLookup);

        if (1 === container.find('.add-location-container').length) {
            new LocationAdder(container, {
                /**
                 * @param {Location} location
                 */
                onAdd: function (location) {
                    locationBag.add(location);
                    classificationLocationBag.add(location);
                },

                /**
                 * @return {Array}
                 */
                getLocations: function () {
                    return locationBag.getAll().filter(function (location) {
                        return !classificationLocationBag.contains(location);
                    });
                },

                /**
                 * @return {Integer}
                 */
                getNextLocationNumber: function () {
                    return locationBag.getNextNumber();
                },
            });
        }

        /* auto init */
        new Collapse($('.classification-locations', container));

        initHeaders(classificationLocationMapper, container);
        buildClassificationLocationMenu(classificationLocationMapper);

        const observer = new MutationObserver((e) => {
            buildClassificationLocationMenu(classificationLocationMapper);

            if (e[0].addedNodes.length) {
                bindAddressLookup.call(e[0].addedNodes[0]);
            }
        });

        observer.observe(classificationLocations, { childList: true });

        const onClassificationLocationMutate = () => {
            buildClassificationLocationMenu(classificationLocationMapper);
        };

        const locationObserver = new MutationObserver(
            _.debounce(onClassificationLocationMutate, 500),
        );
        locationObserver.observe($('.classification-locations')[0], {
            childList: true,
            subtree: true,
        });

        this.locationBag = locationBag;
    }

    /**
     * @return {LocationBag}
     */
    getLocationBag() {
        return this.locationBag;
    }
}

module.exports = { ContractorsGuard };
