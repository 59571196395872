import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
    static targets = [
        'attachments',
        'dragArea',
        'uploadButton',
        'document',
        'documentsSearch',
        'documentsLobs',
    ];

    static debounces = ['searchDocuments'];

    connect() {
        useDebounce(this, { wait: 300 });
    }

    searchDocuments() {
        const criteria = {
            lines_of_business: $(this.documentsLobsTarget).val(),
            name: this.documentsSearchTarget.value,
            description: this.documentsSearchTarget.value,
        };

        this.documentTargets.forEach((e) => {
            const searchable = JSON.parse(e.dataset.searchable);

            if (!this.#search(searchable, criteria)) {
                e.parentElement.classList.add('hidden');
            } else {
                e.parentElement.classList.remove('hidden');
            }
        });
    }

    addAttachmentInput() {
        $(this.#createAttachmentInput()).trigger('click');
    }

    onAttachmentChosen(event, id) {
        const input = $(event.currentTarget);
        const filePath = input.val();
        const size = window.FileReader ? input.get(0).files[0].size : 0;
        const sizeString = bindhq.files.bytesToDescription(size);
        const tenMegabytesInBytes = 1024 * 1024 * 10;
        const errorMessage =
            size > tenMegabytesInBytes
                ? 'Maximum attachment size is 10 Mb'
                : null;

        const file = {
            id: id,
            name: filePath.substring(filePath.lastIndexOf('\\') + 1),
            size: sizeString,
            note: 'From Computer',
            error: errorMessage,
        };

        if (null !== errorMessage) {
            input.remove();
        }

        const html = bindhq.util.template(bindhq.tpl.renewals_manager_file, {
            file: file,
        });

        const item = $(html).insertBefore(this.uploadButtonTarget);

        item.findOne('input[type="checkbox"]').on('change', (e) => {
            const checkbox = $(e.target);

            $('#' + checkbox.attr('data-file-id')).attr(
                'data-included',
                checkbox.is(':checked'),
            );
        });
    }

    onDragAttachment(event) {
        event.preventDefault();
        event.stopPropagation();

        this.dragAreaTarget.classList.remove('hidden');
    }

    onReleaseDrag(event) {
        event.preventDefault();
        event.stopPropagation();

        this.dragAreaTarget.classList.add('hidden');

        for (const file of event.dataTransfer.files) {
            const input = this.#createAttachmentInput();
            const dt = new DataTransfer();

            dt.items.add(file);
            input.files = dt.files;

            $(input).trigger('change');
        }
    }

    #createAttachmentInput() {
        const container = $(this.attachmentsTarget);
        const pattern = new RegExp(
            container.data('prototype-name') || '__name__',
            'g',
        );

        const counter =
            container.data('counter') || container.children().length;

        const input = $(
            '<div>' +
                container.data('prototype').replaceAll(pattern, counter) +
                '</div>',
        ).findOne('input');

        input.attr('data-included', true);
        container.attr('data-counter', counter + 1);

        input.appendTo(container).on('change', (e) => {
            this.onAttachmentChosen(e, input.attr('id'));
        });

        return input.get(0);
    }

    #search(searchable, criteria) {
        const matchingName = searchable.name
            .toLowerCase()
            .includes(criteria.name.toLowerCase());
        const matchingDescription = searchable.description
            .toLowerCase()
            .includes(criteria.description.toLowerCase());
        const matchingLobs =
            Array.from(
                new Set([
                    ...searchable.lines_of_business,
                    ...criteria.lines_of_business,
                ]),
            ).length === searchable.lines_of_business.length;

        return (matchingName || matchingDescription) && matchingLobs;
    }
}
