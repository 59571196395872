import { Controller } from '@hotwired/stimulus';
const Clipboard = require('clipboard');

export default class extends Controller {
    static targets = [
        'keyName',
        'generateButton',
        'generatedKey',
        'keyNameError',
        'keyNameErrorMessage',
        'generatedTokenWrapper',
        'apiKeysForm',
    ];

    static values = {
        copyButtonText: String,
        errorMessage: String,
        duplicateName: String,
    };

    apiKeys = [];

    connect() {
        this.clipboard = new Clipboard('.copy-key-button', {
            text: () => this.generatedKeyTarget.textContent,
        });

        const setMessage = (btn, message) => {
            btn.innerHTML = message;
        };

        const unsetMessage = (btn) => {
            setTimeout(() => {
                btn.innerHTML = this.copyButtonTextValue;
            }, 1000);
        };

        this.clipboard.on('success', (e) => {
            setMessage(e.trigger, '<i class="fas fa-check"></i> Copied!');
            unsetMessage(e.trigger);
        });

        this.keyNameTarget.addEventListener('keypress', (e) =>
            this.handleReturnKey(e),
        );

        if ('' !== this.duplicateNameValue) {
            this.keyNameTarget.value = this.duplicateNameValue;
            this.handleNameChanged();
        }

        this.clipboard.on('error', (e) => {
            setMessage(e.trigger, '<i class="fas fa-times"></i> Failed!');
            unsetMessage(e.trigger);
        });

        if ('' !== this.errorMessageValue) {
            this.showErrorMessage(this.errorMessageValue);
            this.scrollToForm();
        }

        if (this.targets.has('generatedTokenWrapper')) {
            this.scrollToForm();
        }
    }

    scrollToForm() {
        this.apiKeysFormTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    disconnect() {
        this.keyNameTarget.removeEventListener('keypress', (e) =>
            this.handleReturnKey(e),
        );
        this.clipboard.destroy();
    }

    handleSubmit(e) {
        e.target.value = this.keyNameTarget.value.trim();

        if (e.target.value === '') {
            e.preventDefault();
            this.handleNameChanged();
        }
    }

    handleNameChanged() {
        const value = this.keyNameTarget.value.trim();

        if (value === '') {
            this.showErrorMessage('Field is mandatory');
        } else if (value === this.duplicateNameValue.trim()) {
            this.showErrorMessage(this.errorMessageValue);
        } else {
            this.hideErrorMessage();
        }
    }

    showErrorMessage(message) {
        this.keyNameErrorMessageTarget.innerHTML = `<span class="error form-error">${message}</span>`;
        this.keyNameErrorTarget.classList.remove('d-none');
        this.keyNameTarget.classList.remove('valid');
        this.keyNameTarget.classList.add('has-error');
    }

    hideErrorMessage() {
        this.keyNameErrorTarget.classList.add('d-none');
        this.keyNameTarget.classList.remove('has-error');
    }

    handleReturnKey(e) {
        // Do not submit until the generate button is clicked
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
}
