import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['collapsed', 'input'];

    connect() {
        this.#update();
    }

    onChange(event) {
        this.#update();
    }

    #update() {
        const index = this.inputTarget.selectedIndex;
        const value =
            0 !== index ? this.inputTarget.options[index].innerHTML : '~';

        this.collapsedTarget.innerHTML = value;
    }
}
