class DiligentEffortContactListener {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        this.container = container;
    }

    /**
     *
     */
    init() {
        this.container
            .find('.diligent-effort-contact')
            .on('change', (evt) => this.onChange(evt));
    }

    /**
     * @param {Event} evt
     */
    onChange(evt) {
        const select = $(evt.target);
        const data = select.select2('data');

        select
            .closest('tr')
            .find('td:nth-child(3)')
            .html(data.phone_extension || '~');
    }
}

module.exports = { DiligentEffortContactListener };
