import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const { CollectionEvents } = require('../../js/BindHQ/Form/Collection');
const progress = require('../../js/BindHQ/Utils/Progress');
const numeral = require('numeral');
const AutoNumeric = require('autonumeric');

export default class extends Controller {
    static debounces = ['onChange'];

    static outlets = ['premium-calculation'];

    static targets = [
        'adjustment',
        'adjustmentAmount',
        'adjustmentCode',
        'amount',
        'eligibility',
        'forms',
        'modal',
        'rate',
        'status',
    ];

    static values = {
        url: String,
        ratingFailure: String,
        ratingSuccess: String,
    };

    connect() {
        useDebounce(this, { wait: 500 });

        $(this.element).on('change.select2', () => this.onChange());

        $(this.element).on('address-edit-finished', () => this.onChange());

        this.element.addEventListener('change', () => this.onChange());
        this.element.addEventListener(CollectionEvents.ADDED, () =>
            this.onChange(),
        );
        this.element.addEventListener(CollectionEvents.REMOVED, () =>
            this.onChange(),
        );

        // legacy inline collection support
        $(this.element).on(
            'bindhq.forms.collection.inline.blank_item_added',
            () => this.onChange(),
        );
        $(this.element).on('bindhq.forms.collection.inline.item_removed', () =>
            this.onChange(),
        );

        const adjustmentCodeTargets = this.adjustmentCodeTargets;
        const adjustmentAmountTargets = this.adjustmentAmountTargets;

        this.adjustmentTargets.forEach((element) => {
            this.#updateAdjustment(
                element,
                adjustmentCodeTargets,
                adjustmentAmountTargets,
            );
        });
    }

    /**
     * @param {Event} Event
     */
    onFailureClick(event) {
        const anchor = event.currentTarget;

        this.modalTarget.querySelector('.modal-title').innerHTML =
            anchor.dataset.ratingFailureTitle;

        this.modalTarget.querySelector('.modal-body').innerHTML =
            anchor.dataset.ratingFailureBody;

        $(this.modalTarget).modal('show');
    }

    /**
     * @param {Event} Event
     */
    onSuccessClick(event) {
        const anchor = event.currentTarget;

        this.modalTarget.querySelector('.modal-title').innerHTML =
            anchor.dataset.ratingSuccessTitle;

        this.modalTarget.querySelector('.modal-body').innerHTML =
            anchor.dataset.ratingSuccessBody;

        $(this.modalTarget).modal('show');
    }

    onDebugClick() {
        const data = new FormData(this.element.closest('form'));

        const copy = document.createElement('form');
        copy.method = 'post';
        copy.style.display = 'none';
        copy.target = '_blank';
        copy.action = this.urlValue + '?format=html';

        for (const pair of data.entries()) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = pair[0];
            input.value = pair[1];
            copy.appendChild(input);
        }

        this.element.after(copy);
        copy.submit();
        copy.remove();
    }

    onChange() {
        const config = {
            method: 'post',
            body: new URLSearchParams(
                new FormData(this.element.closest('form')),
            ),
        };

        progress.start();

        fetch(this.urlValue, config)
            .then((response) => response.json())
            .then((response) => {
                if (this.hasEligibilityTarget) {
                    this.eligibilityTarget.innerHTML =
                        response.eligibility.html;
                }

                if (response.rating) {
                    this.#updateRating(response.rating);
                }

                if (response.forms) {
                    if (response.forms.has_changes) {
                        this.formsTarget.classList.remove('d-none');
                    } else {
                        this.formsTarget.classList.add('d-none');
                    }
                }
            })
            .finally(() => progress.stop());
    }

    /** @param {Event} Event */
    onAdjustmentCodeChange(event) {
        const adjustmentCode = event.target;
        const adjustmentCodeTargets = this.adjustmentCodeTargets;
        const adjustmentAmountTargets = this.adjustmentAmountTargets;

        this.adjustmentTargets.forEach((adjustment) => {
            if (adjustment.contains(adjustmentCode)) {
                this.#updateAdjustment(
                    adjustment,
                    adjustmentCodeTargets,
                    adjustmentAmountTargets,
                    true,
                );
            }
        });
    }

    /**
     * @param {Object} rating
     */
    #updateRating(rating) {
        const rateTargets = this.rateTargets;
        const amountTargets = this.amountTargets;
        const adjustmentTargets = this.adjustmentTargets;
        const adjustmentCodeTargets = this.adjustmentCodeTargets;
        const adjustmentAmountTargets = this.adjustmentAmountTargets;
        const statusTargets = this.statusTargets;

        rating.items.forEach((item) => {
            const rate = item.rate;
            const amount = item.amount.amount;
            const coverageElement = this.element
                .querySelector('input[value="' + item.source.uuid + '"]')
                .closest('.coverage');

            this.#setValue(coverageElement, rateTargets, rate);
            this.#setValue(coverageElement, amountTargets, amount);
            this.#updateStatus(coverageElement, item, statusTargets);

            const adjustment = this.#findElement(
                coverageElement,
                adjustmentTargets,
            );
            adjustment.dataset.enableDiscount = item.enable_discount
                ? '1'
                : '0';
            adjustment.dataset.maxDiscount = item.max_discount;
            adjustment.dataset.enableSurcharge = item.enable_surcharge
                ? '1'
                : '0';
            adjustment.dataset.maxSurcharge = item.max_surcharge;

            this.#updateAdjustment(
                adjustment,
                adjustmentCodeTargets,
                adjustmentAmountTargets,
            );
        });

        this.premiumCalculationOutlet.total();
    }

    /** @param {HTMLElement} adjustment */
    #updateAdjustment(
        adjustment,
        adjustmentCodeTargets,
        adjustmentAmountTargets,
        clearValue = false,
    ) {
        const adjustmentCode = this.#findElement(
            adjustment,
            adjustmentCodeTargets,
        );

        if (adjustmentCode.disabled) {
            return;
        }

        const discountValue = adjustment.dataset.discountValue;
        const enableDiscount = adjustment.dataset.enableDiscount === '1';
        const maxDiscount = numeral(adjustment.dataset.maxDiscount)
            .multiply(100)
            .value();

        const surchargeValue = adjustment.dataset.surchargeValue;
        const enableSurcharge = adjustment.dataset.enableSurcharge === '1';
        const maxSurcharge = numeral(adjustment.dataset.maxSurcharge)
            .multiply(100)
            .value();

        if (enableDiscount || enableSurcharge) {
            adjustment.classList.remove('d-none');

            this.#updateAdjustmentCode(
                adjustmentCode,
                enableDiscount,
                discountValue,
                enableSurcharge,
                surchargeValue,
            );

            let adjustmentCodeValue = adjustmentCode.value;

            if (adjustmentCode.tomselect) {
                adjustmentCode.tomselect.sync();
                adjustmentCodeValue = adjustmentCode.tomselect.getValue();
            }

            const maxValue = this.#getMaxValue(
                adjustmentCodeValue,
                discountValue,
                maxDiscount,
                surchargeValue,
                maxSurcharge,
            );

            const adjustmentAmount = this.#findElement(
                adjustment,
                adjustmentAmountTargets,
            );
            adjustmentAmount.disabled = !!!adjustmentCodeValue;
            adjustmentAmount.dataset.autonumericMaximumValue = maxValue;

            const autoNumericObject =
                AutoNumeric.getAutoNumericElement(adjustmentAmount);

            if (autoNumericObject) {
                if (clearValue) {
                    AutoNumeric.set(adjustmentAmount, '');
                }

                autoNumericObject.update({
                    maximumValue: maxValue,
                });
            }
        } else {
            adjustment.classList.add('d-none');

            adjustmentCode.value = '';

            if (adjustmentCode.tomselect) {
                adjustmentCode.tomselect.setValue('');
            }
        }
    }

    /**
     * @param {HTMLElement} adjustmentCode
     * @param {Boolean} enableDiscount
     * @param {String} discountValue
     * @param {Boolean} enableSurcharge
     * @param {String} surchargeValue
     */
    #updateAdjustmentCode(
        adjustmentCode,
        enableDiscount,
        discountValue,
        enableSurcharge,
        surchargeValue,
    ) {
        for (const option of adjustmentCode.options) {
            if (option.value === discountValue) {
                option.disabled = !enableDiscount;
            } else if (option.value === surchargeValue) {
                option.disabled = !enableSurcharge;
            }
        }
    }

    /** @return {String} */
    #getMaxValue(
        adjustmentCodeValue,
        discountValue,
        maxDiscount,
        surchargeValue,
        maxSurcharge,
    ) {
        if (adjustmentCodeValue === discountValue) {
            return maxDiscount;
        } else if (adjustmentCodeValue === surchargeValue) {
            return maxSurcharge;
        }

        return '100'; // Nothing is selected, but AutoNumeric requires a value.
    }

    /**
     * @param {HTMLElement} parent
     * @param {Array} elements
     * @param {String} value
     */
    #setValue(parent, elements, value) {
        const element = this.#findElement(parent, elements);
        element.value = value;
    }

    /**
     * @param {HTMLElement} coverage
     * @param {Object} item
     */
    #updateStatus(coverage, item, statusTargets) {
        statusTargets.forEach((element) => {
            if (coverage.contains(element)) {
                element.setAttribute('class', 'rating-' + item.type);

                if (item.type_description) {
                    if (item.type === this.ratingFailureValue) {
                        const failure = element.querySelector(
                            '.status-rating-failure a',
                        );

                        failure.dataset.ratingFailureTitle =
                            item.type_description.title;
                        failure.dataset.ratingFailureBody =
                            item.type_description.body;
                    } else if (item.type === this.ratingSuccessValue) {
                        const success = element.querySelector(
                            '.status-rating-success a',
                        );

                        success.dataset.ratingSuccessTitle =
                            item.type_description.title;
                        success.dataset.ratingSuccessBody =
                            item.type_description.body;
                    }
                }
            }
        });
    }

    /**
     * @param {HTMLElement} parent
     * @param {Array} elements
     */
    #findElement(parent, elements) {
        return elements.find((element) => parent.contains(element));
    }
}
