import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['child', 'type'];

    static values = {
        custom: String,
    };

    connect() {
        this.#update();
    }

    onTypeChange() {
        this.#update();
    }

    #update() {
        const row = this.childTarget.closest('.form-group');

        if (this.customValue === this.typeTarget.value) {
            row.classList.remove('d-none');
        } else {
            row.classList.add('d-none');
        }
    }
}
