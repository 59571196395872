import { Controller } from '@hotwired/stimulus';
const Progress = require('../../../js/BindHQ/Utils/Progress');
const Utils = require('../../../js/BindHQ/QuoteTemplate/Rating/Utils');

export default class extends Controller {
    static targets = [
        'description',
        'descriptionLabel',
        'file',
        'modal',
        'modalDescriptionInput',
        'modalNameInput',
        'modalUploadButton',
        'name',
        'nameLabel',
        'previewLink',
        'uploadInput',
        'errorMessage',
        'errorMessageText',
    ];

    static values = {
        url: String,
    };

    connect() {
        this.prototypeElement = this.element.querySelector('[data-prototype]');
        this.index =
            this.prototypeElement.querySelectorAll('.collection-item').length;
        this.table = null;
    }

    onSave() {
        if (!this.#validateModal()) {
            return;
        }

        const parser = new DOMParser();
        const prototype = parser.parseFromString(
            this.prototypeElement.dataset.prototype,
            'text/html',
        );
        const prototypeInner =
            prototype.getElementsByClassName('collection-item')[0].innerHTML;

        const element = document.createElement('div');
        element.classList.add(
            'collection-item',
            'collection-item--plain',
            'mb-3',
            'collection-border-top',
        );

        element.innerHTML = prototypeInner.replace(/__name__/g, this.index++);

        this.table.name = this.modalNameInputTarget.value;
        this.table.description = this.modalDescriptionInputTarget.value;

        this.prototypeElement.appendChild(element);

        $(this.modalTarget).modal('hide');
    }

    onUpload() {
        const file = this.uploadInputTarget.files[0];

        if (!file) {
            return;
        }

        if (!this.#validateFile(file)) {
            this.#fileIsInvalid();
            return;
        }

        this.uploadInputTarget.classList.remove('is-invalid', 'error');

        const formData = new FormData();
        formData.append('format', 'json');
        formData.append('owners', 'data-table');
        formData.append('file', file);

        Progress.start();

        const config = {
            method: 'POST',
            body: formData,
        };

        fetch(this.urlValue, config)
            .then((response) => response.json())
            .then((data) => this.#showModal(data))
            .finally(() => Progress.stop());

        this.uploadInputTarget.value = null;

        this.uploadInputTarget.classList.remove('dragover');
    }

    uploadInputTargetConnected(element) {
        element.addEventListener('dragenter', (event) => {
            if (event.target.classList.contains('dropzone-input')) {
                event.target.classList.add('dragover');
            }
        });

        element.addEventListener('dragleave', (event) => {
            if (event.target.classList.contains('dropzone-input')) {
                event.target.classList.remove('dragover');
            }
        });
    }

    /** @param {HTMLElement} element */
    nameTargetConnected(element) {
        if (!this.table) {
            return;
        }

        element.value = this.table.name;
    }

    /** @param {HTMLElement} element */
    nameLabelTargetConnected(element) {
        if (!this.table) {
            return;
        }

        element.innerHTML = this.table.name;
    }

    /** @param {HTMLElement} element */
    descriptionTargetConnected(element) {
        if (!this.table) {
            return;
        }

        element.value = this.table.description;
    }

    /** @param {HTMLElement} element */
    descriptionLabelTargetConnected(element) {
        if (!this.table) {
            return;
        }

        element.innerHTML = this.table.description;
    }

    /** @param {HTMLElement} element */
    fileTargetConnected(element) {
        if (!this.table) {
            return;
        }

        element.value = this.table.fileId;
    }

    /** @param {HTMLElement} element */
    previewLinkTargetConnected(element) {
        if (!this.table) {
            return;
        }

        const url = new URL(location.href + '/tables/' + this.table.fileId);
        url.searchParams.append('name', this.table.name);
        url.searchParams.append('description', this.table.description);

        element.setAttribute('href', url.pathname + url.search);
    }

    /** @param {HTMLElement} element */
    modalUploadButtonTargetConnected(element) {
        element.addEventListener('focusout', (event) => {
            $(this.modalNameInputTarget).valid();
        });
    }

    /** @param {Object} data */
    #showModal(data) {
        this.table = {
            fileId: data.id,
            name: null,
            description: null,
        };

        this.modalNameInputTarget.classList.replace('error', 'valid');
        this.modalNameInputTarget.value = Utils.toTableName(data.name);
        this.modalDescriptionInputTarget.value = '';

        $(this.modalTarget).modal('show');
    }

    #validateModal() {
        const allFields = Array.from(
            this.modalTarget.querySelectorAll(
                '.form-control:not(.select2-container):not(.ts-wrapper), select.tomselected',
            ),
        );

        allFields.forEach((input) => {
            $(input).valid();
        });

        const fieldsValid = allFields.every((element) => $(element).valid());
        return fieldsValid;
    }

    #validateFile(file) {
        const fileExt = file.name.split('.').pop().toLowerCase();
        const fileValid = fileExt === 'csv';
        return fileValid;
    }

    #fileIsInvalid() {
        this.errorMessageTextTarget.innerHTML =
            'The uploaded file must be a csv file.';

        this.errorMessageTarget.classList.replace('d-none', 'd-block');
        this.uploadInputTarget.classList.remove('dragover');
        this.uploadInputTarget.classList.add('is-invalid', 'error');
    }
}
