class GeoPoint {
    /**
     * @param {Float} latitude
     * @param {Float} longitude
     */
    constructor(latitude, longitude) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    /** @return {Float} */
    getLatitude() {
        return this.latitude;
    }

    /** @return {Float} */
    getLongitude() {
        return this.longitude;
    }
}

module.exports = { GeoPoint };
