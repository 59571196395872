const Observable = require('../../Observable');

const { CollectionEvents } = require('../../Form/Collection');

const { LocationBagEvents } = require('../../Utils/LocationBag');

const {
    PropertyLocation,
    PropertyLocationEvents,
} = require('./PropertyLocation');

const PropertyLocationBagEvents = {
    CHANGED: 'bindhq.product.commercial_property.property_location_bag.changed',
};

const numeral = require('numeral');

class PropertyLocationBag extends Observable {
    /**
     * @param {jQuery} container
     * @param {LocationBag} locationBag
     */
    constructor(container, locationBag) {
        super();
        this.container = container;
        this.items = [];
        this.geocoder = new google.maps.Geocoder();

        this.locationBag = locationBag;
        this.locationBag.subscribe(LocationBagEvents.CHANGED, () =>
            this.#fireChange(),
        );
    }

    contains(location) {
        return (
            this.items.findIndex(
                (propertyLocation) =>
                    propertyLocation.getLocation().getUuid() ===
                    location.getUuid(),
            ) > -1
        );
    }

    /**
     * @param {Location} location
     */
    add(location) {
        if (this.contains(location)) {
            throw new Error('Location already exists: ' + location.getUuid());
        }

        const subContainer = bindhq.forms.collection.inline.addBlankItem(
            this.container,
            this.#getCollectionContainer(),
        );

        subContainer.findOne('.location').val(location.getUuid());
        subContainer.findOne('.location-number').val(location.getNumber());

        subContainer
            .findOne('.location-collapse')
            .attr('id', `location-${location.getUuid()}`);

        subContainer
            .findOne('.location-collapse-btn .btn-fab')
            .attr('href', `#location-${location.getUuid()}`)
            .attr('aria-controls', `location-${location.getUuid()}`);

        const propertyLocation = new PropertyLocation(
            subContainer,
            this.geocoder,
            this.locationBag,
        );
        propertyLocation.subscribe(PropertyLocationEvents.CHANGED, () =>
            this.#fireChange(),
        );
        propertyLocation.init();

        this.items.push(propertyLocation);
        this.#fireChange();
    }

    getAll() {
        return this.items;
    }

    init() {
        const collectionContainer = this.#getCollectionContainer();

        this.container
            .find('.property-locations .property-location')
            .initEach((subContainer) => {
                const propertyLocation = new PropertyLocation(
                    subContainer,
                    this.geocoder,
                    this.locationBag,
                );
                propertyLocation.subscribe(PropertyLocationEvents.CHANGED, () =>
                    this.#fireChange(),
                );
                propertyLocation.init();

                this.items.push(propertyLocation);
                this.#fireChange();
            });

        this.container
            .get(0)
            .addEventListener(CollectionEvents.REMOVED, (event) => {
                const element = event.detail.args.removedElement;
                const locationId = $('.location', element).val();

                this.items = this.items.filter((propertyLocation) => {
                    const toRemove =
                        propertyLocation.getLocation().getUuid() === locationId;

                    if (toRemove) {
                        this.#fireChange();
                    }

                    return !toRemove;
                });
            });
    }

    #getCollectionContainer() {
        return this.container.find('[data-prototype]').first();
    }

    #fireChange() {
        super.fire(PropertyLocationBagEvents.CHANGED);
    }
}

module.exports = { PropertyLocationBag, PropertyLocationBagEvents };
