import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [];

    connect() {}

    getElement(el) {
        el.preventDefault();
        el.stopPropagation();

        let element = el.target;

        if (el.target.tagName !== 'button') {
            element = el.target.closest('button');
        }

        element?.classList.toggle('active');
        element.children[0]?.classList.toggle('rotate-90');

        return element;
    }

    onToggleCollapse(el) {
        const button = this.getElement(el);
        const { name, value } = button.dataset;
        const isOpen = button?.classList.contains('active');
        this.toggleElements(document.querySelectorAll(`.${name}-${value}`));
        button.closest('tr')?.classList.toggle('active');

        if (!isOpen && name === 'state') {
            this.hideElements(
                document.querySelectorAll(`.state-county-${value}`),
            );
        }
    }

    toggleElements(elements) {
        elements.forEach((element) => element?.classList.toggle('d-none'));

        this.application.logger.log({ elements });
    }

    hideElements(elements) {
        elements.forEach((element) => element?.classList.add('d-none'));
    }
}
