import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['select', 'amount', 'percent'];

    static values = {
        percentEnum: Array,
    };

    connect() {
        this.changed();
    }

    changed() {
        const isPercent = this.percentEnumValue.includes(
            this.selectTarget.value,
        );
        this.percentTarget.hidden = !isPercent;
        this.amountTarget.hidden = isPercent;
    }
}
