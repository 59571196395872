const CHEVRON_HTML = '<i class="fas fa-chevron-right"></i>';

class NextButton {
    /**
     * @param {Object} supportedLinesOfBusiness (eg. {'gl': 'General Liability'})
     * @param {jQuery} nextButton
     * @param {String} defaultText
     * @param {Array} saveButtons
     */
    constructor(
        supportedLinesOfBusiness,
        nextButton,
        defaultText,
        saveButtons,
    ) {
        this.supportedLinesOfBusiness = supportedLinesOfBusiness;
        this.nextButton = nextButton;
        this.defaultText = defaultText;
        this.saveButtons = saveButtons;
    }

    /**
     * @param {Array} selectedLinesOfBusiness (eg. ['gl', 'cp', 'ARTI'])
     */
    update(selectedLinesOfBusiness) {
        const linesOfBusiness = _.filter(
            selectedLinesOfBusiness,
            (selectedLineOfBusiness) => {
                return (
                    undefined !==
                    this.supportedLinesOfBusiness[selectedLineOfBusiness]
                );
            },
        );

        for (const key in this.supportedLinesOfBusiness) {
            if (-1 !== linesOfBusiness.indexOf(key)) {
                this.nextButton.html(
                    this.supportedLinesOfBusiness[key] + ' ' + CHEVRON_HTML,
                );

                return;
            }
        }

        this.nextButton.html(this.defaultText);
        this.saveButtons.forEach((button) => button.classList.remove('d-none'));
    }
}

module.exports = { NextButton };
