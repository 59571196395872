import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'icon', 'button', 'reset', 'cancel'];

    static values = {
        withResetConfirmation: {
            type: Boolean,
            default: false,
        },
        resetConfirmationTitle: {
            type: String,
            default: 'Reset Password',
        },
        resetConfirmationBody: {
            type: String,
            default: 'Are you sure you want to reset the password?',
        },
        resetConfirmationCancel: {
            type: String,
            default: 'Cancel',
        },
        resetConfirmationConfirm: {
            type: String,
            default: 'Confirm',
        },
        resetConfirmationConfirmType: {
            type: String,
            default: 'danger',
        },
    };

    onShowHide(e) {
        e.preventDefault();

        const isPassword = this.inputTarget.type === 'password';
        this.inputTarget.type = isPassword ? 'text' : 'password';
        this.iconTarget.classList.toggle('fa-eye', !isPassword);
        this.iconTarget.classList.toggle('fa-eye-slash', isPassword);
        this.buttonTarget.dataset.content = isPassword
            ? 'Hide password'
            : 'Show password';

        $(this.buttonTarget).popover('hide');
    }

    onReset(e) {
        e.preventDefault();

        if (this.withResetConfirmationValue) {
            this.#confirm(e);

            return;
        }

        this.resetForm();
    }

    resetForm() {
        this.#toggleVisibility(this.cancelTarget, true);
        this.#toggleVisibility(this.resetTarget, false);
        this.#toggleVisibility(this.buttonTarget, true);

        this.inputTarget.disabled = false;
        this.inputTarget.value = '';
    }

    onCancel(e) {
        e.preventDefault();

        this.#toggleVisibility(this.cancelTarget, false);
        this.#toggleVisibility(this.resetTarget, true);
        this.#toggleVisibility(this.buttonTarget, false);

        this.inputTarget.disabled = true;
        this.inputTarget.value = '......';

        // Clear error if reset is canceled
        if (
            this.element.contains(
                this.element.querySelector('.invalid-feedback'),
            )
        ) {
            this.inputTarget.classList.remove('error');
            this.#toggleVisibility(
                this.element.querySelector('.invalid-feedback'),
                false,
            );
        }

        this.#resetInputTypeAndIcon();
    }

    #resetInputTypeAndIcon() {
        console.debug(this.inputTarget);
        this.inputTarget.type = 'password';
        this.iconTarget.classList.replace('fa-eye-slash', 'fa-eye');
        this.buttonTarget.dataset.content = 'Show password';
    }

    #toggleVisibility(target, isVisible) {
        target.classList.toggle('d-block', isVisible);
        target.classList.toggle('d-none', !isVisible);
    }

    #confirm(event) {
        event.stopPropagation();
        event.preventDefault();

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: this.resetConfirmationTitleValue,
                    body: this.resetConfirmationBodyValue,
                    cancel: this.resetConfirmationCancelValue,
                    confirm: this.resetConfirmationConfirmValue,
                    confirmType: this.resetConfirmationConfirmTypeValue,
                    onConfirm: () => {
                        this.resetForm();
                    },
                    lockOnConfirm: false,
                    closeOnConfirm: true,
                },
            }),
        );
    }
}
