const assert = require('assert');

class ClassificationLocationMapper {
    constructor(container) {
        assert.strictEqual(
            1,
            container.length,
            'Expected the container to be exactly one element',
        );

        this.container = container;
    }

    mapClassificationLocations() {
        const map = [];

        for (const location of $('.classification-location', this.container)) {
            const classifications = [];
            const $address = $(location).findOne('.address-description:first');
            const locationNumber = $(location)
                .findOne('.location-number')
                .text();

            for (const el of $(location).find(
                '.classification .select2-container.classification-class-code',
            )) {
                if (
                    el &&
                    $(el).select2('val') &&
                    typeof $(el).select2('val') === 'string'
                ) {
                    const data = $(el).select2('data');
                    classifications.push({
                        target:
                            '#' + $(el).closest('.classification').attr('id'),
                        label: data.name || data.text,
                    });
                }
            }

            map.push({
                target: '#' + $(location).attr('id'),
                label:
                    '<b>Location # ' +
                    locationNumber +
                    ':</b> ' +
                    $address.text(),
                classifications,
            });
        }

        return map;
    }
}

module.exports = { ClassificationLocationMapper };
