import { Controller } from '@hotwired/stimulus';
const numeral = require('numeral');

export default class extends Controller {
    static targets = ['premium', 'premiumTotal'];

    connected = false;

    connect() {
        this.connected = true;
        this.total();
    }

    premiumTargetConnected() {
        this.total();
    }

    premiumTargetDisconnected() {
        this.total();
    }

    total() {
        if (!this.connected) {
            return;
        }

        if (!this.hasPremiumTotalTarget) {
            return;
        }

        if (this.premiumTotalTarget.disabled) {
            return;
        }

        const total = numeral(0);

        this.premiumTargets.forEach((premiumTarget) => {
            total.add(numeral(premiumTarget.value));
        });

        this.premiumTotalTarget.value = total.format('0,0.00');
    }
}
