import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
    static targets = ['counties', 'countiesWrapper', 'state', 'county'];

    static values = {
        countiesPath: String,
    };

    static debounces = ['reload'];

    connect() {
        useDebounce(this, { wait: 500 });

        this.cache = [];
        this.tomselect = new TomSelect(this.countiesTarget, {
            valueField: 'value',
            labelField: 'name',
            searchField: 'name',
        });

        this.reload();
    }

    onStateChange() {
        this.#clear();
        this.reload();
    }

    onCountyChange() {
        this.tomselect.addItem(this.countyTarget.value, true);
    }

    reload() {
        const state = this.stateTarget.value;

        if (state) {
            this.countiesWrapperTarget.classList.remove('d-none');

            if (this.cache[state]) {
                this.#refresh(this.cache[state]);
            } else {
                const url = this.countiesPathValue.replace('{state}', state);

                fetch(url)
                    .then((response) => response.json())
                    .then((counties) => {
                        this.cache[state] = counties;
                        this.#refresh(counties);
                    });
            }
        } else {
            this.countiesWrapperTarget.classList.add('d-none');
        }
    }

    #clear() {
        this.tomselect.clear(true);
        this.tomselect.clearOptions();
        this.tomselect.clearCache();
        this.tomselect.disable();
    }

    #refresh(counties) {
        this.#clear();

        let selectedCounty = null;

        counties.forEach((county) => {
            if (this.countyTarget.value === county.value) {
                selectedCounty = county;
            }

            this.tomselect.addOption(county);
        });

        this.tomselect.refreshOptions(false);
        this.tomselect.enable();

        if (null !== selectedCounty) {
            this.tomselect.addItem(selectedCounty.value, true);
        }
    }
}
