import { Controller } from '@hotwired/stimulus';
const Progress = require('../../js/BindHQ/Utils/Progress');

export default class extends Controller {
    static targets = ['button', 'configured', 'input', 'unconfigured'];

    /**
     * @param {Event} event
     */
    onClick(event) {
        event.preventDefault();

        bindhq.modalAjax.showUrl(this.buttonTarget.href, {
            callback: (modal) => {
                const form = modal.get(0).querySelector('form');

                form.addEventListener('submit', (event) => this.#submit(event));
            },
        });
    }

    /**
     * @param {Event} event
     */
    #submit(event) {
        const form = event.target;
        const button = form.querySelector('button[type="submit"]');

        event.preventDefault();

        if (!$(form).valid()) {
            return;
        }

        const config = {
            method: 'POST',
            body: new FormData(form),
        };

        Progress.start();

        bindhq.modalAjax.helper.toggleForm(form, button, true);

        fetch(form.action, config)
            .then((response) => {
                if (200 === response.status) {
                    return response.json();
                } else if (response.status >= 400 && response.status < 500) {
                    return response.text();
                } else {
                    throw new Error();
                }
            })
            .then((data) => {
                if (typeof data === 'string') {
                    this.#invalid(form, data);
                } else {
                    this.#success(form, data);
                }
            })
            .catch((error) => this.#error(form))
            .finally(() => Progress.stop());
    }

    /**
     * @param {HTMLElement} form
     * @param {Object} data
     */
    #success(form, data) {
        this.inputTarget.value = data.decision.id;
        this.buttonTarget.href = data.url;
        this.configuredTarget.classList.remove('d-none');
        this.unconfiguredTarget.classList.add('d-none');

        $(form.closest('.modal')).modal('toggle');
    }

    /**
     * @param {HTMLElement} form
     * @param {String} data
     */
    #invalid(form, data) {
        const button = form.querySelector('button[type="submit"]');
        const modalBody = form.closest('.modal').querySelector('.modal-body');

        bindhq.modalAjax.helper.toggleForm(form, button, false);

        const div = document.createElement('div');
        div.innerHTML = data;

        modalBody.innerHTML = div.querySelector('.modal-body').innerHTML;
    }

    /**
     * @param {HTMLElement} form
     */
    #error(form, error) {
        bindhq.modalAjax.helper.toggleForm(
            form,
            form.querySelector('button[type="submit"]'),
            false,
        );
    }
}
