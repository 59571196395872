import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        message: String,
    };

    onDeleteClicked() {
        alert(this.messageValue);
    }
}
