import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        description: String,
    };

    static targets = ['selected', 'description'];

    connect() {
        this.#changed();
    }

    onChange() {
        this.#changed();
    }

    #changed() {
        if (this.selectedTarget.checked) {
            this.descriptionTarget.readOnly = false;
        } else {
            this.descriptionTarget.readOnly = true;
            this.descriptionTarget.value = this.descriptionValue;
        }
    }
}
