const assert = require('assert');
const ElementExtractor = require('./Utils/ElementExtractor');

class FormListener {
    /**
     *
     * @param {jQuery} container
     */
    constructor(container) {
        assert.strictEqual(
            1,
            container.length,
            'Expected exactly one element for container',
        );

        this.container = container;
    }

    /**
     *
     * @param {string} fieldId
     * @param {Function} notify
     */
    listenFor(fieldId, notify) {
        this.container.on('change', `#${fieldId}`, function () {
            notify(ElementExtractor.extractValue(this));
        });

        //force first read
        $(`#${fieldId}`, this.container).trigger('change');
    }

    /**
     *
     * @param {string} parentId
     * @param {string} childSelector
     * @param {Function} notify
     */
    listenForChild(parentId, childSelector, notify) {
        const parent = $(`#${parentId}`, this.container);
        parent.on('change', childSelector, function () {
            notify(ElementExtractor.extractValue(this));
        });

        parent
            .closest('[data-prototype]')
            .on('bindhq.forms.collection.inline.item_removed', function () {
                notify(null);
            });

        //force first read
        $(childSelector, parent).trigger('change');
    }
}

module.exports = { FormListener };
