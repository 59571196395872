import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'code',
        'coinsurancePercentage',
        'coinsurancePercentageColumn',
        'coinsOrMonthlyLimitCode',
        'coinsOrMonthlyLimitCodeColumn',
    ];

    static values = {
        codes: Array,
    };

    connect() {
        this.#changed();
    }

    onCodeChanged() {
        this.#changed();
    }

    #changed() {
        if (-1 !== this.codesValue.indexOf(this.codeTarget.value)) {
            this.coinsOrMonthlyLimitCodeColumnTarget.classList.remove('d-none');
            this.coinsurancePercentageColumnTarget.classList.add('d-none');

            this.#setValue(this.coinsurancePercentageTarget, '');
        } else {
            this.coinsOrMonthlyLimitCodeColumnTarget.classList.add('d-none');
            this.coinsurancePercentageColumnTarget.classList.remove('d-none');

            this.#setValue(this.coinsOrMonthlyLimitCodeTarget, '');
        }
    }

    #setValue(select, value) {
        if (select.tomselect) {
            select.tomselect.setValue(value);
        } else {
            select.value = value;
        }
    }
}
