import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
    };

    onClick(event) {
        event.preventDefault();

        const form = this.element.closest('form');
        const originalAction = form.action;

        try {
            form.action = this.urlValue;
            form.target = '_blank';

            form.submit();
        } finally {
            form.action = originalAction;
            form.target = '';
        }
    }
}
