import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

const { AddressSelector } = require('../../js/BindHQ/Portal/AddressSelector');

export default class extends Controller {
    static targets = [
        'manualToggle',
        'details',
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'zip',
        'county',
    ];

    static values = {
        lookupUrl: String,
        detailsUrl: String,
        searchAddressText: String,
    };

    connect() {
        const selector = this.element.querySelector('input');
        const initialValue = selector.value;

        selector.value = null;

        new TomSelect(selector, {
            labelField: 'description',
            load: (query, callback) =>
                fetch(
                    this.lookupUrlValue + '?name=' + encodeURIComponent(query),
                )
                    .then((response) => response.json())
                    .then((results) => {
                        callback(results);
                    })
                    .catch(() => {
                        callback();
                    }),
            maxItems: 1,
            searchField: ['description'],
            valueField: 'id',
        });

        if (initialValue) {
            const item = { id: Math.random(), description: initialValue };

            selector.tomselect.addOption(item);
            selector.tomselect.setValue(item.id);
        }

        this.addressSelector = new AddressSelector(
            selector,
            this.manualToggleTarget,
            this.detailsTarget,
            this.detailsUrlValue,
            this.addressLine1Target,
            this.addressLine2Target,
            this.cityTarget,
            this.stateTarget,
            this.zipTarget,
            this.hasCountyTarget ? this.countyTarget : null,
            this.searchAddressTextValue,
        );

        this.addressSelector.init();

        if (0 !== this.element.querySelectorAll('.form-error-message').length) {
            this.addressSelector.showManualAddressFields();
        }
    }

    /**
     * @param {Object} evt
     */
    onChange(evt) {
        this.addressSelector.onChange(evt);
    }

    /**
     * @param {Object} evt
     */
    onManualToggle(evt) {
        this.addressSelector.onManualToggle(evt);
    }
}
