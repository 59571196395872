import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['adjustmentAmount', 'adjustmentCode', 'message'];

    static values = {
        discountCode: String,
        maxDiscountWarning: Number,
    };

    connect() {
        this.#update();
    }

    onChange() {
        this.#update();
    }

    #update() {
        const adjustmentCode = this.adjustmentCodeTarget.value;
        const adjustmentAmount = this.adjustmentAmountTarget.value;

        if (
            this.discountCodeValue === adjustmentCode &&
            adjustmentAmount > this.maxDiscountWarningValue
        ) {
            this.messageTarget.classList.remove('d-none');
        } else {
            this.messageTarget.classList.add('d-none');
        }
    }
}
