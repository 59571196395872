import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['type', 'lineOfBusiness'];

    static values = {
        premium: String,
    };

    connect() {
        this.singleLineOfBusiness = this.#getSingleLineOfBusiness();
    }

    onChange() {
        const type = this.typeTarget.tomselect.getValue();
        const tomselect = this.lineOfBusinessTarget.tomselect;

        if (type === this.premiumValue && this.singleLineOfBusiness) {
            tomselect.setValue(this.singleLineOfBusiness);
        }
    }

    #getSingleLineOfBusiness() {
        const options = this.lineOfBusinessTarget.querySelectorAll('option');

        // First option is placeholder.
        if (options.length === 2) {
            return options[1].value;
        }

        return null;
    }
}
