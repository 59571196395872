/* eslint no-console: 0 */

const _str = require('lodash/string');
const _collection = require('lodash/collection');
const AutoNumeric = require('autonumeric');
const defaults = AutoNumeric.getPredefinedOptions();
const custom = {
    modifyValueOnWheel: false,
    watchExternalChanges: true,
    showWarnings: process.env.NODE_ENV === 'development',
    onInvalidPaste: 'ignore',
    emptyInputBehavior: 'null',
};

class BigDecimalField {
    /**
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.element = element;
    }

    init() {
        new AutoNumeric(this.element, this.getOptions());

        this.element.addEventListener('paste', (evt) => {
            console.log('AutoNumericValue: ', this.element.value);
            console.log(
                'AutoNumericPaste: ',
                evt.clipboardData.getData('text/plain'),
            );
        });

        this.element.setAttribute('autocomplete', 'off');
    }

    getOptions() {
        return _.extend(
            {},
            defaults.float,
            custom,
            this.getUserDefinedOptions(),
        );
    }

    getUserDefinedOptions() {
        const options = {};

        _collection.each(this.element.dataset || {}, (value, key) => {
            if (_str.startsWith(key, 'autonumeric')) {
                const autoNumericKey = _str.camelCase(
                    key.replace('autonumeric', ''),
                );
                options[autoNumericKey] = value;
            }
        });

        return options;
    }
}

module.exports = { BigDecimalField };
