import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';
import Popper from 'popper.js';

export default class extends Controller {
    static targets = ['comparison', 'source', 'value'];

    static values = {
        enterValuePlaceholder: {
            type: String,
            default: 'Enter value...',
        },
        initial: String,
        noValueComparisons: Array,
        selectValuePlaceholder: {
            type: String,
            default: 'Select value...',
        },
    };

    connect() {
        this.valueWrapper = this.valueTarget.parentElement;
        this.valueName = this.valueTarget.name;
        this.valueControl = this.valueTarget;
        this.valueAttributes = this.valueTarget.attributes;

        this.#updateValueForSource();
        this.#updateValueForComparison();
    }

    onSourceChange() {
        this.comparisonTarget.tomselect.setValue(null, true);
        this.valueControl.value = null;

        this.#updateValueForSource();
    }

    onComparisonChange() {
        this.#updateValueForComparison();
    }

    #updateValueForSource() {
        const validComparisons = this.#getValidComparisons();
        const comparison = this.comparisonTarget;

        this.comparisonTarget.disabled = 0 === validComparisons.length;

        _.each(this.comparisonTarget.options, (option) => {
            option.disabled = !validComparisons.includes(option.value);

            if (this.comparisonTarget.tomselect) {
                this.comparisonTarget.tomselect.updateOption(option.value, {
                    value: option.value,
                    text: option.innerHTML,
                    disabled: option.disabled,
                });
            }
        });

        const urlData = this.#getUrlData();
        const value = this.valueControl.value;

        this.valueWrapper.innerHTML =
            '<label class="d-none required">Value</label>';

        if (urlData) {
            this.valueControl = this.#createControl('select');
            this.valueControl.disabled = true;
            this.valueControl.value = value;

            this.valueWrapper.appendChild(this.valueControl);

            const tomSelect = new TomSelect(this.valueControl, {
                maxOptions: null,
                valueField: urlData.valueName,
                labelField: urlData.valueLabel,
                searchField: urlData.valueLabel,
                dropdownParent: 'body',
                load: (query, callback) => {
                    const url = new URL(urlData.url, location.origin);
                    url.searchParams.append('name', query);

                    fetch(url)
                        .then((response) => response.json())
                        .then((json) => {
                            callback(json);
                        })
                        .catch(() => {
                            callback();
                        });
                },
                onInitialize: function () {
                    this.popper = new Popper(this.control, this.dropdown, {
                        placement: 'bottom-start',
                        modifiers: {
                            preventOverflow: {
                                escapeWithReference: true,
                            },
                        },
                        positionFixed: true,
                    });
                },
                onDropdownOpen: function () {
                    this.popper.update();

                    const isModalOpen =
                        document.body.classList.contains('modal-open');
                    this.dropdown.classList.toggle(
                        'modal-dropdown',
                        isModalOpen,
                    );
                },
            });

            if (value) {
                const optionConfig = {};
                optionConfig[urlData.valueName] = value;
                optionConfig[urlData.valueLabel] = this.initialValue;

                tomSelect.clearOptions();
                tomSelect.addOption(optionConfig);
                tomSelect.setValue(value);
            }

            tomSelect.on('focus', () => {
                tomSelect.load('');
            });
        } else {
            this.valueControl = this.#createControl('input');
            this.valueControl.classList.add('form-control');

            this.valueControl.value = value;
            this.valueControl.placeholder = this.enterValuePlaceholderValue;
            this.valueControl.disabled = true;

            this.valueWrapper.appendChild(this.valueControl);
        }
    }

    #updateValueForComparison() {
        const disabled =
            this.noValueComparisonsValue.includes(
                this.comparisonTarget.value,
            ) || this.comparisonTarget.value === '';

        if (this.valueControl.tomselect) {
            const tomselect = this.valueControl.tomselect;

            if (disabled) {
                tomselect.setValue(null, true);
                tomselect.disable();
                this.valueControl.classList.remove('required');
            } else {
                tomselect.enable();
                this.valueControl.classList.add('required');
            }
        } else {
            this.valueControl.disabled = disabled;
        }
    }

    #getValidComparisons() {
        const index = this.sourceTarget.selectedIndex;

        return 0 === index
            ? []
            : this.sourceTarget.options[index].dataset.comparisons;
    }

    #getUrlData() {
        const index = this.sourceTarget.selectedIndex;

        if (0 === index) {
            return false;
        }

        const data = this.sourceTarget.options[index].dataset;

        if (data.url) {
            return {
                url: data.url,
                valueName: data.urlValueName,
                valueLabel: data.urlValueLabel,
            };
        }

        return null;
    }

    #createControl(type) {
        const control = document.createElement(type);
        control.name = this.valueName;

        for (let i = 0; i < this.valueAttributes.length; i++) {
            control.setAttribute(
                this.valueAttributes[i].name,
                this.valueAttributes[i].value,
            );
        }

        return control;
    }
}
