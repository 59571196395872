const assert = require('assert');
const { ProgramCriteriaEvents } = require('./ProgramCriteria');

class ProgramSelector {
    constructor(container, url, application, programCriteria, selectedProgram) {
        assert.strictEqual(
            1,
            container.length,
            'Expected exactly one element matched for container, got: ' +
                container.length,
        );

        assert.ok(url, 'Expected a URL to be specified to query for programs');

        this.container = container;
        this.url = url;
        this.application = application;
        this.selectedProgram = selectedProgram;

        programCriteria.subscribe(ProgramCriteriaEvents.UPDATED, (evt) =>
            this.#render(evt),
        );

        programCriteria.subscribe(ProgramCriteriaEvents.CLEARED, () =>
            this.#clear(),
        );
    }

    #clear() {
        this.container.empty();
    }

    /**
     * @param {Object} evt
     */
    #render(evt) {
        this.container.addClass('program-selector-loading');

        $.ajax({
            url: this.url,
            data: {
                application: this.application,
                format: 'program-selector',
                state: evt.homeState,
                carrier: evt.carrier,
                marketing_company: evt.marketingCompany,
                business_type: evt.businessType,
                lines_of_business: evt.linesOfBusiness,
                selected: this.selectedProgram,
            },
            success: (html) => this.#success(html),
            complete: (html) =>
                this.container.removeClass('program-selector-loading'),
        });
    }

    #success(html) {
        this.container.html(html);
    }
}

module.exports = { ProgramSelector };
