class PremiumBasisLabel {
    /**
     * @param {HTMLElement} premiumBasis
     * @param {HTMLElement} label
     * @param {Object} mapping
     * @param {String} defaultValue
     */
    constructor(premiumBasis, label, mapping, defaultValue) {
        this.premiumBasis = premiumBasis;
        this.label = label;
        this.mapping = mapping;
        this.defaultValue = defaultValue;
    }

    /**
     *
     */
    onChange() {
        const value = this.premiumBasis.value;

        if (!value) {
            return;
        }

        const selected = this.premiumBasis.querySelector(
            '[value="' + value + '"]',
        );
        const label =
            this.mapping[selected.dataset.premiumBasisCode] ||
            this.defaultValue;

        this.label.innerHTML = label;
    }
}

module.exports = { PremiumBasisLabel };
