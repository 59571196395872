import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    onChange() {
        const id = this.element.id;
        const fileName = this.element.files[0].name;

        document.querySelector(`label[for="${id}"]`).innerHTML = fileName;
    }
}
