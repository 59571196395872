import { Controller } from '@hotwired/stimulus';

const premiumBasisSignMapping = {
    admissions: '$',
    area: '',
    cost: '$',
    each: '',
    flat: '$',
    other: '',
    payroll: '$',
    receipts: '$',
    sales: '$',
    units: '',
};

export default class extends Controller {
    static targets = ['premiumBasis', 'sign'];

    connect() {
        $('.classification-class-code', this.element).on('change', (event) => {
            const element = event.target;
            const selectedIndex = element.selectedIndex;
            const premiumBasisCode = element.options
                ? element.options[selectedIndex].dataset.premiumBasisCode
                : $(element).select2('data').premium_basis_code;

            this.premiumBasisTarget.tomselect.setValue(premiumBasisCode);
        });

        this.#updateExposureLabel();
    }

    onChange() {
        this.#updateExposureLabel();
    }

    #updateExposureLabel() {
        const sign =
            premiumBasisSignMapping[this.premiumBasisTarget.value] ?? null;

        this.signTarget.innerHTML = sign ? '(' + sign + ')' : '';
    }
}
