import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['andCondition', 'condition'];

    conditionTargetDisconnected() {
        this.#update();
    }

    #update() {
        this.andConditionTargets.forEach((andCondition) => {
            const conditionCount = this.conditionTargets.filter((condition) =>
                andCondition.contains(condition),
            ).length;

            if (0 === conditionCount) {
                andCondition.closest('.collection-item').remove();
            }
        });
    }
}
