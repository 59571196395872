class SubLocationBag {
    /**
     *
     */
    constructor() {
        this.items = [];
    }

    /**
     * @param {SubLocation} subLocation
     */
    add(subLocation) {
        this.items.push(subLocation);
    }

    /**
     * @param {SubLocation} subLocation
     */
    remove(subLocation) {
        this.items = this.items.filter((existing) => existing !== subLocation);
    }

    /**
     * @return {Array}
     */
    getItems() {
        return this.items;
    }

    /**
     * @return {Integer}
     */
    getNextNumber() {
        let nextNumber = 1;

        this.items.forEach((item) => {
            if (item.getNumber() >= nextNumber) {
                nextNumber = item.getNumber() + 1;
            }
        });

        return nextNumber;
    }

    /**
     * @param {SubLocation} subLocation
     *
     * @return {Boolean}
     */
    contains(subLocation) {
        return (
            1 ===
            this.items.filter((existing) => existing === subLocation).length
        );
    }
}

module.exports = { SubLocationBag };
