import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'fixedValue', 'fixedValueRow'];

    static values = {
        fixedValue: String,
    };

    connect() {
        this.#update();
    }

    onChange() {
        this.#update();
    }

    #update() {
        const value = this.inputTarget.tomselect
            ? this.inputTarget.tomselect.getValue()
            : this.inputTarget.value;

        if (this.fixedValueValue === value) {
            this.fixedValueRowTarget.classList.remove('d-none');
            this.fixedValueTarget.required = true;
        } else {
            this.fixedValueRowTarget.classList.add('d-none');
            this.fixedValueTarget.required = false;
        }
    }
}
