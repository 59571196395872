/**
 * @param {HTMLElement} element
 * @param {HTMLElement} container
 * @param {boolean} alwaysInit
 */
function lazyInit(element, container, alwaysInit = false) {
    if (!element.dataset.lazyController) {
        return;
    }

    const parentCollapse = element.closest('[data-controller*="collapse"]');

    if (alwaysInit || container === parentCollapse) {
        element.dataset.controller = element.dataset.lazyController;

        delete element.dataset.lazyController;
    }
}

module.exports = (container, alwaysInit = false) => {
    lazyInit(container, container, alwaysInit);

    container.querySelectorAll('[data-lazy-controller]').forEach((element) => {
        lazyInit(element, container, alwaysInit);
    });
};
