import { Controller } from '@hotwired/stimulus';

const { HTMLEditor } = require('../../js/BindHQ/Form/HTMLEditor');

export default class extends Controller {
    static targets = [
        'htmlInput',
        'editorErrors',
        'noPreviewWrapper',
        'previewWrapper',
        'previewPane',
        'previewButton',
        'token',
    ];

    static values = {
        previewUrl: String,
        templateReplacementUri: String,
        htmlTags: String,
    };

    #htmlTags;

    #templateReplacements = [];

    #htmlEditor;

    previewShadowRoot;

    connect() {
        this.#setUpHtmlInput();
    }

    htmlTagSelected(event) {
        event.preventDefault();

        this.#htmlEditor.insertContentAtCursor(
            this.#htmlTags[event.target.innerText],
        );
    }

    preview(event) {
        event.preventDefault();

        this.previewButtonTarget.disabled = true;

        this.#preview();
    }

    #setUpHtmlInput() {
        this.#fetchTemplateReplacements().then(() => {
            this.#htmlTags = JSON.parse(this.htmlTagsValue);

            this.#htmlEditor = new HTMLEditor(this.htmlInputTarget, {
                autocomplete: {
                    enabled: true,
                    data: this.#templateReplacements,
                    wordCharsRegex: /[\[\]\w]/,
                },
            });

            const codeMirrorTextAreaElement = this.#htmlEditor
                .getCodeMirrorInstance()
                .getWrapperElement()
                .querySelector('textarea');

            // Need to set this to match the target as Codemirror doesn't copy the required attribute onto its own textarea
            codeMirrorTextAreaElement.required = this.htmlInputTarget.required;

            // Unlikely we'd have this case, given the above, but just in case, the form gets submitted, we'd want to display the required error also
            if (this.htmlInputTarget.classList.contains('is-invalid')) {
                codeMirrorTextAreaElement.classList.add('is-invalid');
            }

            this.#setUpPreviewPane();
        });
    }

    #fetchTemplateReplacements() {
        return fetch(this.templateReplacementUriValue)
            .then((response) => response.json())
            .then((data) => {
                this.#templateReplacements = data;
            })
            .catch(() => {
                this.#logFetchTemplateReplacementsError();
            });
    }

    #setUpPreviewPane() {
        this.previewShadowRoot = this.previewPaneTarget.attachShadow({
            mode: 'open',
        });

        this.#preview();
    }

    #preview() {
        const data = new FormData();
        data.append('template_html_preview[body]', this.#htmlEditor.getValue());
        data.append('template_html_preview[_token]', this.tokenTarget.value);

        this.#turnOnNoPreviewPane();

        fetch(this.previewUrlValue, {
            method: 'POST',
            body: data,
        })
            .then((response) => response.text())
            .then((html) => {
                this.previewShadowRoot.innerHTML = html;
                this.#turnOnPreviewPane();
            })
            .finally(() => {
                this.previewButtonTarget.disabled = false;
            });
    }

    #turnOnNoPreviewPane() {
        this.previewWrapperTarget.classList.replace('d-flex', 'd-none');
        this.noPreviewWrapperTarget.classList.replace('d-none', 'd-flex');
    }

    #turnOnPreviewPane() {
        this.noPreviewWrapperTarget.classList.replace('d-flex', 'd-none');
        this.previewWrapperTarget.classList.replace('d-none', 'd-flex');
    }

    #logFetchTemplateReplacementsError() {
        this.editorErrorsTarget.innerHTML = `
                <span class="invalid-feedback mb-0 mt-2 d-block">
                    <span class="bindhq-error-box d-block">
                        <i class="fa fa-exclamation-circle"></i>
                        <span id="form-editor-error" class="error form-error-message">Failed to configure auto-completions for the editor.</span>
                    </span>
                </span>
        `;
    }
}
