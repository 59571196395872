import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
    };

    static targets = ['total', 'paid', 'outstanding'];

    connect() {
        $(this.element.closest('.accordion')).on('show.bs.collapse', () => {
            this.#load();
        });
    }

    #load() {
        fetch(this.urlValue)
            .then((response) => response.json())
            .then((data) => {
                this.totalTarget.textContent = this.#format(data.total);
                this.paidTarget.textContent = this.#format(data.paid);
                this.outstandingTarget.textContent = this.#format(
                    data.outstanding,
                );
            });
    }

    #format(value) {
        return `$${parseFloat(value).toFixed(2)}`;
    }
}
