/**
 * Figure out if a field's value is empty, with some special behaviour for select2.
 *
 * When select2 is disabled, we don't care if it is empty, so we say "false" regardless.
 * This is to help with things like dependent select cascades.
 *
 * @param {jQuery} field A form field which may or may not be decoated with select2
 * @return {Boolean} True if field is empty
 */
const isEmptyAndNotDisabledSelect2 = (field) => {
    // If the value is an empty array, then field is empty
    if (Array.isArray(field.val()) && !field.val().length) {
        return true;
    }

    if (!field.val()) {
        // we have a falsy value.
        // If it's a select2, we behave a bit differently.
        // force a boolean from the select2 data. If false, it's not select2.
        const fieldIsSelect2 = !!field.data('select2');

        if (fieldIsSelect2 && field.prop('disabled')) {
            // the field is disabled so we don't care that it's falsy; ignore it
            return false;
        }

        // if we got here, we must report this field as empty
        return true;
    }

    return false;
};

module.exports = { isEmptyAndNotDisabledSelect2 };
