import { Controller } from '@hotwired/stimulus';

const {
    DiligentEffortReasonSelector,
} = require('../../js/BindHQ/Quote/DiligentEffortReasonSelector');

const {
    DiligentEffortContactListener,
} = require('../../js/BindHQ/Quote/DiligentEffortContactListener');

const {
    DiligentEffortToggler,
} = require('../../js/BindHQ/Quote/DiligentEffortToggler');

export default class extends Controller {
    connect() {
        const container = $(this.element);
        const diligentEffortReasonSelector = new DiligentEffortReasonSelector(
            container,
        );
        const diligentEffortContactListener = new DiligentEffortContactListener(
            container,
        );
        const diligentEffortToggler = new DiligentEffortToggler(container);

        diligentEffortReasonSelector.init();
        diligentEffortContactListener.init();
        diligentEffortToggler.init();
    }
}
