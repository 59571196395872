import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['county', 'question', 'state', 'xwind'];

    static values = { mapping: Object, xwindState: String };

    connect() {
        this.#update();
    }

    onStateChange() {
        this.#update();
    }

    onCountyChange() {
        this.#update();
    }

    #update() {
        const state = this.stateTarget.value;
        const county = this.countyTarget.value;
        const question = this.mappingValue[state]
            ? this.mappingValue[state][county]
            : null;

        this.questionTarget
            .querySelectorAll('input[type="checkbox"]')
            .forEach((checkbox) => {
                checkbox.checked = false;
                checkbox.dispatchEvent(new CustomEvent('input')); // Notify Stimulus
            });

        if (state !== this.xwindStateValue) {
            this.xwindTarget.style.display = 'none';
        } else {
            this.xwindTarget.style.display = null;
        }

        if (question) {
            this.questionTarget.querySelector('label').innerHTML = question;
            this.questionTarget.classList.remove('d-none');
        } else {
            this.questionTarget.classList.add('d-none');
        }
    }
}
