const Observable = require('../../Observable');

const { ClassificationLocation } = require('./ClassificationLocation');

class ClassificationLocationBag extends Observable {
    /**
     * @param {jQuery} container
     * @param {LocationBag} locationBag
     */
    constructor(container, locationBag) {
        super();
        this.container = container;
        this.locationBag = locationBag;
        this.items = [];
        this.geocoder = new google.maps.Geocoder();
    }

    contains(location) {
        return (
            this.items.findIndex(
                (classificationLocation) =>
                    classificationLocation.getLocation().getUuid() ===
                    location.getUuid(),
            ) > -1
        );
    }

    /**
     * @param {Location} location
     */
    add(location) {
        if (this.contains(location)) {
            throw new Error('Location already exists: ' + location.getUuid());
        }

        const subContainer = bindhq.forms.collection.inline.addBlankItem(
            this.container,
            this.#getCollectionContainer(),
        );

        subContainer.findOne('.location').val(location.getUuid());
        subContainer
            .findOne('.collapse')
            .attr('id', `location-${location.getUuid()}`);
        subContainer
            .findOne('.btn-fab')
            .attr('href', `#location-${location.getUuid()}`)
            .attr('aria-controls', `location-${location.getUuid()}`);

        const classificationLocation = new ClassificationLocation(
            subContainer,
            this.geocoder,
            this.locationBag,
        );
        classificationLocation.init();

        this.items.push(classificationLocation);

        return classificationLocation;
    }

    getAll() {
        return this.items;
    }

    init() {
        const collectionContainer = this.#getCollectionContainer();

        this.container
            .find('.classification-locations .classification-location')
            .initEach((subContainer) => {
                const classificationLocation = new ClassificationLocation(
                    subContainer,
                    this.geocoder,
                    this.locationBag,
                );

                classificationLocation.init();

                this.items.push(classificationLocation);
            });

        collectionContainer.on(
            'bindhq.forms.collection.inline.item_removed',
            (evt, item) => {
                const locationId = $('.location', item).val();

                this.items = this.items.filter((classificationLocation) => {
                    return (
                        classificationLocation.getLocation().getUuid() ===
                        locationId
                    );
                });
            },
        );
    }

    #getCollectionContainer() {
        return this.container.find('[data-prototype]').first();
    }
}

module.exports = { ClassificationLocationBag };
