import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['effective', 'dateFrom', 'dateTo', 'dateType', 'type'];

    static values = { inForce: String };

    connect() {
        this.#update();
    }

    onTypeChange() {
        this.#update();
    }

    #update() {
        if (this.inForceValue === this.typeTarget.value) {
            this.effectiveTarget.classList.remove('d-none');
            this.dateTypeTarget.classList.add('d-none');
            this.dateFromTarget.classList.add('d-none');
            this.dateToTarget.classList.add('d-none');
        } else {
            this.effectiveTarget.classList.add('d-none');
            this.dateTypeTarget.classList.remove('d-none');
            this.dateFromTarget.classList.remove('d-none');
            this.dateToTarget.classList.remove('d-none');
        }
    }
}
