const Observable = require('../Observable');
const { GeoPoint } = require('../Models/GeoPoint');
const { Address } = require('../Models/Address');
const {
    validateAddressComponents,
} = require('../Validation/AddressComponentsValidator');

/**
 *
 * @typedef {Object} addressSelectors
 * @property {string} addressLine1Selector
 * @property {string} addressLine2Selector
 * @property {string} addressLine3Selector
 * @property {string} citySelector
 * @property {string} countySelector
 * @property {string} stateSelectSelector
 * @property {string} stateInputSelector
 * @property {string} zipCodeSelector
 * @property {string} addressLatitudeSelector
 * @property {string} addressLongitudeSelector
 * @property {string} usAddressOptionSelector
 * @property {string} internationalAddressOptionSelector
 * @property {string} addressLookupContainerSelector
 * @property {string} addressLookupSelectSelector
 * @property {string} btnEnterAddressManuallySelector
 * @property {string} btnSearchAddressSelector
 * @property {string} btnEditAddressSelector
 * @property {string} btnCancelEditAddressSelector
 * @property {string} btnSaveAddressSelector
 */

/**
 *
 * @typedef {Object} Address
 * @property {string} line1
 * @property {string} line2
 * @property {string} city
 * @property {string} state
 * @property {string} county
 * @property {string} postal_code
 * @property {string} international_state
 * @property {string} zipCodeSelector
 * @property {string} latitude
 * @property {string} longitude
 */

const EditType = {
    AddressLookup: 'AddressLookup',
    Manual: 'Manual',
};

class AddressSelector {
    /**
     *
     * @param {jQuery} container
     */
    constructor(container) {
        this.container = container;
        this.init();
    }

    /**
     * @param {Object} address
     */
    onSuccess(address) {
        this.container.trigger('address-details-found', [address]);
    }

    /**
     * @param {Object} jqXHR
     * @param {String} textStatus
     * @param {Error} errorThrown
     */
    onError(jqXHR, textStatus, errorThrown) {
        this.container.trigger('address-details-not-found', [
            jqXHR,
            textStatus,
            errorThrown,
        ]);

        console.error(jqXHR);
    }

    /**
     * @param {jQuery.Event} evt
     */
    onAddressSelected(evt) {
        const addressDetailUrl = this.container.data('address-detail-url');
        const item = evt.added;

        if (item && item.id) {
            this.container.trigger('loading-address-details');
            $.ajax({
                type: 'GET',
                url: addressDetailUrl,
                data: { placeId: item.id },
                success: this.onSuccess.bind(this),
                error: this.onError.bind(this),
            });
        } else if (item && !item.id) {
            //item can be undefined because the change event was called with val(null)
            throw new Error('Expected response to contain address ID');
        }
    }

    init() {
        bindhq.forms.ajaxselect.initContainer(this.container);

        this.container.on('change', this.onAddressSelected.bind(this));
    }
}

class AddressWidget extends Observable {
    /**
     *
     * @param {jQuery} container
     * @param {Location} location
     */
    constructor(container, location) {
        super();
        this.container = container;
        this.location = location;
        this.loadingDetails = false;
        this.editMode = false;
        this.editType = EditType.AddressLookup;
        this.addressSelectors = {};
        this.addressDOM = {
            $addressLine1: null,
            $addressLine2: null,
            $addressLine3: null,
            $city: null,
            $county: null,
            $stateSelect: null,
            $stateInput: null,
            $zipCode: null,
            $addressLatitude: null,
            $addressLongitude: null,
            $usAddressOption: null,
            $addressLookupSelect: null,
            $addressLookupContainer: null,
            $internationalAddressOption: null,
            $addressManualFormContainer: null,
            $addressManualFormFields: null,
            $btnEnterAddressManually: null,
            $btnSearchAddressAgain: null,
            $btnEditAddress: null,
            $btnCancelEditAddress: null,
            $btnSaveAddress: null,
        };
    }

    /**
     * Bind DOM elements inside container
     * @param {addressSelectors} [addressSelectors]
     */
    bindElements({
        addressLine1Selector,
        addressLine2Selector,
        addressLine3Selector,
        citySelector,
        countySelector,
        stateSelectSelector,
        stateInputSelector,
        zipCodeSelector,
        addressLatitudeSelector,
        addressLongitudeSelector,
        usAddressOptionSelector,
        internationalAddressOptionSelector,
        addressLookupContainerSelector,
        addressLookupSelectSelector,
        addressManualFormContainerSelector,
        btnEnterAddressManuallySelector,
        btnSearchAddressSelector,
        btnEditAddressSelector,
        btnCancelEditAddressSelector,
        btnSaveAddressSelector,
    }) {
        this.addressDOM.$addressLine1 = $(
            addressLine1Selector || '.address_line1',
            this.container,
        );
        this.addressDOM.$addressLine2 = $(
            addressLine2Selector || '.address_line2',
            this.container,
        );
        this.addressDOM.$addressLine3 = $(
            addressLine3Selector || '.address_line3',
            this.container,
        );
        this.addressDOM.$city = $(citySelector || '.city', this.container);
        this.addressDOM.$county = $(
            countySelector || '.county',
            this.container,
        );
        this.addressDOM.$stateSelect = $(
            stateSelectSelector || 'select.state',
            this.container,
        );
        this.addressDOM.$stateInput = $(
            stateInputSelector || '.state_text',
            this.container,
        );
        this.addressDOM.$zipCode = $(zipCodeSelector || '.zip', this.container);
        this.addressDOM.$addressLatitude = $(
            addressLatitudeSelector || '.geo_point_latitude',
            this.container,
        );
        this.addressDOM.$addressLongitude = $(
            addressLongitudeSelector || '.geo_point_longitude',
            this.container,
        );
        this.addressDOM.$usAddressOption = $(
            usAddressOptionSelector || '.international_address_0',
            this.container,
        );
        this.addressDOM.$internationalAddressOption = $(
            internationalAddressOptionSelector || '.international_address_1',
            this.container,
        );
        this.addressDOM.$addressLookupContainer = $(
            addressLookupContainerSelector || '.address-lookup-container',
            this.container,
        );
        this.addressDOM.$addressLookupSelect = $(
            addressLookupSelectSelector || 'input.address-widget',
            this.container,
        );
        this.addressDOM.$addressManualFormContainer = $(
            addressManualFormContainerSelector || '.address-manual-form',
            this.container,
        );
        this.addressDOM.$addressManualFormFields =
            this.addressDOM.$addressManualFormContainer.find('input,select');
        this.addressDOM.$btnEnterAddressManually = $(
            btnEnterAddressManuallySelector || '.btn-enter-manually',
            this.container,
        );
        this.addressDOM.$btnSearchAddressAgain = $(
            btnSearchAddressSelector || '.btn-search-address-again',
            this.container,
        );
        this.addressDOM.$btnEditAddress = $(
            btnEditAddressSelector || '.btn-edit-address',
            this.container,
        );
        this.addressDOM.$btnCancelEditAddress = $(
            btnCancelEditAddressSelector || '.btn-cancel-edit-address',
            this.container,
        );
        this.addressDOM.$btnSaveAddress = $(
            btnSaveAddressSelector || '.btn-save-address',
            this.container,
        );
    }

    /**
     * Enable editing address
     * @param {Event} e
     */
    edit(e) {
        this.editMode = true;
        this.editType = EditType.AddressLookup;
        this.activateAddressLookupMode(e);
    }

    /**
     * Disable editing address
     */
    finishEdit() {
        if (this.editType === EditType.Manual) {
            const formProperties = this.serializeManualFormFields();
            const validationErrors = validateAddressComponents(formProperties);
            const event = !validationErrors ? 'addressSaved' : 'addressErrors';
            super.fire(event, validationErrors || formProperties);

            if (validationErrors) {
                return;
            }
        }

        this.editMode = false;
        this.loadingDetails = false;
        this.addressDOM.$addressManualFormContainer &&
            this.addressDOM.$addressManualFormContainer.addClass('d-none');
        this.addressDOM.$addressLookupContainer.addClass('d-none');
        this.container.trigger('address-edit-finished');
    }

    /**
     * Serialize manual address form fields to Object
     * @returns {Object}
     */
    serializeManualFormFields() {
        const serialized = {};

        for (const field of this.addressDOM.$addressManualFormFields) {
            serialized[$(field).attr('name')] = $(field).val();
        }

        return serialized;
    }

    /**
     *
     * @param {Address} address
     */
    populateManualFormFields(address) {
        this.addressDOM.$addressLine1.val(address.line1);
        this.addressDOM.$addressLine2.val(address.line2);
        this.addressDOM.$addressLine3.val('');
        this.addressDOM.$city.val(address.city);
        this.addressDOM.$stateSelect.val(address.state).trigger('change');
        this.addressDOM.$county.val(address.county);
        this.addressDOM.$zipCode.val(address.postalCode);
        this.addressDOM.$addressLatitude.val(address.latitude);
        this.addressDOM.$addressLongitude.val(address.longitude);
    }

    /**
     * Handler for Address Detail callback
     * @param {Event} e
     * @param {Address} address
     */
    onAddressDetailsFound(e, address) {
        this.loadingDetails = false;
        this.addressDOM.$addressLine1.val(address.line1);
        this.addressDOM.$addressLine2.val(address.line2);
        this.addressDOM.$addressLine3.val('');
        this.addressDOM.$city.val(address.city);
        this.addressDOM.$stateSelect.val(address.state).trigger('change');
        this.addressDOM.$county.val(address.county);
        this.addressDOM.$zipCode.val(address.postal_code);
        this.addressDOM.$addressLatitude.val(address.latitude);
        this.addressDOM.$addressLongitude.val(address.longitude);
        const addressModel = new Address(
            address.line1,
            address.line2,
            address.city,
            address.state,
            address.international_state,
            address.postal_code,
            address.county,
            null,
        );
        const geoPoint = new GeoPoint(
            parseFloat(address.latitude),
            parseFloat(address.longitude),
        );
        super.fire('addressDetailsFound', {
            address: addressModel,
            geoPoint,
        });
        this.finishEdit();
    }

    rebindSaveAddress() {
        this.addressDOM.$btnSaveAddress &&
            this.addressDOM.$btnSaveAddress.on(
                'click',
                this.finishEdit.bind(this),
            );

        this.addressDOM.$btnSearchAddressAgain &&
            this.addressDOM.$btnSearchAddressAgain.on(
                'click',
                this.activateAddressLookupMode.bind(this),
            );
    }

    /**
     * Handler for Address Detail not Found
     */
    onAddressDetailsNotFound() {
        alert('Address details could not be loaded.');
        this.loadingDetails = false;
        this.activateAddressManualMode();
    }

    /**
     * Enable address lookup field and disable manual editing
     * @param {Event} e
     */
    activateAddressLookupMode(e) {
        e && e.preventDefault();
        super.fire('activateAddressLookupMode');
        this.editType = EditType.AddressLookup;
        const {
            $addressLookupContainer,
            $addressManualFormContainer,
            $addressManualFormFields,
        } = this.addressDOM;
        if ($addressLookupContainer.length) {
            $addressManualFormFields.val('');
            $addressManualFormFields.find('.state').val('AL');
            $addressManualFormContainer &&
                $addressManualFormContainer.addClass('d-none');
            $addressLookupContainer.removeClass('d-none');
        }
    }

    /**
     * Enable manual editing and disable address lookup field
     * @param {Event} e
     */
    activateAddressManualMode(e) {
        e && e.preventDefault();
        if (this.loadingDetails) {
            alert('Loading Address Information, please wait');
            return;
        }
        super.fire('activateAddressManualMode');
        this.editType = EditType.Manual;
        this.addressDOM.$addressManualFormFields.val('');
        this.addressDOM.$addressLookupSelect.val(null).trigger('change');
        if (
            !this.addressDOM.$addressManualFormContainer ||
            !this.addressDOM.$addressManualFormContainer.length
        ) {
            const $rootAddressManualFormContainer = $('.address-manual-form')
                .last()
                .clone();
            this.addressDOM.$addressLookupContainer.after(
                $rootAddressManualFormContainer,
            );
            $rootAddressManualFormContainer.removeClass('d-none');
            //Bind form fields from correct container
            this.addressDOM.$addressManualFormFields =
                $rootAddressManualFormContainer.find('input,select');
            this.addressDOM.$addressManualFormContainer =
                $rootAddressManualFormContainer;
            this.bindElements(this.addressSelectors);
            this.rebindSaveAddress();
            this.addressDOM.$stateSelect.select2(); // DO NOT REMOVE: select2 looses its reference if cloned within the form container
        } else {
            this.addressDOM.$addressManualFormContainer.removeClass('d-none');
        }
        this.addressDOM.$addressLookupContainer.addClass('d-none');

        if (this.location) {
            this.populateManualFormFields(this.location.address);
        }
    }

    /**
     * Toggler for radio select
     */
    onUsAddressSelected() {
        this.addressDOM.$insuredStateInput.addClass('d-none');
        this.addressDOM.$insuredStateSelect.removeClass('d-none');
    }

    /**
     * Toggler for radio select
     */
    onInternationalAddressSelected() {
        this.addressDOM.$insuredStateInput.removeClass('d-none');
        this.addressDOM.$insuredStateSelect.addClass('d-none');
    }

    isUsAddress() {
        return this.addressDOM.$usAddressOption.is(':checked');
    }

    loadingAddressDetails() {
        this.loadingDetails = true;
    }

    /**
     *
     * @param {addressSelectors} [addressSelectors]
     * @return {AddressWidget}
     */
    init(addressSelectors = {}) {
        this.addressSelectors = addressSelectors;
        this.bindElements(addressSelectors);
        if (this.addressDOM.$addressLookupSelect) {
            this.addressDOM.$addressLookupSelect.on(
                'loading-address-details',
                this.loadingAddressDetails.bind(this),
            );
            this.addressDOM.$addressLookupSelect.on(
                'address-details-found',
                this.onAddressDetailsFound.bind(this),
            );
            this.addressDOM.$addressLookupSelect.on(
                'address-details-not-found',
                this.onAddressDetailsNotFound.bind(this),
            );
        }
        this.addressDOM.$btnEnterAddressManually &&
            this.addressDOM.$btnEnterAddressManually.on(
                'click',
                this.activateAddressManualMode.bind(this),
            );
        this.addressDOM.$btnSearchAddressAgain &&
            this.addressDOM.$btnSearchAddressAgain.on(
                'click',
                this.activateAddressLookupMode.bind(this),
            );
        this.addressDOM.$usAddressOption &&
            this.addressDOM.$usAddressOption.on(
                'click',
                this.onUsAddressSelected.bind(this),
            );
        this.addressDOM.$internationalAddressOption &&
            this.addressDOM.$internationalAddressOption.on(
                'click',
                this.onInternationalAddressSelected.bind(this),
            );
        this.addressDOM.$btnEditAddress &&
            this.addressDOM.$btnEditAddress.on('click', this.edit.bind(this));
        this.addressDOM.$btnCancelEditAddress &&
            this.addressDOM.$btnCancelEditAddress.on(
                'click',
                this.finishEdit.bind(this),
            );
        this.addressDOM.$btnSaveAddress &&
            this.addressDOM.$btnSaveAddress.on(
                'click',
                this.finishEdit.bind(this),
            );

        new AddressSelector(this.addressDOM.$addressLookupSelect);

        return this;
    }
}

module.exports = { AddressWidget, AddressSelector, EditType };
