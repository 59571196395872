import { Controller } from '@hotwired/stimulus';

const { FormSearcher } = require('../../js/BindHQ/Quote/FormSearcher');

export default class extends Controller {
    static targets = ['input', 'result'];

    connect() {
        this.formSearcher = new FormSearcher(
            this.inputTarget,
            this.resultTargets,
        );
    }

    onChange() {
        this.formSearcher.onChange();
    }
}
