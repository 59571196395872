import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['carrier', 'marketingCompany'];

    connect() {
        $(this.marketingCompanyTarget).change((event) => this.#changed(event));

        this.#changed();
    }

    /**
     * @param {null|Event} event
     */
    #changed(event) {
        const carrier = $(this.carrierTarget);

        // Only clear after user interaction, not after connect
        if (event) {
            carrier.select2('val', null);
        }

        const filterParams = {};

        const value = $(this.marketingCompanyTarget).val();

        if (filterParams) {
            filterParams['marketing_company_id'] = value;
        }

        carrier.data('filter', filterParams);
    }
}
