import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['document', 'quote', 'template'];

    connect() {
        const div = document.createElement('div');
        div.innerHTML = this.templateTarget.innerHTML;

        this.multiSelect = div.querySelector('.multi-select');
        this.multiSelect.querySelector('.items').innerHTML = '';

        this.element.append(this.multiSelect);
    }

    onChange() {
        const selectedQuotes = this.#getSelectedQuotes();
        const visibleClass = 'multi-select-visible';

        if (0 === selectedQuotes.length) {
            this.multiSelect.classList.remove(visibleClass);
        } else {
            const queryString = new URLSearchParams({
                'template_parameters[quotes]': selectedQuotes.join(','),
            });

            this.documentTargets.forEach((anchor) => {
                anchor.href = anchor.dataset.url + '?' + queryString;
            });

            this.multiSelect.classList.add(visibleClass);
        }
    }

    /** @return {Array} */
    #getSelectedQuotes() {
        const selectedQuotes = [];

        this.quoteTargets.forEach((quoteElement) => {
            if (quoteElement.checked) {
                selectedQuotes.push(quoteElement.dataset.quoteId);
            }
        });

        return selectedQuotes;
    }
}
