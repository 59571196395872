import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['template', 'subject', 'body'];

    /**
     * @param {Event} event
     */
    onChange(event) {
        const templateId = this.templateTarget.value;

        this.setValues('Loading...', 'Loading...');

        $.ajax({
            url: '/secure/templates/email/' + templateId + '/view',
            success: (data) => {
                this.setValues(data.subject, data.body);
            },
        });
    }

    /**
     * @param {String} subject
     * @param {String} body
     */
    setValues(subject, body) {
        if (subject) {
            this.subjectTarget.value = subject;
        }

        if (body) {
            $(this.bodyTarget).redactor('set', body);
        }
    }
}
