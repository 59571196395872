import { Controller } from '@hotwired/stimulus';

const { Collapse } = require('../js/BindHQ/Utils/Collapse');

export default class extends Controller {
    static targets = ['collapsedTitle'];

    connect() {
        new Collapse($(this.element));
    }

    onCodeChanged(event) {
        const select = event.currentTarget;

        this.collapsedTitleTarget.innerHTML = select.selectedIndex
            ? select.options[select.selectedIndex].innerHTML
            : '~';
    }
}
