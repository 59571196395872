import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        programmeUrl: String,
    };

    static targets = [
        'additionalCarrierProgrammes',
        'additionalCarrierProgrammesCollection',
        'carrier',
        'defaultCarrierProgramme',
        'carrierProgramme',
    ];

    connect() {
        this.#updateAdditional();
    }

    onDefaultCarrierProgrammeChange() {
        this.#updateAdditional();
    }

    onCarrierChange() {
        this.#updateOptions();
        this.#updateAdditional();
    }

    /**
     * @param {HTMLElement} programmeElement
     */
    carrierProgrammeTargetConnected(programmeElement) {
        const options =
            this.defaultCarrierProgrammeTarget.querySelectorAll('option');

        const programmes = _.filter(
            _.map(options, (option) => {
                return {
                    id: option.value,
                    text: option.innerHTML,
                };
            }),
            (programme) => programme.id,
        );

        this.#updateProgrammeElement(programmeElement, programmes);
    }

    #updateAdditional() {
        const carrierProgrammeId = this.defaultCarrierProgrammeTarget.value;

        if (carrierProgrammeId) {
            this.additionalCarrierProgrammesTarget.classList.remove('d-none');
        } else {
            this.additionalCarrierProgrammesTarget.classList.add('d-none');
            this.additionalCarrierProgrammesCollectionTarget.querySelector(
                '[data-prototype]',
            ).innerHTML = '';
        }
    }

    #updateOptions() {
        this.carrierProgrammeTargets
            .concat([this.defaultCarrierProgrammeTarget])
            .forEach((programmeElement) => {
                const tomselect = programmeElement.tomselect;

                tomselect.clear(true);
                tomselect.clearOptions();
                tomselect.disable();
            });

        const carrierId = this.carrierTarget.value;

        if (carrierId) {
            fetch(this.programmeUrlValue.replace(/{carrierId}/, carrierId))
                .then((response) => response.json())
                .then((programmes) => {
                    this.#refreshOptions(
                        _.map(programmes, (programme) => {
                            programme.text =
                                programme.name + ' - ' + programme.prefix;

                            return programme;
                        }),
                    );
                });
        }
    }

    /**
     * @param {Array} programmes
     */
    #refreshOptions(programmes) {
        this.carrierProgrammeTargets
            .concat([this.defaultCarrierProgrammeTarget])
            .forEach((programmeElement) => {
                this.#updateProgrammeElement(programmeElement, programmes);
            });
    }

    /**
     * @param {HTMLElement} programmeElement
     * @param {Array} programmes
     */
    #updateProgrammeElement(programmeElement, programmes) {
        const tomselect = programmeElement.tomselect;

        if (!tomselect) {
            return;
        }

        programmeElement
            .querySelectorAll('option')
            .forEach((option) => programmeElement.removeChild(option));

        this.#addOption(programmeElement, '', '');

        programmes.forEach((programme) => {
            this.#addOption(programmeElement, programme.id, programme.text);
        });

        tomselect.sync();
        tomselect.enable();
    }

    /**
     * @param {HTMLElement} programmeElement
     * @param {String} value
     * @param {String} text
     */
    #addOption(programmeElement, value, text) {
        const option = document.createElement('option');
        option.setAttribute('value', value);
        option.innerHTML = text;

        programmeElement.appendChild(option);
    }
}
