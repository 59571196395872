import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['form', 'header', 'total'];

    static values = {
        selectedClass: {
            type: String,
            default: 'selected',
        },
    };

    connect() {
        this.#updateTotals();
    }

    /**
     * @param {Event} event
     */
    onSelect(event) {
        const [form, otherForm] = this.#getElements(event);

        form.classList.add(this.selectedClassValue);
        form.querySelector('.form-value').value = '1';

        otherForm.classList.add(this.selectedClassValue);

        this.#updateTotals();
    }

    /**
     * @param {Event} event
     */
    onDelete(event) {
        const [form, otherForm] = this.#getElements(event);

        form.classList.remove(this.selectedClassValue);
        form.querySelector('.form-value').value = '';

        otherForm.classList.remove(this.selectedClassValue);

        this.#updateTotals();
    }

    /**
     * @param {Event} event
     *
     * @return [HTMLElement, HTMLElement]
     */
    #getElements(event) {
        const id = event.params.id;

        return [
            this.element.querySelector(`#form-${id}`),
            this.element.querySelector(`#other-form-${id}`),
        ];
    }

    #updateTotals() {
        let total = 0;

        this.formTargets.forEach((form) => {
            if ('1' === form.value) {
                total++;
            }
        });

        if (0 === total) {
            this.headerTarget.classList.add('d-none');
        } else {
            this.headerTarget.classList.remove('d-none');
            this.totalTarget.innerHTML = total;
        }
    }
}
