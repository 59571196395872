import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        custom: String,
    };

    static targets = ['manage', 'usage'];

    connect() {
        this.#update();
    }

    onUsageChange() {
        this.#update();
    }

    #update() {
        if (this.customValue === this.usageTarget.value) {
            this.manageTarget.classList.remove('d-none');
            this.manageTarget.classList.add('d-flex');
        } else {
            this.manageTarget.classList.remove('d-flex');
            this.manageTarget.classList.add('d-none');
        }
    }
}
