const { PropertyLocationBagEvents } = require('./PropertyLocationBag');

const { truncate } = require('../../Utils/String');

class NavigationListener {
    /**
     * @param {HTMLElement} element
     * @param {PropertyLocationBag} propertyLocationBag
     */
    constructor(element, propertyLocationBag) {
        this.element = element;
        this.propertyLocationBag = propertyLocationBag;
    }

    /**
     *
     */
    init() {
        this.#redraw();

        this.propertyLocationBag.subscribe(
            PropertyLocationBagEvents.CHANGED,
            () => {
                this.#redraw();
            },
        );
    }

    /**
     *
     */
    #redraw() {
        const menu = document.createElement('ul');
        menu.setAttribute('class', 'side-form-items');

        this.propertyLocationBag.getAll().forEach((propertyLocation) => {
            const locationAnchor = document.createElement('a');
            locationAnchor.dataset.controller = 'scroll-to';
            locationAnchor.dataset.sideScrollerTarget = 'link';
            locationAnchor.dataset.action = 'side-scroller#onClick';
            locationAnchor.href = '#' + propertyLocation.getAnchor();
            locationAnchor.innerHTML = truncate(
                '<b>Location # ' +
                    propertyLocation.getLocation().getNumber() +
                    ':</b> ' +
                    propertyLocation
                        .getLocation()
                        .getAddress()
                        .getDescription(),
                40,
                '...',
            );

            const locationItem = document.createElement('li');
            locationItem.appendChild(locationAnchor);

            const subLocationItems = document.createElement('ul');

            propertyLocation.getSubLocations().forEach((subLocation) => {
                const subLocationAnchor = document.createElement('a');
                subLocationAnchor.dataset.controller = 'scroll-to';
                subLocationAnchor.href = '#' + subLocation.getAnchor();
                subLocationAnchor.innerHTML =
                    'Building # ' + subLocation.getNumber();

                const subLocationItem = document.createElement('li');
                subLocationItem.appendChild(subLocationAnchor);

                subLocationItems.appendChild(subLocationItem);
            });

            locationItem.appendChild(subLocationItems);
            menu.appendChild(locationItem);
        });

        this.element.innerHTML = '';
        this.element.appendChild(menu);
    }
}

module.exports = { NavigationListener };
