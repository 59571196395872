import { Controller } from '@hotwired/stimulus';

const progress = require('../../js/BindHQ/Utils/Progress');

export default class extends Controller {
    connect() {
        this.element.addEventListener('submit', () => {
            if (!$(this.element).valid()) {
                return;
            }

            progress.start();
        });
    }
}
