import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    /**
     * @param {Event} event
     */
    onClick(event) {
        const link = event.target;
        const codePath = link.dataset.codePath;
        const expandClass = 'expand-collapse-expanded';

        if (link.classList.contains(expandClass)) {
            this.#withChildren(codePath, (element) => {
                element.classList.add('d-none');

                element
                    .querySelectorAll('.expand-collapse')
                    .forEach((expandCollapse) =>
                        expandCollapse.classList.remove(expandClass),
                    );
            });

            link.classList.remove(expandClass);
        } else {
            this.#withChildren(codePath, (element) => {
                if (element.dataset.parentCodePath === codePath) {
                    element.classList.remove('d-none');
                }
            });

            link.classList.add(expandClass);
        }
    }

    /**
     * @param {String} codePath
     * @param {Function} callback
     */
    #withChildren(codePath, callback) {
        const selector = "[data-parent-code-path^='" + codePath + "']";

        this.element
            .closest('table')
            .querySelectorAll(selector)
            .forEach(callback);
    }
}
