import { Controller } from '@hotwired/stimulus';
const qs = require('qs');
export default class extends Controller {
    static targets = ['insuredId', 'linesOfBusiness', 'callout'];

    connect() {
        $(this.insuredIdTarget).on('change', () => {
            this.lookForDuplicates();
        });
    }

    lookForDuplicates() {
        const data = {
            insured: this.insuredIdTarget.value || null,
            linesOfBusiness: $(this.linesOfBusinessTarget).val() || [],
        };

        if (data.insured !== null && data.linesOfBusiness.length !== 0) {
            this.calloutTarget.innerHTML = `
                <div class="alert alert-secondary ml-0 mr-0 text-black-50 font-weight-bold" role="alert">
                    Finding Duplicate Applications...
                </div>
            `;

            fetch(
                '/secure/applications/clearing?' +
                    qs.stringify({
                        application_clearing: data,
                    }),
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error();
                    }

                    return response.text();
                })
                .then((html) => {
                    this.calloutTarget.innerHTML = html;
                })
                .catch(() => {
                    this.calloutTarget.innerHTML = `
                    <div class="alert alert-danger ml-0 mr-0 text-black-50 font-weight-bold" role="alert">
                        There was a problem running clearing checks!
                    </div>
                `;
                });
        } else {
            this.calloutTarget.innerHTML = `
                <div class="alert alert-dark ml-0 mr-0" role="alert">
                    Select an insured and at least one line of business for application clearing...
                </div>
            `;
        }
    }
}
