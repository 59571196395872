import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const numeral = require('numeral');
const Progress = require('../../../js/BindHQ/Utils/Progress');
const qs = require('qs');

const {
    ContractorsGuard,
} = require('../../../js/BindHQ/Program/ContractorsGuard');

export default class extends Controller {
    static targets = [
        'adjustmentAmount',
        'adjustmentCode',
        'alert',
        'baseRate',
        'classCode',
        'classificationLocation',
        'classificationLocations',
        'deniedOrCancelledCoverage',
        'excludedLocations',
        'excludedRisks',
        'exposure',
        'locationUuid',
        'minimumPremium',
        'noIneligibleLosses',
        'premium',
        'rate',
        'subcontractedWork',
        'tooLittleExperience',
        'totalPremium',
    ];

    static values = {
        homeState: String,
        ratingUrl: String,
        rateMapping: Object,
    };

    static debounces = ['rate'];

    connect() {
        useDebounce(this, { wait: 500 });

        this.contractorsGuard = new ContractorsGuard(
            'program_contractors_guard',
            $(this.element),
            this.classificationLocationsTarget,
        );

        this.rate();
    }

    rate() {
        if (!this.contractorsGuard) {
            return;
        }

        const locations = [];
        const classifications = [];

        this.classificationLocationTargets.forEach((classificationLocation) => {
            this.classCodeTargets.forEach((classCode) => {
                if (classificationLocation.contains(classCode)) {
                    const classification = classCode.closest('.classification');
                    const locationUuid = this.#findIn(
                        classificationLocation,
                        this.locationUuidTargets,
                    ).value;
                    const location = this.contractorsGuard
                        .getLocationBag()
                        .find(locationUuid);
                    const exposure = this.#findIn(
                        classification,
                        this.exposureTargets,
                    ).value;

                    if (exposure) {
                        locations.push({
                            number: location.getNumber(),
                            state: this.homeStateValue,
                            classCode: classCode.value,
                            exposure: exposure,
                            rate: this.rateMappingValue[
                                'class-code-' + classCode.value
                            ],
                        });
                    }

                    classifications.push(classification);
                }
            });
        });

        if (0 === locations.length) {
            return;
        }

        const request = {
            locations,
        };

        if (
            this.adjustmentAmountTarget.value &&
            this.adjustmentCodeTarget.value
        ) {
            request.adjustment = {
                amount: numeral(this.adjustmentAmountTarget.value)
                    .divide(100)
                    .format('0.00000'),
                code: this.adjustmentCodeTarget.value,
            };
        }

        const checkboxes = {
            tooLittleExperience: this.tooLittleExperienceTarget,
            deniedOrCancelledCoverage: this.deniedOrCancelledCoverageTarget,
            noIneligibleLosses: this.noIneligibleLossesTarget,
            excludedRisks: this.excludedRisksTarget,
            excludedLocations: this.excludedLocationsTarget,
            subcontractedWork: this.subcontractedWorkTarget,
        };

        for (const name in checkboxes) {
            if (checkboxes[name].checked) {
                request[name] = '1';
            }
        }

        Progress.start();

        fetch(this.ratingUrlValue + '?' + qs.stringify(request))
            .then((response) => response.json())
            .then((rateResponse) => {
                if ('ineligible' === rateResponse.eligibility_status) {
                    this.#ineligible(rateResponse);
                    this.#renderRates(rateResponse, classifications);
                } else {
                    this.#eligible(rateResponse);
                    this.#renderRates(rateResponse, classifications);
                }
            })
            .finally(() => Progress.stop());
    }

    classificationLocationTargetConnected() {
        this.rate();
    }

    classificationLocationTargetDisconnected() {
        this.rate();
    }

    classCodeTargetConnected() {
        this.rate();
    }

    classCodeTargetDisconnected() {
        this.rate();
    }

    #findIn(parent, elements) {
        for (const element of elements) {
            if (parent.contains(element)) {
                return element;
            }
        }

        throw new Error('Could not find element in parent...');
    }

    /**
     * @param {Object} rateResponse
     */
    #ineligible(rateResponse) {
        const ul = document.createElement('ul');

        for (const index in rateResponse.reasons) {
            const li = document.createElement('li');
            li.innerHTML = rateResponse.reasons[index];

            ul.appendChild(li);
        }

        this.alertTarget.innerHTML = '';
        this.alertTarget.appendChild(ul);
        this.alertTarget.classList.remove('d-none');
    }

    /**
     * @param {Object} rateResponse
     */
    #eligible(rateResponse) {
        this.alertTarget.classList.add('d-none');
    }

    /**
     * @param {Object} rateResponse
     * @param {Array} classifications
     */
    #renderRates(rateResponse, classifications) {
        this.premiumTargets
            .concat(this.baseRateTargets)
            .concat(this.rateTargets)
            .forEach((element) => (element.innerHTML = '~'));

        this.minimumPremiumTarget.innerHTML = this.#formatCurrency(
            rateResponse.minimum_premium.amount,
        );

        this.totalPremiumTarget.innerHTML = this.#formatCurrency(
            rateResponse.premium.amount,
        );

        for (let i = 0; i < rateResponse.locations.length; i++) {
            const location = rateResponse.locations[i];
            const classification = classifications[i];

            this.premiumTargets.forEach((premium) => {
                if (classification.contains(premium)) {
                    premium.innerHTML = this.#formatCurrency(
                        location.premium.amount,
                    );
                }
            });

            this.baseRateTargets.forEach((baseRate) => {
                if (classification.contains(baseRate)) {
                    baseRate.innerHTML = location.base_rate;
                }
            });

            this.rateTargets.forEach((rate) => {
                if (classification.contains(rate)) {
                    rate.innerHTML = location.rate;
                }
            });
        }
    }

    #formatCurrency(amount) {
        return '$' + numeral(amount).format('0,00.00');
    }
}
