const TriaTogglerEvents = {
    CHANGED: 'bindhq.quote.tria_toggler.changed',
    KEYUP: 'bindhq.quote.tria_toggler.keyup',
};

const Observable = require('../Observable');

class TriaToggler extends Observable {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        super();

        this.container = container;
    }

    init() {
        this.container.on('change', '.tria-included', () =>
            super.fire(TriaTogglerEvents.CHANGED),
        );

        this.container.on('keyup', '.tria-premium', () =>
            super.fire(TriaTogglerEvents.KEYUP),
        );
    }

    render(xhr) {
        const html = $(xhr.responseText);
        const oldContainer = this.container.findOne('.tria-premium-container');
        const newContainer = html.findOne('.tria-premium-container');
        const triaPremium = oldContainer.findOne('.tria-premium').get(0);

        let selectionStart = null;
        let selectionEnd = null;

        if (triaPremium === document.activeElement) {
            selectionStart = triaPremium.selectionStart;
            selectionEnd = triaPremium.selectionEnd;
        }

        oldContainer.replaceWith(newContainer);

        if (null !== selectionStart) {
            const newTriaPremium = newContainer
                .find('.tria-premium')
                .focus()
                .get(0);

            setTimeout(() => {
                newTriaPremium.selectionStart = selectionStart;
                newTriaPremium.selectionEnd = selectionEnd;
            }, 10);
        }
    }
}

module.exports = { TriaToggler, TriaTogglerEvents };
