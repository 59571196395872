const Observable = require('../Observable');

const SubLocationEvents = {
    CHANGED: 'bindhq.models.sub_location.changed',
};

class SubLocation extends Observable {
    /**
     * @param {HTMLElement} element
     * @param {SubLocationBag} subLocationBag
     */
    constructor(element, subLocationBag) {
        super();

        this.element = element;
        this.number = element.querySelector('.sub-location-number');
        this.collapsedNumber = element.querySelector(
            '.collapsed-sub-location-number',
        );

        this.subLocationBag = subLocationBag;
        this.subLocationBag.add(this);

        this.anchor = 'sub-location-' + bindhq.util.uuid4();
    }

    /**
     *
     */
    init() {
        this.element.setAttribute('id', this.anchor);

        if (!this.getNumber()) {
            const nextNumber = this.subLocationBag.getNextNumber();

            this.number.value = nextNumber;
            this.collapsedNumber.innerHTML = nextNumber;
        }

        this.number.addEventListener('change', () => {
            this.collapsedNumber.innerHTML = this.number.value;

            super.fire(SubLocationEvents.CHANGED);
        });
    }

    /**
     * @return {null|String}
     */
    getNumber() {
        const numberString = this.number.value;

        return numberString ? parseInt(numberString, 10) : null;
    }

    /**
     * @return {String}
     */
    getAnchor() {
        return this.anchor;
    }
}

module.exports = { SubLocation, SubLocationEvents };
