const ManualTaxesTogglerEvents = {
    CHANGED: 'bindhq.quote.manual_taxes_toggler.changed',
};

const Observable = require('../Observable');

class ManualTaxesToggler extends Observable {
    /**
     * @param {jQuery} container
     * @param {CollectionIndexer} collectionIndexer
     */
    constructor(container, collectionIndexer) {
        super();

        this.collectionIndexer = collectionIndexer;

        this.lineItemContainer = container.findOne('.line-item-container');
        this.checkbox = container.findOne('.manual-taxes');
        this.taxTypes = container.data('tax-types').split(',');
    }

    init() {
        this.checkbox.on('change', () => this.onChange());
    }

    onChange() {
        const isChecked = this.checkbox.is(':checked');

        this.lineItemContainer
            .find('.collection-item')
            .each((i, collectionElement) => {
                const item = $(collectionElement);
                const type = item.findOne('select.line-item-type');
                const value = type.get(0).tomselect.getValue();

                if (isChecked) {
                    if (this.#isTax(value)) {
                        item.removeClass('read-only-collection-item');
                        item.find('select').each((i, select) =>
                            select.tomselect.enable(),
                        );
                        item.find('.close.d-none').removeClass('d-none');
                        item.find(':disabled').prop('disabled', false);
                        item.find('[data-name]').each((j, inputElement) => {
                            $(inputElement).attr(
                                'name',
                                $(inputElement).data('name'),
                            );
                        });
                    }
                } else if (this.#isTax(value)) {
                    item.remove();
                }

                this.#updateOptions(type, isChecked);
            });

        this.collectionIndexer.reindex(this.lineItemContainer);

        super.fire(ManualTaxesTogglerEvents.CHANGED);
    }

    #isTax(value) {
        return -1 !== this.taxTypes.indexOf(value);
    }

    #updateOptions(type, isChecked) {
        const tomselect = type.get(0).tomselect;

        type.find('option').each((i, option) => {
            if (this.#isTax(option.value)) {
                option.disabled = !isChecked;

                tomselect.updateOption(option.value, {
                    value: option.value,
                    text: option.innerHTML,
                    disabled: option.disabled,
                });
            }
        });
    }
}

module.exports = { ManualTaxesToggler, ManualTaxesTogglerEvents };
