import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['email', 'submit', 'error'];

    static values = {
        clickedSubmit: { type: Boolean, default: false },
    };

    connect() {
        this.#update();
    }

    onChange() {
        this.#update();
        this.#updateError();
    }

    onSubmitClick(event) {
        this.#error(event);
    }

    #error(event) {
        if (this.submitTarget.dataset.disabled == 'true') {
            this.emailTarget.classList.add('error');
            this.errorTarget.classList.add('d-block');
            this.clickedSubmitValue = true;

            event.preventDefault();
            event.stopPropagation();
        }
    }

    #updateError() {
        if (this.clickedSubmitValue) {
            this.emailTarget.classList.toggle(
                'error',
                this.#getValidEmailInInput(),
            );
            this.errorTarget.classList.toggle(
                'd-block',
                this.#getValidEmailInInput(),
            );
        }
    }

    #update() {
        this.submitTarget.dataset.disabled = this.#getValidEmailInInput();
    }

    #getValidEmailInInput() {
        return (
            !this.emailTarget.value ||
            !this.emailTarget.value.match(/.+@.+\..+/)
        );
    }
}
