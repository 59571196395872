import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox', 'input'];

    connect() {
        this.#update();
    }

    onToggle() {
        this.#update();
    }

    #update() {
        if (this.checkboxTarget.checked) {
            this.inputTarget.classList.remove('invisible');
        } else {
            this.inputTarget.classList.add('invisible');
        }
    }
}
