import { Controller } from '@hotwired/stimulus';
const { DateTime } = require('luxon');

export default class extends Controller {
    static targets = ['effectiveDate'];

    static values = {
        minEffectiveDate: Number,
        maxEffectiveDate: Number,
    };

    validateEffectiveDate() {
        const effectiveDate = DateTime.fromISO(
            this.effectiveDateTarget.value,
        ).startOf('day');

        const min = DateTime.now()
            .minus({ days: this.minEffectiveDateValue })
            .startOf('day');

        const max = DateTime.now()
            .plus({ days: this.maxEffectiveDateValue })
            .startOf('day');

        this.#removeMessages(this.effectiveDateTarget, 'warning');

        if (effectiveDate < min || effectiveDate > max) {
            this.#displayMessage(
                this.effectiveDateTarget,
                `The selected date is outside of Standard Underwriting Guidelines. Entering -${this.minEffectiveDateValue} to +${this.maxEffectiveDateValue} days is recommended.`,
                'warning',
            );
        }
    }

    #displayMessage(element, message, level = 'error') {
        const error = this.#createMessageElement(message, level);

        element.parentNode.append(error);
        element.classList.add(`${level}-message`);
    }

    #removeMessages(element, level = 'error') {
        element.parentNode
            .querySelectorAll('.builders-guard-form-error')
            .forEach((error) => error.remove());

        element.classList.remove(`${level}-message`);
    }

    #createMessageElement(message, type = 'error') {
        const element = document.createElement('span');
        element.innerText = message;
        element.classList.add(
            `text-${type}`,
            'builders-guard-form-error',
            'd-block',
            'mt-1',
        );

        return element;
    }
}
