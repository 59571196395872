class DiligentEffortToggler {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        this.radioGroup = container.findOne('.skip-diligent-effort');
        this.controlContainer = container.findOne('.diligent-effort-controls');
    }

    /**
     *
     */
    init() {
        this.radioGroup
            .find('input[type="radio"]')
            .on('change', () => this.refresh());

        this.refresh();
    }

    /**
     *
     */
    refresh() {
        const skipClass = 'diligent-effort-skipped';
        const isSkipped = '1' === this.radioGroup.find('input:checked').val();

        if (isSkipped) {
            this.controlContainer.addClass(skipClass);
        } else {
            this.controlContainer.removeClass(skipClass);
        }
    }
}

module.exports = { DiligentEffortToggler };
