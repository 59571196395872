import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['ifAny', 'exposure', 'exposureDiv'];

    connect() {
        this.#changed();
    }

    onChange(event) {
        this.#changed(event);
    }

    /**
     * @param {null|Event} evt
     */
    #changed(event) {
        const isChecked = this.ifAnyTarget.checked;

        // Only fire change on user interaction
        if (event) {
            this.exposureTarget.dispatchEvent(new Event('change'));
        }

        if (isChecked) {
            this.exposureTarget.value = '';
            this.exposureDivTarget.classList.add('d-none');
        } else {
            this.exposureDivTarget.classList.remove('d-none');
        }
    }
}
