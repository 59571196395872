const Observable = require('../Observable');

const { Address } = require('../Models/Address');
const { GeoPoint } = require('../Models/GeoPoint');
const { Location } = require('../Models/Location');
const { FieldSet } = require('../Utils/FieldSet');

const LocationBagEvents = {
    CHANGED: 'bindhq.utils.location_bag.changed',
};

class LocationBag extends Observable {
    /** @param {jQuery} container */
    constructor(container, formName) {
        super();

        this.locationsContainer = container.findOne('.locations');
        this.items = [];
        this.fieldSet = new FieldSet(
            this.locationsContainer,
            formName + '[locations][{index}]{name}',
        );
    }

    /**
     * @param {String} uuid
     *
     * @return {Location}
     */
    find(uuid) {
        return this.items.find(function (location) {
            return location.getUuid() === uuid;
        });
    }

    /** @return {Array} */
    getAll() {
        return this.items;
    }

    /** @return {Integer} */
    getNextNumber() {
        let nextNumber = 1;

        _.each(this.items, function (item) {
            const number = item.getNumber();

            if (number >= nextNumber) {
                nextNumber = number + 1;
            }
        });

        return nextNumber;
    }

    /** @param {Location} location */
    add(location) {
        if (undefined !== this.find(location.getUuid())) {
            return;
        }

        this.items.push(location);
        location.subscribe('locationUpdated', () => {
            this.flush();
        });

        this.flush();
        this.#fireChange();

        location.subscribe('locationUpdated', () => this.#fireChange());
    }

    flush() {
        this.locationsContainer.empty();
        this.items.forEach((item, index) => {
            const address = item.getAddress();
            const geoPoint = item.getGeoPoint();

            this.fieldSet.add('[uuid]', item.getUuid(), index);
            this.fieldSet.add('[number]', item.getNumber(), index);
            this.fieldSet.add('[address][line1]', address.getLine1(), index);
            this.fieldSet.add('[address][line2]', address.getLine2(), index);
            this.fieldSet.add('[address][city]', address.getCity(), index);
            this.fieldSet.add('[address][state]', address.getState(), index);
            this.fieldSet.add(
                '[address][internationalState]',
                address.getInternationalState(),
                index,
            );
            this.fieldSet.add(
                '[address][postalCode]',
                address.getPostalCode(),
                index,
            );
            this.fieldSet.add('[address][county]', address.getCounty(), index);
            this.fieldSet.add(
                '[address][countyFips]',
                address.getCountyFips(),
                index,
            );

            if (geoPoint) {
                this.fieldSet.add(
                    '[geoPoint][latitude]',
                    geoPoint.getLatitude(),
                    index,
                );
                this.fieldSet.add(
                    '[geoPoint][longitude]',
                    geoPoint.getLongitude(),
                    index,
                );
            }
        });
    }

    init() {
        window.locationBag = this;

        this.locationsContainer
            .find('.location')
            .map(function () {
                const address = new Address(
                    $('.location-address .line1', this).val(),
                    $('.location-address .line2', this).val(),
                    $('.location-address .city', this).val(),
                    $('.location-address .state', this).val(),
                    $('.location-address .international-state', this).val(),
                    $('.location-address .postal-code', this).val(),
                    $('.location-address .county', this).val(),
                    $('.location-address .county-fips', this).val(),
                );

                let geoPoint = null;

                const latitude = $('.location-point .latitude', this).val();
                const longitude = $('.location-point .longitude', this).val();

                if (latitude && longitude) {
                    geoPoint = new GeoPoint(
                        parseFloat(latitude),
                        parseFloat(longitude),
                    );
                }

                const uuid = $('.location-uuid', this).val();
                const number = parseInt($('.location-number', this).val(), 10);

                return new Location(uuid, number, address, geoPoint);
            })
            .toArray()
            .forEach((location) => {
                this.add(location);
            });
    }

    #fireChange() {
        this.fire(LocationBagEvents.CHANGED);
        window.dispatchEvent(new CustomEvent('location_bag_changed'));
    }
}

module.exports = { LocationBag, LocationBagEvents };
