/**
 * @typedef {Object.<validate: Function, message: string>} ValidationRule
 */

/**
 *
 * @typedef {Object.<key:string, Array<ValidationRule>>} ValidationRules
 */

/**
 *
 * @param {Object} properties
 * @param {ValidationRules} validationRules
 * @returns {null}
 */
const validate = (properties, validationRules) => {
    const errors = {};

    for (const prop of Object.keys(validationRules)) {
        const rules = validationRules[prop];
        if (rules && rules.length) {
            for (const rule of rules) {
                if (!rule.validate) {
                    throw new Error(
                        `validate function is required for property  ${prop}`,
                    );
                }
                if (!rule.validate(properties[prop])) {
                    errors[prop]
                        ? errors[prop].push(rule.message)
                        : (errors[prop] = [rule.message]);
                }
            }
        }
    }

    return Object.entries(errors).length ? errors : null;
};

module.exports = { validate };
