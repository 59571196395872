import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
    static targets = ['input', 'subjectivity'];

    static debounces = ['onChange'];

    connect() {
        useDebounce(this, { wait: 500 });
    }

    onChange() {
        const query = this.inputTarget.value.toLowerCase();

        this.subjectivityTargets.forEach((element) => {
            const text = _.map(
                element.querySelectorAll('input[type="text"]'),
                (input) => input.value.toLowerCase(),
            ).join(' ');

            if ('' === query || -1 !== text.indexOf(query)) {
                element.classList.remove('d-none');
            } else {
                element.classList.add('d-none');
            }
        });
    }
}
