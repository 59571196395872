import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox', 'address'];

    connect() {
        this.addressTarget.querySelectorAll('[required]').forEach((element) => {
            element.setAttribute('required-disabled', '');
            element.required = false;
        });

        this.#update();
    }

    onChange() {
        this.#update();
    }

    #update() {
        this.addressTarget.classList.toggle(
            'd-none',
            this.checkboxTarget.checked,
        );
        this.addressTarget
            .querySelectorAll('[required-disabled]')
            .forEach((element) => {
                element.required = !this.checkboxTarget.checked;
            });
    }
}
