import { Controller } from '@hotwired/stimulus';

const { AjaxSubmitHandler } = require('../../js/BindHQ/Form/AjaxSubmitHandler');

export default class extends Controller {
    connect() {
        this.ajaxSubmitHandler = new AjaxSubmitHandler($(this.element));
    }

    onSubmit(evt) {
        this.ajaxSubmitHandler.onSubmit(evt);
    }

    /**
     * @param {Event} evt
     */
    onClick(evt) {
        this.ajaxSubmitHandler.onClick(evt);
    }

    /**
     * @param {Event} evt
     */
    onNavigate(evt) {
        this.ajaxSubmitHandler.onNavigate(evt);
    }

    /**
     * @param {Event} evt
     */
    onCancel(evt) {
        this.ajaxSubmitHandler.onCancel(evt);
    }
}
