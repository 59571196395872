import { Controller } from '@hotwired/stimulus';

const { DateTime } = require('luxon');

export default class extends Controller {
    static targets = ['effective', 'expiration'];

    onChange() {
        this.expirationTarget.value = DateTime.fromISO(
            this.effectiveTarget.value,
        )
            .plus({ years: 1 })
            .toISODate();
    }
}
