import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['container'];

    close(e) {
        e.preventDefault();

        this.#hide(this.containerTarget);
    }

    #hide(element) {
        element.style.transition = 'opacity 300ms';
        element.style.opacity = '0';

        setTimeout(() => element.remove(), 300);
    }
}
