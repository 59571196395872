class FieldSet {
    /**
     *
     * @param {jQuery} container
     * @param {string} inputNamePattern
     */
    constructor(container, inputNamePattern) {
        this.container = container;
        this.inputNamePattern = inputNamePattern;
        this.validateInputNamePattern();
    }

    validateInputNamePattern() {
        if (!this.inputNamePattern) {
            throw new Error('inputNamePattern cannot be empty');
        }
        if (
            !this.inputNamePattern.includes('{index}') ||
            !this.inputNamePattern.includes('{name}')
        ) {
            throw new Error(
                'inputNamePattern must contain {index} and {name} keys',
            );
        }
    }

    /**
     *
     * @param {string} name
     * @param {string} value
     * @param {string} index
     */
    add(name, value, index) {
        const input = $('<input type="hidden" />');
        input.attr(
            'name',
            this.inputNamePattern
                .replace(/{index}/, index)
                .replace(/{name}/, name),
        );
        input.val(value);

        this.container.append(input);
    }
}

module.exports = { FieldSet };
