module.exports = {
    /**
     * @param {String} name
     *
     * @returns {String}
     */
    toTableName: function (name) {
        return name
            .toLowerCase()
            .replace(/.\w+$/, '')
            .replace(/\s+/g, '_')
            .replace(/[^a-z0-9_]/g, '')
            .replace(/^[0-9_]+/g, '');
    },
};
