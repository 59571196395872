import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['name', 'preview'];

    connect() {
        this.#update();
    }

    onChange(event) {
        this.#update();
    }

    #update() {
        if (this.nameTarget.value === '') {
            this.previewTarget.innerHTML = 'Value Name = Calculation';
        } else {
            this.previewTarget.innerHTML = this.nameTarget.value;
        }
    }
}
