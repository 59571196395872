import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

const { CollectionIndexer } = require('../js/BindHQ/Form/CollectionIndexer');

export default class extends Controller {
    static values = {
        parentSelector: String,
        handle: {
            type: String,
            default: '.move-handle',
        },
        reindexSelector: String,
    };

    connect() {
        const sortableParent = this.parentSelectorValue
            ? this.element.querySelector(this.parentSelectorValue)
            : this.element;

        Sortable.create(sortableParent, {
            handle: this.handleValue,
            ghostClass: 'sortable-dragging',
            forceFallback: true,
            onEnd: (event) => this.#onEndSort(event),
        });

        this.#changeItems();

        const mutationConfig = { childList: true, subtree: true };
        const mutationObserver = new MutationObserver(
            this.#changeItems.bind(this),
        );
        mutationObserver.observe(this.element, mutationConfig);
    }

    #changeItems() {
        const items = document.querySelectorAll(this.reindexSelectorValue);
        items.forEach((item) => {
            item.classList.add('sortable-item');
        });
    }

    #onEndSort(event) {
        if (this.reindexSelectorValue) {
            const reindexer = new CollectionIndexer();
            reindexer.reindex($(this.element), this.reindexSelectorValue);
        }
    }
}
