import { Controller } from '@hotwired/stimulus';

const {
    GeneralLiability,
} = require('../../js/BindHQ/Product/GeneralLiability');

const {
    LimitQuickSelect,
} = require('../../js/BindHQ/Product/GeneralLiability/LimitQuickSelect');

export default class extends Controller {
    static targets = ['additionalCoverages', 'classificationLocations'];

    connect() {
        const container = $(this.element);
        const generalLiability = new GeneralLiability(
            'product_general_liability',
            container,
            this.classificationLocationsTarget,
            this.additionalCoveragesTarget,
        );

        const limitQuickSelect = new LimitQuickSelect(
            container.findOne('.quote-limits'),
        );
        limitQuickSelect.init();
    }
}
