import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
    static targets = [
        'andCondition',
        'comparison',
        'condition',
        'eligibility',
        'modal',
        'orCondition',
        'orConditionForm',
        'outcome',
        'rule',
        'source',
        'value',
    ];

    static values = {
        addModalTitle: String,
        editModalTitle: String,
        hideOnClickOutside: Boolean,
    };

    connect() {
        this.newRuleClass = 'new-eligibility-rule';
        this.#initModalProperties();
        this.#updateRules();
    }

    onSaveClicked() {
        if (!this.#validateModal()) {
            return;
        }

        this.#updateRules();
        this.#hideModal();
    }

    onCancelClicked(event) {
        this.modalRulesAdded.forEach((rule) => {
            rule.remove();
        });

        const element = this.modal._element;

        if (element.classList.contains(this.newRuleClass)) {
            element.closest('.collection-item').remove();
        } else {
            this.#setValue(
                this.#find(element, this.outcomeTargets),
                this.modalOutcomeValue,
            );

            this.modalConditionValues.forEach(
                ([condition, source, comparison, value, valueText]) => {
                    const sourceElement = this.#find(
                        condition,
                        this.sourceTargets,
                    );

                    this.#setValue(sourceElement, source);

                    this.#setValue(
                        this.#find(condition, this.comparisonTargets),
                        comparison,
                    );

                    const valueElement = this.#find(
                        condition,
                        this.valueTargets,
                    );

                    if (valueElement.tomselect) {
                        const sourceOption =
                            sourceElement.options[sourceElement.selectedIndex];

                        const option = {};
                        option[sourceOption.dataset.urlValueName] = value;
                        option[sourceOption.dataset.urlValueLabel] = valueText;

                        const ts = valueElement.tomselect;
                        ts.clear(true);
                        ts.clearOptions();
                        ts.addOption(option);
                        ts.setValue(value);
                    } else {
                        valueElement.value = value;
                    }
                },
            );
        }

        this.#hideModal();
    }

    onAddClicked(event) {
        const element = event.detail.args.newElement;

        if (!element.classList.contains('or-condition')) {
            return;
        }

        this.#showModal(element, true);
    }

    onEditClicked(event) {
        this.#showModal(event.target.closest('.collection-item'), false);
    }

    onRuleAdded(event) {
        this.modalRulesAdded.push(event.detail.args.newElement);
    }

    #validateModal() {
        let valid = true;

        this.#modalFields().forEach((field) => {
            if (field.disabled) {
                return;
            }

            $(field).valid();

            if (!field.validity.valid) {
                valid = false;
            }
        });

        return valid;
    }

    #initModalProperties() {
        this.modal = null;
        this.modalRulesAdded = [];
        this.modalConditionValues = [];
        this.modalOutcomeValue = null;
    }

    #showModal(parentElement, isNewRule) {
        const element = this.#find(parentElement, this.modalTargets);

        element.querySelector('.modal-title').innerHTML = isNewRule
            ? this.addModalTitleValue
            : this.editModalTitleValue;

        $(element).on('hidden.bs.modal', (event) => {
            if (event.currentTarget.classList.contains(this.newRuleClass)) {
                this.onCancelClicked(event);
            }
        });

        this.modal = new Modal(element, {
            backdrop: this.hideOnClickOutsideValue ? true : 'static',
            keyboard: false,
        });

        if (isNewRule) {
            element.classList.add(this.newRuleClass);
        } else {
            this.#findAll(element, this.conditionTargets).forEach(
                (condition) => {
                    const valueElement = this.#find(
                        condition,
                        this.valueTargets,
                    );
                    const value = this.#getValue(valueElement);
                    const valueOption = valueElement.tomselect
                        ? valueElement.tomselect.getOption(value)
                        : null;
                    const valueText =
                        value && valueOption
                            ? valueOption.innerHTML
                            : valueElement.dataset.initial;

                    this.modalConditionValues.push([
                        condition,
                        this.#findValue(condition, this.sourceTargets),
                        this.#findValue(condition, this.comparisonTargets),
                        value,
                        valueText,
                    ]);
                },
            );

            this.modalOutcomeValue = this.#findValue(
                element,
                this.outcomeTargets,
            );
        }

        this.modal.show();
    }

    #hideModal() {
        this.modal._element.classList.remove(this.newRuleClass);
        this.modal.hide();

        this.#initModalProperties();
    }

    #updateRules() {
        this.orConditionTargets.forEach((orCondition) => {
            const andConditions = [];

            this.#findAll(orCondition, this.andConditionTargets).forEach(
                (andCondition) => {
                    const conditions = [];

                    this.#findAll(andCondition, this.conditionTargets).forEach(
                        (condition) => {
                            const sourceElement = this.#find(
                                condition,
                                this.sourceTargets,
                            );
                            const comparisonElement = this.#find(
                                condition,
                                this.comparisonTargets,
                            );
                            const valueElement = this.#find(
                                condition,
                                this.valueTargets,
                            );

                            conditions.push(
                                this.#readable(sourceElement) +
                                    ' ' +
                                    this.#readable(comparisonElement) +
                                    ' ' +
                                    this.#enumeratedValue(valueElement),
                            );
                        },
                    );

                    andConditions.push(conditions.join(' <b>AND</b> '));
                },
            );

            this.#find(orCondition, this.ruleTargets).innerHTML =
                andConditions.join(' <b>OR</b> ');

            const outcome = this.#find(orCondition, this.outcomeTargets);

            this.#find(orCondition, this.eligibilityTargets).innerHTML =
                outcome.options[outcome.selectedIndex].text;
        });
    }

    #findAll(element, targets) {
        return targets.filter((target) => {
            return element.contains(target);
        });
    }

    #find(element, targets) {
        return targets.find((target) => {
            return element.contains(target);
        });
    }

    #findValue(element, targets) {
        return this.#getValue(this.#find(element, targets));
    }

    #readable(element) {
        if ('SELECT' === element.tagName) {
            return element.options[element.selectedIndex].text;
        }

        return element.value;
    }

    /**
     * @param {HTMLElement} valueElement
     *
     * @returns {String}
     */
    #enumeratedValue(valueElement) {
        if (valueElement.tomselect) {
            const value = valueElement.tomselect.getValue();
            const option = valueElement.tomselect.getOption(value);

            return option ? option.innerHTML : '';
        }

        return valueElement.value || '';
    }

    #modalFields() {
        const element = this.modal._element;

        return [].concat(
            this.#findAll(element, this.sourceTargets),
            this.#findAll(element, this.comparisonTargets),
            this.#findAll(element, this.valueTargets),
            this.#findAll(element, this.outcomeTargets),
        );
    }

    #setValue(field, value) {
        if (field.tomselect) {
            field.tomselect.setValue(value);
        } else {
            field.value = value;
        }
    }

    #getValue(field) {
        if (field.tomselect) {
            return field.tomselect.getValue();
        } else {
            return field.value;
        }
    }
}
