/**
 * @param {HTMLElement} element
 *
 * @return {string|object}
 */
const extractValue = (element) => {
    const $element = $(element);

    if ($element.hasClass('select2') || $element.hasClass('select2-ajax')) {
        return $element.select2('data');
    }

    return element.dataset.value || $element.val();
};

module.exports = { extractValue };
