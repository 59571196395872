import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    static values = {
        teamIdentifier: String,
        teamLabel: String,
        url: String,
        userIdentifier: String,
        userLabel: String,
        name: String,
    };

    connect() {
        const tomselect = new TomSelect(this.element, {
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            optgroups: [
                { value: this.userIdentifierValue, label: this.userLabelValue },
                { value: this.teamIdentifierValue, label: this.teamLabelValue },
            ],
            maxItems: 1,
            preload: true,
            plugins: {
                clear_button: {},
            },
            load: (query, callback) => {
                const url = new URL(
                    location.protocol + '//' + location.host + this.urlValue,
                );
                url.searchParams.append('name', query);

                fetch(url)
                    .then((response) => response.json())
                    .then((json) => {
                        const results = [];

                        json.users.forEach((user) => {
                            results.push({
                                id: this.userIdentifierValue + ':' + user.id,
                                name: user.name,
                                optgroup: this.userIdentifierValue,
                            });
                        });

                        json.teams.forEach((team) => {
                            results.push({
                                id: this.teamIdentifierValue + ':' + team.id,
                                name: team.name,
                                optgroup: this.teamIdentifierValue,
                            });
                        });

                        callback(results);
                    })
                    .catch(() => {
                        callback();
                    });
            },
        });

        if (this.element.value) {
            const value = this.element.value;
            const option = {
                id: value,
                name: this.nameValue,
                optgroup: value.split(':')[0],
            };

            tomselect.clear(true);
            tomselect.clearOptions();
            tomselect.addOption(option);
            tomselect.addItem(value);
        }
    }
}
