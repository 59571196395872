const { DateTime } = require('luxon');

class OutOfSequenceWarner {
    /**
     * @param {HTMLElement} effectiveDateInput
     * @param {HTMLElement} warningDiv
     * @param {String|null} latestEffectiveDate
     */
    constructor(effectiveDateInput, warningDiv, latestEffectiveDate) {
        this.effectiveDateInput = effectiveDateInput;
        this.warningDiv = warningDiv;
        this.latestEffectiveDate = null;
        this.debounce = _.debounce(() => this.refresh(), 1000);

        if (latestEffectiveDate) {
            this.latestEffectiveDate = DateTime.fromISO(latestEffectiveDate);
        }
    }

    /**
     *
     */
    onChange() {
        this.debounce();
    }

    /**
     *
     */
    refresh() {
        if (
            null !== this.latestEffectiveDate &&
            this.effectiveDateInput.value &&
            DateTime.fromISO(this.effectiveDateInput.value).startOf('day') <
                this.latestEffectiveDate.startOf('day')
        ) {
            this.warningDiv.classList.remove('d-none');
        } else {
            this.warningDiv.classList.add('d-none');
        }
    }
}

module.exports = { OutOfSequenceWarner };
