import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'address',
        'county',
        'country',
        'state',
        'internationalState',
    ];

    connect() {
        this.#update();
    }

    onChange() {
        this.#update();
    }

    #update() {
        const isInternationalAddress =
            '1' === this.addressTarget.querySelector('input:checked').value;

        if (isInternationalAddress) {
            this.countyTarget.classList.add('d-none');
            this.stateTarget.classList.add('d-none');
            this.countryTarget.classList.remove('d-none');
            this.internationalStateTarget.classList.remove('d-none');
        } else {
            this.countyTarget.classList.remove('d-none');
            this.stateTarget.classList.remove('d-none');
            this.countryTarget.classList.add('d-none');
            this.internationalStateTarget.classList.add('d-none');
        }
    }
}
