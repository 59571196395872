import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'stateDropdown',
        'companyNumberInput',
        'admittedCheckbox',
        'addStateTrigger',
        'allAdmittedCheckboxToggle',
        'collectionContainer',
    ];

    dataPrototype = null;

    dataPrototypeElement = null;

    stateOptions = null;

    currentIndex = null;

    connect() {
        this.#initialise();

        this.#updateAllAdmittedCheckboxToggleText();
    }

    addAllStates() {
        const currentStateFieldsValues = this.#getCurrentStateFieldsValues();

        this.#removeAllFields();
        this.currentIndex = 0;

        Object.keys(this.stateOptions)
            .sort((firstStateOption, secondStateOption) =>
                this.stateOptions[firstStateOption].localeCompare(
                    this.stateOptions[secondStateOption],
                ),
            )
            .forEach((stateValue) => {
                this.#addStateField(stateValue, currentStateFieldsValues);
                this.currentIndex++;
            });

        this.#updateAllAdmittedCheckboxToggleText();
    }

    toggleAllAdmittedCheckbox() {
        if (this.stateDropdownTargets.length === 0) {
            return;
        }

        const toggleState = this.#hasACheckBoxUnSelected();

        this.admittedCheckboxTargets.forEach((admittedCheckboxTarget) => {
            admittedCheckboxTarget.checked = toggleState;
        });

        this.#updateAllAdmittedCheckboxToggleText();
    }

    admittedCheckboxToggled() {
        this.#updateAllAdmittedCheckboxToggleText();
    }

    #initialise() {
        $(this.element).on(
            'bindhq.forms.collection.inline.blank_item_added',
            () => this.#updateAllAdmittedCheckboxToggleText(),
        );

        $(this.element).on('bindhq.forms.collection.inline.item_removed', () =>
            this.#updateAllAdmittedCheckboxToggleText(),
        );

        this.dataPrototype =
            this.collectionContainerTarget.getAttribute('data-prototype');

        this.dataPrototypeElement = this.#makeDOMPrototype();

        this.stateOptions = this.#extractStateOptions([
            ...this.dataPrototypeElement.querySelectorAll('select option'),
        ]);
    }

    #extractStateOptions(stateOptionsElements) {
        return stateOptionsElements.reduce((acc, optionElement) => {
            acc[optionElement.value] = optionElement.innerHTML;
            return acc;
        }, {});
    }

    #removeAllFields() {
        this.element
            .querySelectorAll('.collection-item')
            .forEach((stateField) => {
                stateField.remove();
            });
    }

    #getCurrentStateFieldsValues() {
        return this.stateDropdownTargets.reduce(
            (acc, stateDropdownTarget, index) => {
                acc[stateDropdownTarget.value] = {
                    companyNumber: this.companyNumberInputTargets[index].value,
                    admitted: this.admittedCheckboxTargets[index].checked,
                };
                return acc;
            },
            {},
        );
    }

    #addStateField(stateOption, existingStateFieldValues) {
        const prototype = this.#makeDOMPrototype();

        prototype.querySelector('select[id*="state"]').value = stateOption;

        if (existingStateFieldValues[stateOption]) {
            prototype.querySelector('input[id*="company_number"]').value =
                existingStateFieldValues[stateOption].companyNumber;

            prototype.querySelector('input[id*="admitted"]').checked =
                existingStateFieldValues[stateOption].admitted;
        }

        this.collectionContainerTarget.appendChild(prototype);

        this.collectionContainerTarget.dispatchEvent(
            new Event('bindhq.forms.collection.inline.blank_item_added', {
                bubbles: true,
            }),
        );
    }

    #makeDOMPrototype() {
        const wrapper = document.createElement('div');

        wrapper.innerHTML = this.dataPrototype.replace(
            /__name__/g,
            this.currentIndex,
        );

        return wrapper.firstElementChild;
    }

    #updateAllAdmittedCheckboxToggleText() {
        if (this.stateDropdownTargets.length === 0) {
            this.allAdmittedCheckboxToggleTarget.innerText = 'Select all';

            return;
        }

        this.allAdmittedCheckboxToggleTarget.innerText =
            this.#hasACheckBoxUnSelected() ? 'Select all' : 'Deselect all';
    }

    #hasACheckBoxUnSelected() {
        return this.admittedCheckboxTargets.some(
            (admittedCheckboxTarget) => !admittedCheckboxTarget.checked,
        );
    }
}
