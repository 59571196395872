import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        codePath: String,
        url: String,
    };

    static targets = ['expand', 'collapse'];

    connect() {
        this.children = null;
    }

    onExpand() {
        this.element.classList.add('expanded');

        if (null === this.children) {
            this.children = [];

            const loading = document.createElement('tr');
            loading.innerHTML =
                '<td colspan="999" class="p-3"><i class="fas fa-spinner"></i> Loading...</td>';

            this.element.after(loading);

            fetch(this.urlValue)
                .then((response) => response.text())
                .then((html) => {
                    loading.remove();

                    const nodes = document.createElement('table');
                    nodes.innerHTML = html;

                    nodes.querySelectorAll('tr').forEach((tr) => {
                        this.element.after(tr);
                        this.children.push(tr);
                    });
                });
        } else {
            this.children.forEach((tr) => tr.classList.remove('d-none'));
        }
    }

    onCollapse() {
        this.element.classList.remove('expanded');
        this.element.parentNode
            .querySelectorAll("[id^='cost-centre-" + this.codePathValue + "']")
            .forEach((tr) => {
                if (tr === this.element) {
                    return;
                }

                tr.classList.remove('expanded');
                tr.classList.add('d-none');
            });
    }
}
