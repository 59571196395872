class LimitQuickSelect {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        this.container = container;
    }

    /**
     *
     */
    init() {
        this.container.find('.quote-limits-selectors').each((i, element) => {
            $(element)
                .find('a')
                .on('click', (evt) => this.onButtonClick(evt));
        });
    }

    /**
     * @param {Event} evt
     */
    onButtonClick(evt) {
        evt.preventDefault();

        const button = $(evt.target);
        const limits = button.data('limits');

        this.applyLimits(limits);
    }

    /**
     * @param {Object} limits
     */
    applyLimits(limits) {
        const controls = this.container.findOne('.quote-limits-controls');

        _.each(limits, (value, limit) => {
            const control = controls.findOne('select.limit-' + limit);

            control.get(0).tomselect.setValue(value);
        });
    }
}

module.exports = { LimitQuickSelect };
