const Observable = require('../Observable');

const { CollectionEvents } = require('./Collection');

const LineItemRegeneratorEvents = {
    CHANGED: 'bindhq.form.line_item_regenerator.changed',
    KEYUP: 'bindhq.form.line_item_regenerator.keyup',
};

class LineItemRegenerator extends Observable {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        super();

        this.container = container;
        this.lineItemContainer = container.findOne('.line-item-container');
    }

    init() {
        this.lineItemContainer.on('change', '.line-item-type', () =>
            this.fireEvent(LineItemRegeneratorEvents.CHANGED),
        );

        this.lineItemContainer.on(
            'keyup',
            '.line-item-type, .line-item-amount',
            () => this.fireEvent(LineItemRegeneratorEvents.KEYUP),
        );

        const controllerElement = this.lineItemContainer
            .closest('[data-controller="form--collection"]')
            .get(0);

        controllerElement.addEventListener(CollectionEvents.REMOVED, () =>
            this.fireEvent(LineItemRegeneratorEvents.CHANGED),
        );
    }

    fireEvent(type) {
        this.container.find('.line-item-amount').each((index, field) => {
            if ('' === field.value) {
                field.value = '0';
            }
        });

        super.fire(type);
    }

    /**
     * @param {XMLHttpRequest} xhr
     */
    render(xhr) {
        const html = $(xhr.responseText);
        const collectionContainer =
            this.lineItemContainer.findOne('[data-prototype]');

        this.lineItemContainer.find('.read-only-collection-item').remove();

        $(
            html
                .find('.line-item-container .read-only-collection-item')
                .get()
                .reverse(),
        ).each((index, item) => {
            this.lineItemContainer.prepend(item);
        });

        this.container
            .find('.line-item-totals')
            .replaceWith(html.find('.line-item-totals'));

        const newPrototype = html
            .findOne('.line-item-container [data-prototype]')
            .data('prototype');

        this.lineItemContainer
            .findOne('[data-prototype]')
            .attr('data-prototype', newPrototype);

        this.lineItemContainer
            .find('.read-only-collection-item')
            .each((index, element) => {
                bindhq.forms.initContainer($(element));
            });
    }
}

module.exports = { LineItemRegenerator, LineItemRegeneratorEvents };
