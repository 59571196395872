import { Controller } from '@hotwired/stimulus';

const {
    Collection,
    CollectionEvents,
} = require('../../js/BindHQ/Form/Collection');

export default class extends Controller {
    static values = {
        reindex: { type: Boolean, default: false },
    };

    connect() {
        this.collection = new Collection(this.element, this.reindexValue);
    }

    /** @param {HTMLElement} item */
    addItem(item) {
        this.collection.addItem(item);
    }

    /**
     * @param {Event} evt
     */
    onAddClicked(evt) {
        this.collection.add(evt);
    }

    /**
     * @param {Event} evt
     */
    onDeleteClicked(evt) {
        if (evt.params.message) {
            window.dispatchEvent(
                new CustomEvent('confirm--button:clicked', {
                    detail: {
                        title: evt.params.title || 'Confirm',
                        body:
                            evt.params.message ||
                            'Are you sure you want to remove this item?',
                        confirm: evt.params.confirm || 'Are you sure?',
                        confirmType: evt.params.confirmType || 'danger',
                        onConfirm: (closeDialog) => {
                            this.collection.delete(evt);

                            window.dispatchEvent(
                                new CustomEvent('confirm--dialog:closeDialog'),
                            );
                        },
                    },
                }),
            );
        } else {
            this.collection.delete(evt);
        }
    }
}
