'use strict';

// Responsibilities of this file:
//   Templating for brokerage emails
//      Provides a very simple handlebars-style interface
//      but way, way simpler and SPECIFIC to brokerage.
//   Updating the summary of marketing contacts that are selected

const getMarketingCompanyFor = function (element) {
    const row = element.closest('tr');

    // work back through the row's siblings until we find the marketing company
    // (could alternatively use a data attribute here)
    let tmp = row.previousElementSibling;
    while (!tmp.classList.contains('brokerage-marketing-company')) {
        tmp = tmp.previousElementSibling;
    }

    return tmp;
};

/**
 * @param {jQuery} container
 * @param {String} previewTemplateUrl
 */
const updateRightHandColumn = function (container, previewTemplateUrl) {
    const selections = container.find(
        '#marketing-company-contact-selections tbody',
    );
    selections.empty();

    // Create an array of all the marketing company contacts
    const output = container
        // Find all the checked boxes
        .find('input.checkbox-marketing-company-contact:checked')
        // Convert it into an array with keys 'marketingCompany' and 'contact'
        .map((idx, element) => {
            const $element = $(element);
            return {
                id: $element.val(),
                marketingCompany: $element.data('marketing-company'),
                marketingCompanyId: $element.data('marketing-company-id'),
                contact: $element.data('contact'),
                contactEmail: $element.data('contact-email'),
                isSharedInbox: $element.data('is-shared-inbox'),
            };
        })
        .toArray()
        // Transform the array to a map, with the key being the marketing company name
        .reduce((map, obj) => {
            if (!map[obj.marketingCompany]) {
                map[obj.marketingCompany] = [];
            }
            map[obj.marketingCompany].push(obj);
            return map;
        }, {});

    // Display the map as an HTML table
    const tableBody = selections.get(0);
    const applicationId =
        container
            .find('#brokerage-risk-information')
            .data('applicationNumber') || '';

    const marketingCompanyTags = [`application-${applicationId}`];
    Object.keys(output).forEach((marketingCompanyKey) => {
        const marketingCompany = output[marketingCompanyKey];
        const { marketingCompanyId: id } = marketingCompany[0];
        const row = bindhq.util.template(
            bindhq.tpl.brokerage_marketing_company,
            {
                id,
                name: marketingCompanyKey,
            },
        );

        marketingCompanyTags.push(`doctag-brokerage-marketing-company-${id}`);

        tableBody.appendChild($(row).get(0));
        for (const contact of marketingCompany) {
            const contactRow = bindhq.util.template(
                bindhq.tpl.brokerage_marketing_company_contact,
                contact,
            );
            tableBody.appendChild($(contactRow).get(0));
        }
    });
};

module.exports = {
    /**
     * @param {jQuery} container
     * @param {String} previewTemplateUrl
     */
    configureEmailTemplates: function (container, previewTemplateUrl) {
        /**
         * On selection of a template, update the template box showing the
         * email template that the user can subsequently edit
         * @param {event} e
         */
        container
            .find(
                '#brokerage_submission_emailTemplate, #brokerage_submission_bulk_emailTemplate',
            )
            .on('change', function (e) {
                const selected = $(e.target).find('option:selected');
                const templateSubject = selected.data('template-subject');
                const templateBody = selected.data('template-body');

                const subject = container.findOne(
                    '#brokerage_submission_bulk_riskInformationSubject, #brokerage_submission_riskInformationSubject',
                );

                const wysiwyg = container.findOne(
                    '#brokerage_submission_riskInformation, #brokerage_submission_bulk_riskInformation',
                );

                subject.val(templateSubject);
                bindhq.wysiwyg.setContent(wysiwyg, templateBody);
            });
    },

    filter: function (container, filterText, page, perPage) {
        const matchingRows = _.reduce(
            container.find(
                '#brokerage-markets-and-contacts .brokerage-marketing-company, #brokerage-markets-and-contacts .brokerage-marketing-company-contact',
            ),
            function (acc, row) {
                let text = $(row).text().toLowerCase();

                if (
                    row.classList.contains(
                        'brokerage-marketing-company-contact',
                    )
                ) {
                    text += row
                        .querySelector('.checkbox-marketing-company-contact')
                        .dataset.marketingCompany.toLowerCase();
                }

                if (
                    null === filterText ||
                    '' === filterText ||
                    text.indexOf(filterText) > -1
                ) {
                    acc.push(row);

                    return acc;
                }

                return acc;
            },
            [],
        );

        // hide any currently visible rows
        container
            .find('#brokerage-markets-and-contacts .visible')
            .removeClass('visible');

        if (0 === matchingRows.length) {
            $('#brokerage-markets-and-contacts .no-results').addClass(
                'visible',
            );
        } else {
            const from = page * perPage;
            const to = from + perPage;

            _.each(matchingRows.slice(from, to), (row) => {
                row.classList.add('visible');

                if (
                    row.classList.contains(
                        'brokerage-marketing-company-contact',
                    )
                ) {
                    $(getMarketingCompanyFor(row)).addClass('visible');
                }
            });
        }

        // Only display the pagination controller if it is required
        if (matchingRows.length > perPage) {
            const html = bindhq.util.template(
                bindhq.tpl.transaction_table_pagination,
                {
                    currentPageIndex: page + 1,
                    currentItems: matchingRows,
                    perPage: perPage,
                },
            );

            $('.pagination-container', container).html(html);
        } else {
            $('.pagination-container', container).html('');
        }
    },

    /**
     * @param {jquery} element
     * @returns {*|Element}
     */
    getMarketingCompanyFor: getMarketingCompanyFor,

    updateRightHandColumn: updateRightHandColumn,
};
