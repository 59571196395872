module.exports = {
    TomSelectEvents: {
        /**
         * Trigger validation on change, which will clear
         * any existing errors if the control is now valid,
         * or add errors otherwise.
         */
        onChangeValidate: function () {
            let form = this.control.closest('form');

            this.control.parentElement.classList.remove('is-invalid');

            if (null === form) {
                return;
            }

            form = $(form);

            if (!form.validate) {
                return;
            }

            const validator = form.validate();

            if (validator && validator.element) {
                validator.element(this.input);
            }
        },
    },
};
