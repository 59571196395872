const Observable = require('../Observable');
const assert = require('assert');

const Events = {
    UPDATED: 'program_criteria_updated',
    CLEARED: 'program_criteria_cleared',
};

class ProgramCriteria extends Observable {
    /**
     * @param {jQuery} homeState
     * @param {jQuery} carrier
     * @param {jQuery} marketingCompany
     * @param {jQuery} businessType
     * @param {jQuery} linesOfBusiness
     */
    constructor(
        homeState,
        carrier,
        marketingCompany,
        businessType,
        linesOfBusiness,
    ) {
        super();

        assert.strictEqual(
            1,
            homeState.length,
            'Expected exactly one element matched for home state, got: ' +
                homeState.length,
        );
        assert.strictEqual(
            1,
            carrier.length,
            'Expected exactly one element matched for carrier, got: ' +
                carrier.length,
        );
        assert.strictEqual(
            1,
            marketingCompany.length,
            'Expected exactly one element matched for marketing company, got: ' +
                marketingCompany.length,
        );
        assert.strictEqual(
            1,
            businessType.length,
            'Expected exactly one element matched for business type, got: ' +
                businessType.length,
        );
        assert.ok(
            0 < linesOfBusiness.length,
            'Expected at least one line of business element',
        );

        this.homeState = homeState;
        this.carrier = carrier;
        this.marketingCompany = marketingCompany;
        this.businessType = businessType;
        this.linesOfBusiness = linesOfBusiness;

        homeState.on('change', () => this.#changed());
        carrier.on('change', () => this.#changed());
        marketingCompany.on('change', () => {
            // Clear the carrier because we don't know if it's valid for the new marketing company
            carrier.select2('val', null);

            this.#changed();
        });
        businessType.on('change', () => this.#changed());
        linesOfBusiness.on('change', () => this.#changed());
    }

    init() {
        this.#changed();
    }

    #changed() {
        const homeState = this.homeState.val();
        const carrier = this.carrier.val();
        const marketingCompany = this.marketingCompany.val();
        const businessType = this.businessType.val();
        const linesOfBusiness = this.linesOfBusiness
            .filter((i, element) => element.checked)
            .map((i, element) => element.value)
            .toArray();

        if (
            homeState &&
            carrier &&
            marketingCompany &&
            businessType &&
            linesOfBusiness.length > 0
        ) {
            this.fire(Events.UPDATED, {
                homeState,
                carrier,
                marketingCompany,
                businessType,
                linesOfBusiness,
            });
        } else {
            this.fire(Events.CLEARED);
        }
    }
}

module.exports = { ProgramCriteria, ProgramCriteriaEvents: Events };
