import { Controller } from '@hotwired/stimulus';
import { errorPlacement } from '../../js/BindHQ/Validation/FormValidator';

export default class extends Controller {
    onChange(event) {
        const { maxSize } = event.params;

        if (maxSize == undefined) {
            throw new Error('maxSize must be defined');
        }

        const actualSize = this.element.files[0].size;

        if (actualSize > maxSize) {
            const sizeString = Math.round((maxSize / 1024 / 1024) * 10) / 10;

            $(this.element).parent().find('.invalid-feedback').remove();
            errorPlacement(
                $(
                    `<span class="error">File is too large. Maximum allowed size is ${sizeString}MB</span>`,
                ),
                $(this.element),
            );
        } else {
            $(this.element).parent().find('.invalid-feedback').remove();
        }
    }
}
