import { Controller } from '@hotwired/stimulus';

const { DateTime } = require('luxon');

export default class extends Controller {
    static targets = ['links', 'expiresAfter', 'expiresBefore'];

    connect() {
        this.linksTarget.innerHTML =
            '<div class="autofill">' +
            'Set to ' +
            '<a data-action="report--renewals-manager-dates#onClick" data-report--renewals-manager-dates-days-param="30" href="#">30</a> / ' +
            '<a data-action="report--renewals-manager-dates#onClick" data-report--renewals-manager-dates-days-param="60" href="#">60</a> / ' +
            '<a data-action="report--renewals-manager-dates#onClick" data-report--renewals-manager-dates-days-param="90" href="#">90</a> / ' +
            '<a data-action="report--renewals-manager-dates#onClick" data-report--renewals-manager-dates-days-param="120" href="#">120</a> ' +
            'days' +
            '</div>';
    }

    /**
     * @param {Event} event
     */
    onClick(event) {
        const newDate = DateTime.now().plus({ days: event.params.days });

        this.expiresAfterTarget.value = DateTime.now().toISODate();
        this.expiresBeforeTarget.value = newDate.toISODate();
    }
}
