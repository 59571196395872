import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['item'];

    onChange(evt) {
        this.itemTargets.forEach((item) => {
            if (evt.target !== item) {
                item.checked = false;
            }
        });
    }
}
