import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        carrierStatesUrl: String,
    };

    static targets = ['carrier', 'carrierStates'];

    onCarrierChange() {
        this.#update();
    }

    #update() {
        const tomselect = this.carrierStatesTarget.tomselect;
        tomselect.clear(false);
        tomselect.clearOptions();
        tomselect.disable();

        const carrierId = this.carrierTarget.value;

        if (carrierId) {
            fetch(this.carrierStatesUrlValue.replace(/{carrierId}/, carrierId))
                .then((response) => response.json())
                .then((carrierStates) => {
                    this.#refresh(carrierStates);
                });
        }
    }

    /**
     * @param {Array} carrierStates
     */
    #refresh(carrierStates) {
        const tomselect = this.carrierStatesTarget.tomselect;

        this.carrierStatesTarget
            .querySelectorAll('option')
            .forEach((option) => this.carrierStatesTarget.removeChild(option));

        carrierStates.forEach((carrierState) => {
            this.#addOption(carrierState.id, carrierState.state_tax.name);
        });

        tomselect.settings.maxOptions = carrierStates.length;
        tomselect.sync();
        tomselect.enable();
    }

    #addOption(value, text) {
        const option = document.createElement('option');
        option.setAttribute('value', value);
        option.innerHTML = text;

        this.carrierStatesTarget.appendChild(option);
    }
}
