import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        if (this.element.classList.contains('no-auto-init')) {
            return;
        }

        const container = $(this.element);

        bindhq.forms.ajaxselect.initContainer(container);
    }
}
