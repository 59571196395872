import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class extends Controller {
    static targets = ['county', 'state'];

    static values = {
        countiesPath: String,
    };

    connect() {
        this.cache = [];
        this.tomselect = new TomSelect(this.countyTarget, {
            valueField: 'value',
            labelField: 'name',
            searchField: 'name',
        });

        this.#update();
    }

    /**
     * @param {Event} event
     */
    onStateChange(event) {
        if (event.isTrusted) {
            this.countyName = null;
        }

        this.#update();
    }

    /**
     * @param {Event} event
     */
    onCountyChange(event) {
        this.countyName = event.detail.countyName;
        this.#update();
    }

    #update() {
        this.#clear();

        const state = this.#getState();

        if (state) {
            if (this.cache[state]) {
                this.#refresh(this.cache[state]);
            } else {
                const url = this.countiesPathValue.replace('{state}', state);

                fetch(url)
                    .then((response) => response.json())
                    .then((counties) => {
                        this.cache[state] = counties;
                        this.#refresh(counties);
                    });
            }
        }
    }

    #getState() {
        return this.stateTarget.value;
    }

    #clear() {
        this.tomselect.clear();
        this.tomselect.clearOptions();
        this.tomselect.disable();
    }

    /**
     * @param {Array} counties
     */
    #refresh(counties) {
        this.#clear();

        const countyName = (this.countyName || '')
            .replace(' County', '')
            .replace('St ', 'St. ');

        let selectedCounty = null;

        counties.forEach((county) => {
            this.tomselect.addOption(county);

            if (county.name === countyName) {
                selectedCounty = county;
            }
        });

        this.tomselect.refreshOptions(false);

        if (selectedCounty) {
            this.tomselect.setValue(selectedCounty.value);
        } else {
            window.dispatchEvent(
                new CustomEvent('bindhq.address.county_not_found'),
            );
        }

        this.tomselect.enable();
    }
}
