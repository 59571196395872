const progress = require('../Utils/Progress');

class AjaxSubmitHandler {
    constructor(container, successCallback = null, errorCallback = null) {
        this.enabled = container.closest('.tab-pane').length > 0;
        this.container = container;
        this.successCallback = successCallback;
        this.errorCallback = errorCallback;
    }

    onSubmit(evt) {
        if (this.enabled) {
            evt.preventDefault();
        }
    }

    /**
     * @param {Event} evt
     */
    onClick(evt) {
        if (!this.enabled) {
            return;
        }

        evt.preventDefault();

        if (this.container.valid()) {
            const button = $(evt.currentTarget);

            this.appendButton(button);

            button.progress('start');

            this.submit();
        }
    }

    /**
     * @param {Event} evt
     */
    onNavigate(evt) {
        evt.preventDefault();

        if (this.container.valid()) {
            const link = $(evt.target).get(0);
            const defaults = {
                flow_transition_to: link.dataset.flowTransitionTo,
            };
            const config = $.extend(
                {},
                defaults,
                link.dataset.flowTransitionParameters
                    ? JSON.parse(link.dataset.flowTransitionParameters)
                    : {},
            );

            this.submit(config);
        }
    }

    /**
     * @param {Event} evt
     */
    onCancel(evt) {
        evt.preventDefault();

        const link = $(evt.target).get(0);

        progress.start();

        $.ajax({
            type: 'GET',
            url: link.href,
            success: (html, status, xhr) => this.onSuccess(xhr),
            error: (xhr) => this.onError(xhr),
            complete: () => progress.stop(),
        });
    }

    /**
     * @param {Object} xhr
     */
    onSuccess(xhr) {
        const redirectUrl = xhr.getResponseHeader('X-Location');

        if (redirectUrl) {
            if (location.pathname + location.hash !== redirectUrl) {
                history.pushState(null, null, redirectUrl);
            }

            location.reload();
        } else if (200 === xhr.status || 400 === xhr.status) {
            this.render(xhr.responseText);

            if (null !== this.successCallback) {
                this.successCallback(xhr);
            }
        } else {
            console.error('Unexpected status: ', status);
        }
    }

    /**
     * @param {Object} xhr
     */
    onError(xhr) {
        if (xhr.status >= 400 && xhr.status < 500) {
            this.render(xhr.responseText);
        } else {
            alert(
                'BindHQ encountered an error when saving your data, please try again, or if the issue continues submit a support request and we will investigate.',
            );
        }

        if (null !== this.errorCallback) {
            this.errorCallback(xhr);
        }
    }

    /**
     * @param {String} html
     */
    render(html) {
        const newContainer = $('<div>' + html + '</div>');

        this.container.replaceWith(newContainer);

        bindhq.initContainer(newContainer);

        window.scrollTo({
            top: newContainer.offset().top - $('.navbar-fixed-top').height(),
            left: 0,
            behavior: 'smooth',
        });

        this.container = newContainer;
    }

    /**
     * @param {Object} extraData
     */
    submit(extraData) {
        let data = this.container.formSerialize();

        if (extraData) {
            data += '&' + $.param(extraData);
        }

        progress.start();

        $.ajax({
            type: this.container.attr('method'),
            url: this.container.attr('action'),
            data: data,
            success: (html, status, xhr) => this.onSuccess(xhr),
            error: (xhr) => this.onError(xhr),
            complete: () => progress.stop(),
        });
    }

    /**
     * By default the jQuery form plugin doesn't include the submit button in the
     * form data, so here we include it as a hidden item if it's defined a name.
     *
     * @param {jQuery} button
     */
    appendButton(button) {
        if (button.attr('name')) {
            $('<input type="hidden">')
                .attr('name', button.attr('name'))
                .val(button.val())
                .appendTo(this.container);
        }
    }
}

module.exports = { AjaxSubmitHandler };
