class CollectionIndexer {
    /**
     * @param {jQuery} container
     * @param {String} selector
     *
     * @return {Integer}
     */
    reindex(container, selector) {
        let index = 0;

        container
            .find(selector || '.collection-item')
            .each((i, collectionElement) => {
                $(collectionElement)
                    .find('[name]')
                    .each((j, inputElement) => {
                        const name = $(inputElement).attr('name');
                        const newName = this.#reverse(
                            this.#reverse(name).replace(
                                /\]\d+\[/,
                                ']' + this.#reverse(new String(index)) + '[',
                            ),
                        );

                        $(inputElement).attr('name', newName);
                    });

                index++;
            });

        return index;
    }

    #reverse(str) {
        return str.split('').reverse().join('');
    }
}

module.exports = { CollectionIndexer };
