class MenuLinkHandler {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        this.container = container;
    }

    /**
     * @param {Event} evt
     */
    onNavigate(evt) {
        evt.preventDefault();

        if (this.container.valid()) {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'flow_transition_to');
            input.setAttribute(
                'value',
                $(evt.target).get(0).dataset.flowTransitionTo,
            );

            this.container.append(input);
            this.container.submit();
        }
    }
}

module.exports = { MenuLinkHandler };
