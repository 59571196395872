import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
    static targets = ['form', 'input'];

    static debounces = ['onChange'];

    connect() {
        useDebounce(this, { wait: 500 });
    }

    onChange() {
        const query = this.inputTarget.value.toLowerCase();

        this.formTargets.forEach((element) => {
            if (
                '' === query ||
                -1 !== element.dataset.searchText.toLowerCase().indexOf(query)
            ) {
                element.classList.remove('d-none');
            } else {
                element.classList.add('d-none');
            }
        });
    }
}
