import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

const { TomSelectEvents } = require('../../js/BindHQ/Form/TomSelect');

export default class extends Controller {
    static values = {
        addressSearchUrl: String,
        addressDetailUrl: String,
        labelRender: { type: Boolean, default: true },
        id: {
            type: String,
            default:
                'address-controller-' +
                Math.floor((Math.random() * 1000000) % 1000),
        },
    };

    static targets = [
        'address',
        'city',
        'line1',
        'line2',
        'postalCode',
        'state',
    ];

    connect() {
        this.#resetSession();

        const stateIndex = this.stateTarget.selectedIndex;
        const currentAddress = [
            this.line1Target.value,
            this.line2Target.value,
            this.cityTarget.value,
            0 < this.stateTarget.selectedIndex
                ? this.stateTarget.options[stateIndex].innerHTML
                : null,
            this.postalCodeTarget.value,
        ]
            .filter((x) => x)
            .join(', ');

        const disabled = this.line1Target.disabled;
        const picker = this.element.ownerDocument.createElement('div');
        picker.innerHTML =
            `
            <div class="form-group">
              <span>
                ` +
            (this.labelRenderValue
                ? `<label class="required">Mailing Address</label>`
                : '<label></label>') +
            `
                <select ` +
            (disabled ? 'disabled' : '') +
            ` required id="` +
            this.idValue +
            `"></select>
              </span>

              <a href="javascript:void(0)" class="pt-2 ` +
            (disabled ? 'd-none' : 'd-inline-block') +
            `">Enter Address Manually</a>
            </div>`;

        const tomSelect = new TomSelect(picker.querySelector('select'), {
            valueField: 'id',
            labelField: 'description',
            searchField: ['name', 'description'],
            placeholder: 'Search for address...',
            onChange: (event) => {
                if (!tomSelect.getValue()) {
                    tomSelect.clearOptions();
                    tomSelect.close();
                } else {
                    TomSelectEvents.onChangeValidate.apply(tomSelect);
                }
            },
            load: (query, callback) => {
                fetch(
                    this.addressSearchUrlValue +
                        '?' +
                        new URLSearchParams({
                            name: query,
                            sessionToken: this.sessionToken,
                        }),
                )
                    .then((response) => response.json())
                    .then((results) => callback(results))
                    .catch(() => callback());
            },
        });

        const manualAnchor = picker.querySelector('a');
        manualAnchor.addEventListener('click', () => {
            picker.classList.add('d-none');
            this.addressTarget.classList.remove('d-none');
        });

        const searchAnchor = this.element.ownerDocument.createElement('a');
        searchAnchor.href = 'javascript:void(0)';
        searchAnchor.innerHTML = 'Search For Address';
        searchAnchor.addEventListener('click', () => {
            tomSelect.setValue(null);

            this.#clearAddress();

            picker.classList.remove('d-none');
            this.addressTarget.classList.add('d-none');
        });

        this.addressTarget.classList.add('d-none');
        this.addressTarget.parentNode.insertBefore(picker, this.addressTarget);
        this.addressTarget.querySelector('fieldset').appendChild(searchAnchor);

        const defaultItemId = bindhq.util.uuid4();

        if (currentAddress) {
            const item = {
                id: defaultItemId,
                description: currentAddress,
            };

            tomSelect.addOption(item);
            tomSelect.setValue(item.id);
        }

        tomSelect.on('change', (value) => {
            if (!value) {
                this.#clearAddress();

                return;
            }

            if (value === defaultItemId) {
                return;
            }

            fetch(
                this.addressDetailUrlValue +
                    '?' +
                    new URLSearchParams({
                        placeId: value,
                        sessionToken: this.sessionToken,
                    }),
            )
                .then((response) => response.json())
                .then((address) => {
                    this.line1Target.value = address.line1;
                    this.line2Target.value = address.line2;
                    this.cityTarget.value = address.city;
                    this.stateTarget.tomselect.setValue(address.state);
                    this.postalCodeTarget.value = address.postal_code;

                    this.#resetSession();
                });
        });

        if (this.element.querySelector('.is-invalid')) {
            manualAnchor.click();
        }
    }

    #clearAddress() {
        this.line1Target.value = null;
        this.line2Target.value = null;
        this.cityTarget.value = null;
        this.stateTarget.tomselect.setValue(null);
        this.postalCodeTarget.value = null;
    }

    #resetSession() {
        this.sessionToken = bindhq.util.uuid4();
    }
}
