import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const { CollectionEvents } = require('../../../js/BindHQ/Form/Collection');

export default class extends Controller {
    static targets = [
        'comparison',
        'condition',
        'conditions',
        'preview',
        'source',
        'value',
    ];

    static values = {
        coverageCondition: String,
    };

    static debounces = ['onChange'];

    connect() {
        useDebounce(this, { wait: 500 });

        this.conditionsTarget.addEventListener('change', () => this.onChange());
        this.conditionsTarget.addEventListener(CollectionEvents.ADDED, () =>
            this.onChange(),
        );
        this.conditionsTarget.addEventListener(CollectionEvents.REMOVED, () =>
            this.onChange(),
        );

        // Wait for TomSelect to be initialized.
        setTimeout(() => this.#update(), 100);
    }

    onChange() {
        this.#update();
    }

    #update() {
        const conditions = {};

        this.conditionTargets.forEach((condition) => {
            const source = this.#findElement(condition, this.sourceTargets);
            const comparison = this.#findElement(
                condition,
                this.comparisonTargets,
            );
            const value = this.#findElement(condition, this.valueTargets);

            conditions[source.value] = {
                source: this.#readableFor(source),
                comparison: this.#readableFor(comparison),
                value: this.#readableFor(value),
            };
        });

        const coverageCondition = conditions[this.coverageConditionValue];

        delete conditions[this.coverageConditionValue];

        const preview = [];

        if (coverageCondition) {
            preview.push(this.#previewFor(coverageCondition));
        }

        this.previewTarget.innerHTML = preview
            .concat(
                Object.values(conditions).map((condition) =>
                    this.#previewFor(condition),
                ),
            )
            .join(', <span class="and">and if</span> ');
    }

    /**
     * @param {HTMLElement} container
     * @param {HTMLElement[]} elements
     */
    #findElement(container, elements) {
        return elements.find((element) => container.contains(element));
    }

    /**
     * @param {HTMLElement} element
     */
    #readableFor(element) {
        if (!element.options) {
            return element.value;
        }

        const value = element.tomselect
            ? element.tomselect.getValue()
            : element.value;
        const option = Array.from(element.options).find(
            (option) => option.value === value,
        );

        if (option) {
            return option.innerHTML;
        }

        return null;
    }

    /**
     * @param {Object} condition
     */
    #previewFor(condition) {
        return `<span class="source">${condition.source}</span> <span class="comparison">${condition.comparison}</span> <span class="value">${condition.value}</span>`;
    }
}
