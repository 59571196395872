const { GeoPoint } = require('../Models/GeoPoint');
const { Location } = require('../Models/Location');
const { Address } = require('../Models/Address');

/**
 * @param {Object} options
 * @param {Array} data
 * @param {Integer} index
 * @param {Object} params
 *
 * @return {Object}
 */
function handleAddressLookupResults(options, data, index, params) {
    const locations = _.reduce(
        options.getLocations(),
        function (acc, loc) {
            const description =
                'Location # ' +
                loc.getNumber() +
                ': ' +
                loc.getAddress().getDescription();

            if (
                -1 !==
                description.toLowerCase().indexOf(params.term.toLowerCase())
            ) {
                acc.push({
                    id: loc.getUuid(),
                    text: description,
                    loc: loc,
                });
            }

            return acc;
        },
        [],
    );

    const results = _.map(data, function (result) {
        return {
            id: result.id,
            text: result.description,
        };
    });

    return {
        results: locations.concat(results),
    };
}

/**
 * @param {jQuery} container
 * @param {String} name
 *
 * @return {String}
 */
function getUrl(container, name) {
    const url = container.data(name);

    if (url) {
        return url;
    }

    throw new Error('Expected "' + name + '" data attribute');
}

/**
 * @param {Object} options
 * @param {jQuery} locationContainer
 */
function getAddressLookupConfig(options, locationContainer) {
    return {
        placeholder: 'Select a location, or type an address',
        ajax: {
            url: getUrl(locationContainer, 'address-suggest-url'),
            dataType: 'json',
            data: function (term) {
                return {
                    name: term,
                };
            },
            results: _.partial(handleAddressLookupResults, options),
        },
    };
}

/**
 * @param {Object} options
 * @param {Object} detail
 */
function onPlaceDetail(options, detail) {
    let geoPoint = null;

    if (detail.latitude && detail.longitude) {
        geoPoint = new GeoPoint(detail.latitude, detail.longitude);
    }

    options.onAdd(
        new Location(
            null,
            options.getNextLocationNumber(),
            new Address(
                detail.line1,
                detail.line2,
                detail.city,
                detail.state,
                detail.international_state,
                detail.postal_code,
                detail.county,
                detail.county_fips,
            ),
            geoPoint,
        ),
    );
}

/**
 * @param {String} url
 * @param {Object} options
 * @param {String} placeId
 */
function addPlace(url, options, placeId) {
    $.ajax({
        url: url,
        data: { placeId: placeId },
        success: _.partial(onPlaceDetail, options),
        failure: options.onFailure,
    });
}

/**
 * @param {jQuery} container
 * @param {Object} options
 */
function LocationAdder(container, options) {
    if (typeof options.onAdd !== 'function') {
        throw new Error('Expected the onAdd option to be a callback function');
    }

    if (typeof options.getLocations !== 'function') {
        throw new Error(
            'Expected the getLocations option to be a callback function',
        );
    }

    if (typeof options.getNextLocationNumber !== 'function') {
        throw new Error(
            'Expected the getNextLocationNumber option to be a callback function',
        );
    }

    const disabledClass = 'disabled';
    const locationContainer = container.findOne('.add-location-container');
    const locationButton = container
        .findOne('.btn-add-location')
        .addClass(disabledClass);
    const locationSelector = $('<input type="text">')
        .addClass('form-control')
        .appendTo(locationContainer);
    const locationConfig = getAddressLookupConfig(options, locationContainer);

    locationSelector.select2(locationConfig);
    locationSelector.change(function () {
        const data = locationSelector.select2('data');

        if (data !== null) {
            locationButton.removeClass(disabledClass);
        } else {
            locationButton.addClass(disabledClass);
        }
    });

    locationButton.click(function (evt) {
        evt.preventDefault();

        const data = locationSelector.select2('data');

        if (data.loc) {
            options.onAdd(data.loc);
        } else {
            addPlace(
                getUrl(locationContainer, 'address-detail-url'),
                options,
                data.id,
            );
        }

        locationSelector.select2('data', null);
        locationSelector.trigger('change');
    });
}

module.exports = { LocationAdder };
