import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        clientOrn: String,
        userId: String,
        userName: String,
        userEmail: String,
    };

    connect() {}
}
