import { Controller } from '@hotwired/stimulus';

const {
    OutOfSequenceWarner,
} = require('../../js/BindHQ/Endorsement/OutOfSequenceWarner');

export default class extends Controller {
    static values = {
        latestEffectiveDate: String,
    };

    static targets = ['effectiveDate', 'warning'];

    connect() {
        this.warner = new OutOfSequenceWarner(
            this.effectiveDateTarget,
            this.warningTarget,
            this.latestEffectiveDateValue,
        );

        this.warner.refresh();
    }

    onChange() {
        this.warner.onChange();
    }
}
