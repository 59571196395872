import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.element.addEventListener('click', () => {
            setTimeout(() => {
                const form = this.element.closest('form');

                if (null === form || $(form).valid()) {
                    this.element.disabled = true;
                }
            }, 10);
        });
    }
}
