import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    static targets = ['links', 'content'];

    connect() {
        const observerOptions = {
            root: null,
            rootMargin: '-50% 0px -50%',
            threshold: 0,
        };

        const observer = new IntersectionObserver(
            handleIntersection,
            observerOptions,
        );

        const content = this.contentTarget;
        const sections = content.querySelectorAll(':scope > ol > li');
        const navLinks = this.linksTarget.querySelectorAll('li a');

        sections.forEach((section) => {
            observer.observe(section);
        });

        content.addEventListener('scroll', () => {
            // Checks if scrollable block is at the bottom and makes last item active as if last item is short the intersection will not trigger
            if (
                content.scrollTop + content.clientHeight >=
                content.scrollHeight
            ) {
                activateNavLink(
                    navLinks[navLinks.length - 1]
                        .getAttribute('href')
                        .substring(1),
                );
            }
        });

        function handleIntersection(entries, observer) {
            // If entries are provided, it's called by IntersectionObserver
            if (entries) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const targetId = entry.target.id;
                        activateNavLink(targetId);
                    }
                });
            }
        }

        // Makes relevent nav item active and adds id to the url
        function activateNavLink(targetId) {
            navLinks.forEach((link) => {
                const linkHref = link.getAttribute('href').substring(1);

                link.classList.remove('active');

                if (linkHref === targetId) {
                    link.classList.add('active');
                    history.pushState(null, null, `#${linkHref}`);
                }
            });
        }
    }

    linksTargetConnected(element) {
        // Attach click event listeners to each anchor element
        const linkElements = element.querySelectorAll('li a');

        linkElements.forEach((link) => {
            link.addEventListener('click', this.onClick);
        });
    }

    onClick(event) {
        event.preventDefault();

        // Extracting the hash part from the href
        const href = event.target.href;
        const hash = href.split('#')[1];

        // Allows you to specify a different part of the block to link to if needed
        const block = event.target.getAttribute('data-block') || 'start';

        // Get the element with the corresponding ID
        const targetElement = document.getElementById(hash);

        // Scroll to the target element smoothly
        targetElement.scrollIntoView({ behavior: 'smooth', block: block });
    }
}
