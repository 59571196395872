class DiligentEffortReasonSelector {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        this.reasonSelector = container.findOne(
            'select.diligent-effort-reasons',
        );
        this.reason = container.findOne('.diligent-effort-reason');
    }

    /**
     *
     */
    init() {
        this.reasonSelector.on('change', () => this.updateReason());
    }

    /**
     *
     */
    updateReason() {
        this.reason.val(this.reason.val() + this.reasonSelector.val());
        this.reasonSelector.select2('val', null);
    }
}

module.exports = { DiligentEffortReasonSelector };
