import { Controller } from '@hotwired/stimulus';

const {
    FormFlowReloader,
    FormFlowReloaderEvents,
} = require('../../../js/BindHQ/Form/FormFlowReloader');

const { CollectionEvents } = require('../../../js/BindHQ/Form/Collection');

export default class extends Controller {
    static targets = ['alert', 'premium', 'rate'];

    connect() {
        this.showAllErrors = false;

        const formFlowReloader = new FormFlowReloader(
            $(this.element),
            this.element.action + '/program',
        );

        formFlowReloader.subscribe(FormFlowReloaderEvents.CHANGED, (xhr) => {
            if (200 === xhr.status) {
                this.#update(xhr.responseJSON);
            }

            if (400 === xhr.status) {
                this.#error(xhr.responseJSON);
            }
        });

        const onChange = () => {
            formFlowReloader.reload();
        };

        this.element.addEventListener(CollectionEvents.ADDED, onChange);
        this.element.addEventListener(CollectionEvents.REMOVED, onChange);
        this.element.addEventListener('change', onChange);
    }

    /**
     * @param {Object} response
     */
    #update(response) {
        const rating = response.custom_rating;

        this.#clear(rating);

        rating.coverages.forEach((coverage) => {
            const coverageElement = this.element
                .querySelector('input[value="' + coverage.uuid + '"]')
                .closest('.collection-item');

            this.#setValue(coverageElement, this.rateTargets, coverage.rate);
            this.#setValue(
                coverageElement,
                this.premiumTargets,
                coverage.premium.amount,
            );
        });
    }

    /**
     * @param {Object} response
     */
    #error(response) {
        const rating = response.custom_rating;

        this.#clear(rating);

        const maxErrors = this.showAllErrors ? rating.errors.length : 2;

        if (0 !== rating.errors.length) {
            const errors = document.createElement('ul');

            rating.errors.forEach((responseError, index) => {
                const error = document.createElement('li');
                error.innerHTML = responseError.message;

                if (index >= maxErrors) {
                    error.classList.add('d-none');
                }

                errors.appendChild(error);
            });

            let more = null;

            if (rating.errors.length > maxErrors) {
                more = document.createElement('li');
                more.innerHTML = rating.errors.length - maxErrors + ' more...';
                more.classList.add('more');

                errors.append(more);
            }

            this.alertTarget.innerHTML = '';
            this.alertTarget.append(errors);

            if (rating.errors.length > maxErrors) {
                const expand = document.createElement('a');
                expand.innerHTML = 'See all errors';
                expand.classList.add('d-block', 'pt-2');
                expand.addEventListener('click', () => {
                    this.showAllErrors = true;

                    this.alertTarget
                        .querySelectorAll('.d-none')
                        .forEach((element) => {
                            element.classList.remove('d-none');
                        });

                    expand.classList.add('d-none');
                    expand.classList.remove('d-block');

                    more.classList.add('d-none');
                });

                this.alertTarget.appendChild(expand);
            }

            this.alertTarget.classList.remove('d-none');
        }
    }

    /**
     * @param {Object} rating
     */
    #clear(rating) {
        this.premiumTargets.concat(this.rateTargets).forEach((element) => {
            element.value = null;
        });

        if (0 === rating.errors.length) {
            this.alertTarget.classList.add('d-none');
        }
    }

    /**
     * @param {HTMLElement} parent
     * @param {Array} element
     * @param {String} value
     */
    #setValue(parent, elements, value) {
        elements.forEach((element) => {
            if (parent.contains(element)) {
                element.value = value;
                element.dispatchEvent(new CustomEvent('input'));
            }
        });
    }
}
