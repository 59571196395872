const Validator = require('../Utils/Validator');

const rules = {
    address_line1: [
        {
            validate: (value) => {
                return !!value;
            },
            message: 'Street is required',
        },
    ],
    city: [
        {
            validate: (value) => {
                return !!value;
            },
            message: 'City is required',
        },
    ],
    state: [
        {
            validate: (value) => {
                return !!value;
            },
            message: 'State is required',
        },
    ],
    zip: [
        {
            validate: (value) => {
                return !!value;
            },
            message: 'Zip is required',
        },
    ],
};

/**
 *
 * @param {Object} formProperties
 * @param {string} formProperties.address_line1
 * @param {string} [formProperties.address_line2]
 * @param {string} formProperties.city
 * @param {string} [formProperties.county]
 * @param {string} formProperties.state
 * @param {string} formProperties.zip
 */
const validateAddressComponents = (formProperties) =>
    Validator.validate(formProperties, rules);

module.exports = { validateAddressComponents };
