import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = { url: String };

    onClick(event) {
        event.preventDefault();
        event.stopPropagation();

        const form = this.element.closest('form');
        const originalAction = form.action;

        form.action = this.element.href;
        form.submit();
        form.action = originalAction;
    }
}
