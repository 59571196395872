import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox', 'formButton', 'status'];

    connect() {
        this.#updateButton();
    }

    onChange() {
        this.#updateButton();
    }

    #updateButton() {
        const isChecked = this.checkboxTarget.checked;
        this.formButtonTarget.disabled = !isChecked;
        this.statusTarget.classList.toggle('invisible', !isChecked);
        this.statusTarget.classList.toggle('visible', isChecked);
    }
}
