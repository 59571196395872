import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        title: {
            type: String,
            default: 'Confirm',
        },
        message: String,
        cancel: String,
        confirm: {
            type: String,
            default: 'Are you sure?',
        },
        confirmType: {
            type: String,
            default: 'danger',
        },
        httpMethod: {
            type: String,
            default: 'get',
        },
    };

    connect() {
        if ('A' !== this.element.tagName) {
            throw new Error(
                'The confirm controller can only be used on an anchor element.',
            );
        }

        if (!this.element.hasAttribute('href')) {
            throw new Error(
                'The confirm controller requires an href attribute.',
            );
        }
    }

    onClick(event) {
        const href = event.currentTarget.getAttribute('href');

        event.stopPropagation();
        event.preventDefault();

        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: this.titleValue,
                    body: this.messageValue,
                    cancel: this.cancelValue,
                    confirm: this.confirmValue,
                    confirmType: this.confirmTypeValue,
                    onConfirm: () => {
                        if (this.httpMethodValue === 'post') {
                            const form = document.createElement('form');
                            form.method = 'post';
                            form.action = href;
                            form.classList.add('hidden');

                            document.body.appendChild(form);

                            form.submit();
                        } else {
                            location.href = href;
                        }
                    },
                    lockOnConfirm: true,
                },
            }),
        );
    }
}
