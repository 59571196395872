import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['parent', 'child'];

    static values = {
        url: String,
        urlParameter: String,
    };

    connect() {
        $(this.parentTarget).on('change', () => this.#changed());

        this.#changed();
    }

    #changed() {
        const parentValue = $(this.parentTarget).val();

        if (parentValue && !$(this.parentTarget).attr('disabled')) {
            $(this.childTarget).data('ajaxurl', () => {
                return $(this.childTarget)
                    .data('ajaxurl-pattern')
                    .replace(
                        '{' + this.urlParameterValue + '}',
                        $(this.parentTarget).select2('val'),
                    );
            });

            $(this.childTarget).select2('enable');
            $(this.childTarget).select2('data', null);
        } else {
            this.childTarget.disabled = true;
            $(this.childTarget).select2('disable');
        }
    }
}
