const { Address } = require('../Models/Address');

class AddressSelector {
    /**
     * @param {HTMLElement} addressSelect
     * @param {HTMLElement} manualToggle
     * @param {HTMLElement} details
     * @param {String} detailsUrl
     * @param {HTMLElement} addressLine1
     * @param {HTMLElement} addressLine2
     * @param {HTMLElement} city
     * @param {HTMLElement} state
     * @param {HTMLElement} zip
     * @param {HTMLElement} county
     * @param {String} searchAddressText
     */
    constructor(
        addressSelect,
        manualToggle,
        details,
        detailsUrl,
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        county,
        searchAddressText,
    ) {
        this.addressSelect = addressSelect;
        this.manualToggle = manualToggle;
        this.details = details;
        this.detailsUrl = detailsUrl;
        this.addressLine1 = addressLine1;
        this.addressLine2 = addressLine2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.county = county;
        this.manualAddressText = manualToggle.innerHTML;
        this.searchAddressText = searchAddressText;
    }

    init() {
        this.details.classList.add('d-none');

        window.addEventListener('bindhq.address.county_not_found', () =>
            this.showManualAddressFields(),
        );
    }

    /**
     * @param {Event} evt
     */
    onManualToggle(evt) {
        evt.preventDefault();

        if (this.details.classList.contains('d-none')) {
            this.showManualAddressFields();
        } else {
            this.details.classList.add('d-none');
            this.addressSelect.required = true;
            this.addressSelect.tomselect.setValue(null);
            this.addressSelect.nextElementSibling.classList.remove('d-none');
            this.addressLine1.value = null;
            this.addressLine2.value = null;
            this.city.value = null;
            this.state.tomselect.setValue(null);
            this.setZip(null);
            this.setCounty(null);
            this.manualToggle.innerHTML = this.manualAddressText;
        }
    }

    /**
     * @param {Event} evt
     */
    onChange(evt) {
        $.ajax({
            url: this.detailsUrl,
            data: {
                placeId: evt.target.value,
            },
            success: (address) =>
                this.onAddressSuccess(
                    new Address(
                        this.noData(address.line1),
                        address.line2,
                        address.city,
                        address.state,
                        address.international_state,
                        this.noData(address.postal_code),
                        address.county,
                        address.count_fips,
                    ),
                ),
        });
    }

    /**
     * @param {Address} address
     */
    onAddressSuccess(address) {
        this.addressLine1.value = address.getLine1();
        this.addressLine2.value = address.getLine2();
        this.city.value = address.getCity();
        this.state.tomselect.setValue(address.getState());
        this.setZip(address.getPostalCode());
        this.setCounty(address.getCounty());

        if (
            !address.getLine1() ||
            !address.getCity() ||
            !address.getState() ||
            !address.getPostalCode()
        ) {
            this.showManualAddressFields();
        }
    }

    /**
     * @param {String|null} zip
     */
    setZip(zip) {
        this.zip.value = zip;
        this.zip.dispatchEvent(new CustomEvent('input'));
    }

    /**
     * @param {String|null} county
     */
    setCounty(county) {
        if (!this.county) {
            return;
        }

        window.dispatchEvent(
            new CustomEvent('bindhq.address.county_change', {
                detail: {
                    countyName: county,
                },
            }),
        );
    }

    /**
     *
     */
    showManualAddressFields() {
        this.details.classList.remove('d-none');
        this.addressSelect.required = false;
        this.addressSelect.nextElementSibling.classList.add('d-none');
        this.manualToggle.innerHTML = this.searchAddressText;
    }

    /**
     * @param {String|null} value
     *
     * @return {String|null}
     */
    noData(value) {
        return '(no data)' === value ? null : value;
    }
}

module.exports = { AddressSelector };
