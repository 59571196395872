import { Controller } from '@hotwired/stimulus';

const {
    ProductsCompletedOperations,
} = require('../../js/BindHQ/Product/GeneralLiability/ProductsCompletedOperations');

export default class extends Controller {
    static values = {
        charged: String,
    };

    connect() {
        const container = $(this.element);

        const productsCompletedOperations = new ProductsCompletedOperations(
            container.findOne('select.prdco-code'),
            container.findOne('.prdco-premium'),
            container.findOne('.prdco-rate'),
            this.chargedValue,
        );

        productsCompletedOperations.init();
    }
}
